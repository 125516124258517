import React from "react";
import Loader from "./Loader";
import { NavLink } from "react-router-dom";

const PageFrame = ({ isLoading, children, frameLinks, childrenContainerClass }) => {
    const logout = () => {
        // remove user from local storage to log user out
        localStorage.removeItem("noqUser");
        sessionStorage.removeItem("noqUser");
        window.dispatchEvent(new Event("storage"));
    };

    return (
        <div className="bg-dark-blue w-full flex justify-center items-center px-14 py-20 max-sm:p-0 h-screen">
            <div className="w-[94%] h-full min-h-[85vh] overflow-auto bg-white rounded-2xl px-16 py-12 max-lg:p-5 max-sm:h-[90vh]">
                <div className="w-full">
                    <div className="flex justify-between py-4 px-3 h-14 max-sm:h-auto bg-dark-blue mb-10">
                        <div className="flex flex-wrap gap-[10px] items-center ">
                            {frameLinks?.map((link, idx) => {
                                return (
                                    <NavLink
                                        key={idx}
                                        to={link.url}
                                        className={`text-sm text-white pr-2.5 ${
                                            link?.isActive ? "font-bold underline" : ""
                                        }`}
                                    >
                                        {link.text}
                                    </NavLink>
                                );
                            })}
                        </div>
                        <div className="flex gap-8 pr-6">
                            {/* <span className="material-icons-outlined text-white text-lg cursor-pointer">
                                notifications
                            </span> */}
                            <span
                                onClick={() => logout()}
                                className="material-icons-outlined text-white text-lg cursor-pointer"
                            >
                                logout
                            </span>
                        </div>
                    </div>
                    <div className={`${childrenContainerClass}`}>
                        {isLoading ? (
                            <div className="w-full h-full py-8 flex items-center justify-center">
                                <Loader color={"#5386DB"} />
                            </div>
                        ) : (
                            children
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PageFrame;
