export const authTypes = {
    USER_LOGIN_REQUEST: "USER_LOGIN_REQUEST",
    USER_LOGIN_SUCCESS: "USER_LOGIN_SUCCESS",
    USER_LOGIN_FAILURE: "USER_LOGIN_FAILURE",
    USER_SIGNUP_REQUEST: "USER_SIGNUP_REQUEST",
    USER_SIGNUP_SUCCESS: "USER_SIGNUP_SUCCESS",
    USER_SIGNUP_FAILURE: "USER_SIGNUP_FAILURE",
    REQUEST_PASSWORD_RESET_REQUEST: "REQUEST_PASSWORD_RESET_REQUEST",
    REQUEST_PASSWORD_RESET_SUCCESS: "REQUEST_PASSWORD_RESET_SUCCESS",
    REQUEST_PASSWORD_RESET_FAILURE: "REQUEST_PASSWORD_RESET_FAILURE",
    PASSWORD_RESET_REQUEST: "PASSWORD_RESET_REQUEST",
    PASSWORD_RESET_SUCCESS: "PASSWORD_RESET_SUCCESS",
    PASSWORD_RESET_FAILURE: "PASSWORD_RESET_FAILURE",
};
