import React, { useEffect, useState } from "react";
import { vendorActions } from "../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import AddressInput from "./InputFields/Addressinput";
import { useNavigate, useParams } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import Map from "./Map";
import GoogleMaps from "./GoogleMap";
import Loader from "./Loader";

const CreateStore = ({ setAddStore }) => {
    const noqUser = localStorage.getItem("noqUser") || sessionStorage.getItem("noqUser");
    const [user] = useState(noqUser);
    const userObj = JSON.parse(user);

    const [storeDetails, setStoreDetails] = useState({});
    const [address, setAddress] = useState({});
    const [latLng, setLatLng] = useState({
        lat: "",
        lng: "",
    });
    const [vendors, setVendors] = useState({});
    const [isCentering, setIsCentering] = useState(false);
    const [addStoreData, setAddStoreData] = useState({
        name: "",
        services: "",
    });

    const { storeId } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {
        isAddingVendorStore,
        hasAddedVendorStoreSuccess,
        isFetchedVendorsSuccess,
        isFetchingStoreDetails,
        isFetchedStoreDetailsSuccess,
        isUpdatingStore,
        isUpdateStoreSuccess,
    } = useSelector((state) => state.vendor);

    const handleChange = (name, value) => {
        setAddStoreData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleGetAddress = async (lat, lng) => {
        try {
            const response = await fetch(
                `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.REACT_APP_GOOGLE_MAP_API}`
            );
            const addressData = await response.json();

            console.log("addressData", addressData);

            if (addressData?.results?.length > 0) {
                setAddress((prev) => ({
                    ...prev,
                    description: addressData?.results?.[0]?.formatted_address,
                    lat,
                    lng,
                }));
            } else {
                // setAddress("Address not found");
                console.log("first", lat, lng);
            }
        } catch (error) {
            console.error("Error fetching address:", error);
        }
    };

    const recenter = async () => {
        setIsCentering(true);
        navigator.geolocation.getCurrentPosition(
            (position) => {
                setLatLng({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                });
                setIsCentering(false);
            },
            (error) => {
                setIsCentering(false);
                console.error("Error getting user location:", error);
            },
            {
                enableHighAccuracy: true,
                timeout: 10000, // 10 seconds
                maximumAge: 0, // Do not use a cached position
            }
        );
    };

    useEffect(() => {
        handleGetAddress(latLng?.lat, latLng?.lng);
    }, [latLng?.lat, latLng?.lng]);

    useEffect(() => {
        if (userObj?.token && storeId) {
            // setIsLoading(true);
            dispatch(vendorActions.getVendors(userObj.token));
        }
    }, [dispatch, userObj?.token]);

    useEffect(() => {
        if (storeId && userObj?.token) {
            dispatch(vendorActions.getStoreDetails(storeId, userObj?.token));
        }
    }, [dispatch, storeId, userObj?.token]);

    useEffect(() => {
        if (isFetchedStoreDetailsSuccess && storeId) {
            setStoreDetails(isFetchedStoreDetailsSuccess);
            setAddStoreData({
                name: isFetchedStoreDetailsSuccess?.name,
                services: isFetchedStoreDetailsSuccess?.services,
            });
            handleGetAddress(
                Number(isFetchedStoreDetailsSuccess?.latitude),
                Number(isFetchedStoreDetailsSuccess?.longitude)
            );
        }
    }, [isFetchedStoreDetailsSuccess]);

    useEffect(() => {
        if (isFetchedVendorsSuccess) {
            // console.log("isFetchedVendorsSuccess", isFetchedVendorsSuccess);
            setVendors(isFetchedVendorsSuccess?.[0]);
            // setAddStore?.(true);
            // console.log("first", isFetchedVendorsSuccess?.[0]?.id);
        }
    }, [dispatch, isFetchedVendorsSuccess]);

    useEffect(() => {
        if (hasAddedVendorStoreSuccess) {
            console.log("addedddd");
            setAddStore?.(false);
            setAddStoreData({ name: "", services: "" });
            setAddress({});
            dispatch(vendorActions.resetAddVendorStore());
        }
        if (isUpdateStoreSuccess) {
            console.log("updated");
            navigate(`/dashboard/store/${storeId}`);
            setAddStoreData({ name: "", services: "" });
            setAddress({});
            dispatch(vendorActions.resetUpdateVendorStore());
        }
    }, [hasAddedVendorStoreSuccess, isUpdateStoreSuccess]);

    return (
        <div className="relative">
            <div className="absolute">
                <span
                    className="material-icons-outlined cursor-pointer"
                    onClick={() => {
                        if (storeId) {
                            navigate(`/dashboard/store/${storeId}`);
                        } else {
                            setAddStore?.(false);

                            setAddStoreData({ name: "", services: "" });
                            setAddress({});
                        }
                    }}
                >
                    keyboard_backspace
                </span>
            </div>
            <div className="flex justify-center">
                <div className="w-[45%] max-sm:w-[100%]">
                    <div className="text-center mb-6">
                        <p className="text-xl font-bold">
                            {storeId ? "Edit StoreFront" : "Add New Storefront"}
                        </p>
                        <p className="text-base text-grey-subtext">
                            {storeId ? "Edit" : "Enter"} name of your storefront and services that
                            you will be providing at this storefront.
                        </p>
                    </div>
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            if (!storeId) {
                                dispatch(
                                    vendorActions.addVendorStore(
                                        vendors?.id,
                                        {
                                            ...addStoreData,
                                            latitude: address?.lat,
                                            longitude: address?.lng,
                                        },
                                        userObj?.token
                                    )
                                );
                            } else {
                                dispatch(
                                    vendorActions.updateVendorStore(
                                        storeId,
                                        {
                                            ...addStoreData,
                                            latitude: address?.lat,
                                            longitude: address?.lng,
                                        },
                                        userObj?.token
                                    )
                                );
                            }
                        }}
                    >
                        <div className="mb-4">
                            <p className="text-base mb-1 text-grey-subtext font-semibold">
                                Storefront Name
                            </p>
                            <input
                                type={"text"}
                                placeholder="Enter your store name"
                                className="input-field"
                                required
                                name="name"
                                value={addStoreData.name}
                                onChange={(e) => handleChange(e.target.name, e.target.value)}
                            />
                        </div>
                        <div className="mb-4">
                            <p className="text-base mb-1 text-grey-subtext font-semibold">
                                Store Address
                            </p>

                            <AddressInput
                                setData={setAddress}
                                savedAddress={address}
                                placeholder="Store address"
                                required={true}
                                name={"storeAddress"}
                            />
                        </div>

                        {address?.description && (
                            <div className="mb-4">
                                <div className="flex items-center justify-between mb-2">
                                    <p className="text-base mb-1 text-grey-subtext font-semibold">
                                        Pin exact location
                                    </p>

                                    <button
                                        onClick={recenter}
                                        type="button"
                                        className="py-2 px-3 bg-btn-blue rounded text-white flex space-x-2.5 items-center"
                                    >
                                        <i className="ri-map-pin-range-line"></i>{" "}
                                        <span className="text-sm font-semibold">
                                            Use current position
                                        </span>
                                        {isCentering && <Loader size={4} color={"white"} />}
                                    </button>
                                </div>

                                <div>
                                    {/* <Map lat={address?.lat} lng={address?.lng} /> */}
                                    <GoogleMaps
                                        lat={address?.lat}
                                        lng={address?.lng}
                                        setLatLng={setLatLng}
                                    />
                                </div>
                            </div>
                        )}
                        <div className="">
                            <p className="text-base mb-1 text-grey-subtext font-semibold">
                                Services
                            </p>

                            <CreatableSelect
                                isMulti
                                options={[]}
                                value={
                                    addStoreData?.services
                                        ? addStoreData?.services?.split(",")?.map((item) => ({
                                              label: item,
                                              value: item,
                                          }))
                                        : []
                                }
                                required
                                name="services"
                                styles={{
                                    control: (base) => ({
                                        ...base,
                                        border: "2px solid #5386db",
                                        outline: "#5386db",
                                        borderRadius: "10px",
                                        minHeight: "50px",
                                        width: "100%",
                                        boxSizing: "border-box",
                                        backgroundColor: "transparent",
                                        padding: "0 12px",
                                    }),
                                }}
                                setValue={(v, a) => console.log("firstv", v)}
                                onChange={(values) => {
                                    console.log("values ===>>", values);
                                    handleChange(
                                        "services",
                                        values?.map((services) => services?.value)?.join(",")
                                    );
                                }}
                            />
                        </div>
                        <div className="px-10 mt-10 mb-6">
                            <button
                                type="submit"
                                className="bg-btn-blue w-full font-bold text-base text-white py-3 rounded"
                                disabled={isAddingVendorStore || isUpdatingStore}
                            >
                                {isAddingVendorStore
                                    ? "Adding Store..."
                                    : isUpdatingStore
                                    ? "Updating Store..."
                                    : "Submit"}
                            </button>
                        </div>
                        <div className="text-center">
                            <p
                                onClick={() => {
                                    if (storeId) {
                                        navigate(`/dashboard/store/${storeId}`);
                                    } else {
                                        setAddStore?.(false);
                                        setAddStoreData({ name: "", services: "" });
                                        setAddress({});
                                    }
                                }}
                                className="underline cursor-pointer font-bold text-base"
                            >
                                Back
                            </p>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default CreateStore;
