import React, {useState} from "react";

function MonthDropdown() {
    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
    const handleMonthChange = (event) => {
        setSelectedMonth(parseInt(event.target.value));
        
    };

    return (
        <div className="relative inline-block w-full max-w-sm">
          <select
            value={selectedMonth}
            onChange={handleMonthChange}
            className="inline-flex justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50" id="menu-button" aria-expanded="true" aria-haspopup="true" // Add relative class
>
            {months.map((month, index) => (
              <option key={index} value={index}>
                {month}
              </option>
            ))}
          </select>
        </div>
      );
}

export default MonthDropdown;