import React, { useEffect, useState } from "react";
import default_logo from "../../assets/images/default_logo.svg";
import NoQ_logo from "../../assets/icons/NO-Q_logo.png";

import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import { vendorActions } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../common/Loader";
import { toast } from "react-hot-toast";
import PageFrame from "../../common/PageFrame";
import vendorPayment from "../Dashboard/vendorPayment";
import { link } from "fs";
// import { link } from "fs";
// import { url } from "inspector";

const StoreDetail = () => {
    const params = useParams();
    const {
        isFetchingStoreDetails,
        isFetchedStoreDetailsSuccess,
        isUpdatingStore,
        isUpdateStoreSuccess,
        isPublishingStore,
        publishStoreSuccess,
        isUnpublishingStore,
        unpublishStoreSuccess,
    } = useSelector((store) => store.vendor);
    const noqUser = localStorage.getItem("noqUser") || sessionStorage.getItem("noqUser");
    const vendorInfo = JSON.parse(sessionStorage.getItem("vendorInfo") || "{}");

    const [storeDetails, setStoreDetails] = useState({});
    const [isEnabled, setIsEnabled] = useState(false);
    const [user] = useState(noqUser);
    const userObj = JSON.parse(user);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const copyText = (text) => {
        navigator.clipboard.writeText(text);
        toast.success("Copied Successfully");
    };

    // useEffect(() => {
    //     dispatch(vendorActions.getStoreDetails(params.id, userObj?.token));
    // }, [dispatch, params?.id, userObj?.token]);

    useEffect(() => {
        if (isFetchedStoreDetailsSuccess) {
            setStoreDetails(isFetchedStoreDetailsSuccess);
        }
    }, [isFetchedStoreDetailsSuccess]);

    useEffect(() => {
        if (Boolean(publishStoreSuccess)) {
            setStoreDetails(publishStoreSuccess);
            dispatch(vendorActions?.resetPublishStoreSuccess());
        }
    }, [publishStoreSuccess]);

    useEffect(() => {
        if (Boolean(unpublishStoreSuccess)) {
            setStoreDetails(unpublishStoreSuccess);
            dispatch(vendorActions?.resetUnpublishStoreSuccess());
        }
    }, [unpublishStoreSuccess]);
    console.log("storeDetails", storeDetails);
    return (
        <div className="font-sans flex flex-row h-screen">
            <PageFrame
                isLoading={isFetchingStoreDetails}
                frameLinks={[
                    // { text: "Dashboard", url: `/dashboard`, isActive: false },

                    {
                        text: "Store Details",
                        url: `/dashboard/store/${params?.storeId}`,
                        isActive: true,
                    },
                    {
                        text: "Manage Orders",
                        url: `/dashboard/store/${params?.storeId}/orders`,
                        isActive: false,
                    },

                    {
                        text: "Store Earnings",
                        url: `/dashboard/store/${params?.storeId}/payment`,
                        isActive: false,
                    },
                ]}
                childrenContainerClass={` bg-white border w-full overflow-y-auto`}
            >
                <div className="flex justify-center mt-10 max-sm:mt-16 ">
                    <div className="mx-20 max-sm:mx-3">
                        {/* <div className="flex max-sm:flex-col justify-between mt-5">
                        <div
                            className="flex items-center gap-1.5 cursor-pointer max-sm:mb-3"
                            onClick={() => navigate("/dashboard")}
                        >
                            <span className="material-icons text-base">west</span>
                            <span className="text-sm">Back</span>
                        </div>


                    </div> */}
                        <p className="text-3xl font-bold mb-2">Store Details</p>
                        <p className="text-2xl mb-2">{storeDetails?.name}</p>

                        <div className="flex flex-row items-center bg-white border gap-3 rounded-md w-fit p-2 ">
                            <a
                                href={`/dashboard/store/${params?.storeId}/edit`}
                                className="flex items-center gap-1.5 pr-4 hover:text-white hover:bg-black  "
                            >
                                <i className="ri-edit-2-fill text-sm"></i>
                                <span className="text-sm max-sm:hidden">Edit Store</span>
                                <span className="text-sm max-sm:block hidden">Edit</span>
                            </a>
                            <span className="w-0.5 h-4 bg-black"></span>
                            <a
                                href={`/dashboard/store/${params?.storeId}/products`}
                                className="flex items-center gap-1.5 pr-4 hover:text-white hover:bg-black"
                            >
                                <span className="material-icons text-sm">inventory</span>
                                <span className="text-sm ">Manage Products</span>
                                {/* <span className="text-sm max-sm:block hidden">Products</span> */}
                            </a>
                        </div>
                        <div className="flex mt-5  items-center gap-11 max-sm:flex-col max-md:flex-col ">
                            <div className=" flex flex-col">
                                <p className="font-bold text-xl">Store QR Code:</p>

                                <div className=" flex flex-col items-end gap-5 bg-[#181818] px-6  py-6">
                                    <div
                                        className="max-sm:w-full qrcode-container bg-white p-3"
                                        id="qrcode-container"
                                        dangerouslySetInnerHTML={{
                                            __html: storeDetails?.qr_code,
                                        }}
                                    />

                                    <div
                                        onClick={() => {
                                            const qrCodeContainer =
                                                document.querySelector("#qrcode-container");
                                            const printWindow = window.open("", "_blank");
                                            printWindow.document.write(`
                                                <html>
                <head>
                    <title>Store QR Code</title>
                    <style>
                        body {
                            margin: 0;
                            padding: 0;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            height: 100vh;
                            background-color: #f0f0f0;
                            font-family: Arial, sans-serif;
                        }
                        .qr-code-container {
                            text-align: center;
                            background-color: white;
                            padding: 20px;
                            border-radius: 10px;
                            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                        }
                        .qr-code-container h1 {
                            font-size: 32px;
                            font-weight: bold;
                            color: #333;
                            margin-bottom: 20px;
                        }
                        .qr-code-container p {
                            font-size: 22px;
                            color: #555;
                            margin-bottom: 20px;
                        }
                        .qr-code-container .powered-by {
                            font-size: 14px;
                            color: #777;
                            margin-top: 20px;
                        }
                    </style>
                </head>
                <body>
                    <div class="qr-code-container">
                        <h1><i>Scan QR Code to skip the line</i></h1>
                        <p>Store Name: ${storeDetails?.name}</p>
                        ${qrCodeContainer.innerHTML}
                        <div class="powered-by">Powered by No-Q (www.no-q.ca)</div>
                    </div>
                </body>
            </html>
        `);
                                            printWindow.document.close();
                                            printWindow.print();
                                        }}
                                        className="flex flex-row text-white w-fit p-2 space-x-2 rounded-md border-white border-2 cursor-pointer hover:text-black hover:bg-white"
                                    >
                                        <i className="ri-printer-fill"></i>

                                        <p>Print</p>
                                    </div>
                                </div>
                            </div>
                            <div className="w-1/2 max-sm:w-full mb-10">
                                <img
                                    src={vendorInfo?.logo_url || NoQ_logo}
                                    width={vendorInfo?.logo_url ? 80 : 60}
                                    height={vendorInfo?.logo_url ? 80 : 60}
                                    alt="logo"
                                    className="mb-3"
                                />
                                <hr />
                                <div className="py-2 text-xl">
                                    <p className="font-bold">Store URL:</p>
                                    <p className="flex items-center gap-2">
                                        <span className="text-base max-sm:text-wrap">
                                            {storeDetails?.url}{" "}
                                        </span>
                                        <span
                                            onClick={() => copyText(storeDetails?.url)}
                                            className="material-icons-outlined cursor-pointer text-lg"
                                        >
                                            content_copy
                                        </span>
                                    </p>
                                </div>
                                <hr />
                                <div className="py-2 text-xl">
                                    <p className="font-bold">Services:</p>
                                    <p className="text-base">{storeDetails?.services}</p>
                                </div>
                                <div className="flex items-center text-center">
                                    <button
                                        type="button"
                                        className={` ${
                                            storeDetails?.status === "published"
                                                ? "bg-red-600 hover:bg-red-700"
                                                : "bg-green-700 hover:bg-green-800"
                                        } w-full font-semibold text-white py-2 rounded-md   `}
                                        disabled={isPublishingStore || isUnpublishingStore}
                                        onClick={() => {
                                            // dispatch(
                                            //     vendorActions.updateVendorStore(
                                            //         params.id,
                                            //         {
                                            //             name: storeDetails?.name,
                                            //             services: storeDetails?.services,
                                            //             latitude: Number(storeDetails?.latitude),
                                            //             longitude: Number(storeDetails?.longitude),
                                            //             enabled: !storeDetails?.enabled,
                                            //         },
                                            //         userObj?.token
                                            //     )
                                            // );
                                            if (storeDetails?.status === "published") {
                                                dispatch(
                                                    vendorActions.unpublishStore(
                                                        params?.storeId,
                                                        userObj?.token
                                                    )
                                                );
                                            } else {
                                                dispatch(
                                                    vendorActions.publishStore(
                                                        params?.storeId,
                                                        userObj?.token
                                                    )
                                                );
                                            }
                                        }}
                                    >
                                        <span className="text-sm">
                                            {storeDetails?.status === "published"
                                                ? "Unpublish Store"
                                                : "Publish Store"}
                                        </span>
                                        {(isPublishingStore || isUnpublishingStore) && (
                                            <Loader size={4} color={"white"} />
                                        )}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </PageFrame>
        </div>
        // </PageFrame>
    );
};

export default StoreDetail;
