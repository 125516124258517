import { React, useEffect, useState } from "react";
import Loader from "./Loader";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import no_q_logo from "../assets/icons/NO-Q_logo.png";
import { List, X } from "@phosphor-icons/react";
import { useDispatch, useSelector } from "react-redux";
import { vendorActions } from "../redux/actions";

const vendorInfo = JSON.parse(sessionStorage.getItem("vendorInfo") || "{}");

const PageFrame = ({ isLoading, children, frameLinks, childrenContainerClass }) => {
    const params = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const noqUser = localStorage.getItem("noqUser") || sessionStorage.getItem("noqUser");
    const userObj = JSON.parse(noqUser);

    const { isFetchedStoreDetailsSuccess } = useSelector((store) => store.vendor);

    const logout = () => {
        // remove user from local storage to log user out
        dispatch(vendorActions.resetGetStoreDetailsSuccess());
        localStorage.removeItem("noqUser");
        sessionStorage.removeItem("noqUser");
        window.dispatchEvent(new Event("storage"));
    };

    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        if (!isFetchedStoreDetailsSuccess && params?.storeId) {
            dispatch(vendorActions.getStoreDetails(params?.storeId, userObj?.token));
        }
    }, [dispatch, params?.storeId, userObj?.token, isFetchedStoreDetailsSuccess]);

    return (
        <div className="">
            <div className="max-sm:hidden h-screen w-screen font-sans flex">
                <div className="w-[19%] shrink-0 h-full flex flex-col  py-4 px-2 bg-[#181818]">
                    <div className="flex flex-row gap-4 text-white items-center font-bold">
                        <img src={no_q_logo} alt="logo" className="w-[60px] h-auto" />
                        <p>{isFetchedStoreDetailsSuccess?.name}</p>
                    </div>

                    <div className=" h-full mt-32 flex flex-col justify-between">
                        <div className="flex flex-col gap-[10px] w-full ">
                            {frameLinks?.map((link, idx) => {
                                return (
                                    <NavLink
                                        key={idx}
                                        to={link.url}
                                        className={`text-sm py-2 text-white w-full  px-2.5 hover:bg-white hover:bg-opacity-10 hover:rounded-md ${
                                            link?.isActive
                                                ? "font-bold  text-black bg-white  rounded-md"
                                                : ""
                                        }`}
                                    >
                                        {link.text}
                                    </NavLink>
                                );
                            })}
                        </div>

                        {/* <div className="flex gap-2 pr-6 text-red-700 text-sm  w-full  px-2.5 items-center">
                       
                        <span
                            onClick={() => logout()}
                            className="material-icons-outlined  text-lg cursor-pointer"
                        >
                            logout
                        </span>
                        <span onClick={() => logout()} className="cursor-pointer">Logout</span>
                    </div> */}

                        <div className="space-y-2">
                            <div
                                className="flex space-x-2 pr-6 text-red-700 text-sm   w-fit  px-2.5 items-center"
                                onClick={() => {
                                    dispatch(vendorActions.resetGetStoreDetailsSuccess());
                                    navigate("/dashboard");
                                }}
                            >
                                {/* <span className="material-icons-outlined  text-lg cursor-pointer">
                                close
                            </span>
                            <span className="cursor-pointer">Close</span> */}
                                <i className="ri-arrow-left-line text-lg"></i>
                                <span className="cursor-pointer">Back to Stores</span>
                            </div>
                            <hr />

                            <div className="flex space-x-2 text-red-700 text-sm  w-fit px-2.5 items-center">
                                <span
                                    onClick={() => logout()}
                                    className="material-icons-outlined  text-lg cursor-pointer"
                                >
                                    logout
                                </span>
                                <span onClick={() => logout()} className="cursor-pointer">
                                    Logout
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className={`${childrenContainerClass} w-fill-available h-full overflow-y-auto flex items-center justify-center`}
                >
                    {isLoading ? (
                        <div className="w-full h-full py-8 flex items-center justify-center">
                            <Loader color={"#5386DB"} />
                        </div>
                    ) : (
                        children
                    )}
                </div>
            </div>

            {/* Mobile Navigation */}

            <div className="hidden max-sm:flex max-sm:z-30 max-sm:opacity-100 ">
                <div className="w-full h-14 fixed justify-between items-center max-sm:z-30 flex flex-row   py-4 px-2 bg-[#181818]">
                    <div className="flex flex-row gap-4 text-white items-center font-bold">
                        <img src={no_q_logo} alt="logo" className="w-[60px] h-auto" />
                        <p>{isFetchedStoreDetailsSuccess?.name}</p>
                    </div>

                    <div className="w-fit right-0 items-end justify-end  hidden max-sm:flex ">
                        {/* Hamburger Icon */}
                        <button
                            className="md:hidden p-2 focus:outline-none"
                            onClick={toggleMenu}
                            aria-label="Toggle Menu"
                        >
                            {isOpen ? (
                                <X size={30} className=" text-white" />
                            ) : (
                                <List size={30} className=" text-white" />
                            )}
                        </button>

                        <div
                            className={`absolute w-[90%] top-24 bg-[#181818]  max-sm:z-30 border-t-4 border border-t-red-600 transform transition-transform duration-100 ease-in-out ${
                                isOpen ? "-translate-y-[40px] " : "-translate-y-[500px]"
                            }`}
                        >
                            <div className="flex flex-col gap-2 text-lg font-bold  p-4">
                                {frameLinks?.map((link, idx) => {
                                    return (
                                        <NavLink
                                            key={idx}
                                            to={link.url}
                                            className={`text-sm py-2 text-white w-full  px-2.5 hover:bg-white hover:bg-opacity-10 hover:rounded-md ${
                                                link?.isActive
                                                    ? "font-bold  text-black bg-white  rounded-md"
                                                    : ""
                                            }`}
                                        >
                                            {link.text}
                                        </NavLink>
                                    );
                                })}

                                <div className="space-y-2">
                                    <div
                                        className="flex space-x-2 pr-6 text-red-700 text-sm   w-fit  px-2.5 items-center"
                                        onClick={() => {
                                            dispatch(vendorActions.resetGetStoreDetailsSuccess());
                                            navigate("/dashboard");
                                        }}
                                    >
                                        {/* <span className="material-icons-outlined  text-lg cursor-pointer">
                                close
                            </span>
                            <span className="cursor-pointer">Close</span> */}
                                        <i className="ri-arrow-left-line text-lg"></i>
                                        <span className="cursor-pointer">Back to Stores</span>
                                    </div>

                                    <hr />

                                    <div className="flex space-x-2 text-red-700 text-sm  w-fit px-2.5 items-center">
                                        <span
                                            onClick={() => logout()}
                                            className="material-icons-outlined  text-lg cursor-pointer"
                                        >
                                            logout
                                        </span>
                                        <span onClick={() => logout()} className="cursor-pointer">
                                            Logout
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`${childrenContainerClass}`}>
                    {isLoading ? (
                        <div className="w-full h-full py-8 flex items-center justify-center">
                            <Loader color={"#5386DB"} />
                        </div>
                    ) : (
                        children
                    )}
                </div>
            </div>
        </div>
    );
};

export default PageFrame;
