import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { vendorActions } from "../../redux/actions";
import Loader from "../../common/Loader";
import PageFrame from "../../common/PageFrame";
import ModalContainer from "../../common/ModalContainer";
// import Add_Button from "./assets/Add_Button.svg";
import { flatten, set, uniqBy } from "lodash";
import MonthDropdown from "../Signup/components/MonthDropdown";
import YearDropdown from "../Signup/components/YearDropdown";

const VendorPayment = () => {
    const params = useParams();
    const tabledata = [
        { name: "Earnings", price: 5500 },
        { name: "Platform Fee", price: -250 },
        { name: "Taxes", price: -110 },
        { name: "Others", price: -40 },
    ];
    const {
        isFetchingStoreDetails,
        isFetchedStoreDetailsSuccess,
        isUpdatingStore,
        isUpdateStoreSuccess,
    } = useSelector((store) => store.vendor);

    const calculateTotal = () => tabledata.reduce((acc, item) => acc + item.price, 0);
    const [storeDetails, setStoreDetails] = useState({});
    const noqUser = localStorage.getItem("noqUser") || sessionStorage.getItem("noqUser");
    const [user] = useState(noqUser);
    const userObj = JSON.parse(user);
    const dispatch = useDispatch();
    const [detailModal, setDetailModal] = useState(false);

    useEffect(() => {
        dispatch(vendorActions.getStoreDetails(params.storeId, userObj?.token));
    }, [dispatch, params?.storeId, userObj?.token]);

    useEffect(() => {
        if (isFetchedStoreDetailsSuccess) {
            setStoreDetails(isFetchedStoreDetailsSuccess);
        }
    }, [isFetchedStoreDetailsSuccess]);

    // useEffect(() => {
    //     if (Boolean(isUpdateStoreSuccess)) {
    //         setStoreDetails(isUpdateStoreSuccess);
    //         dispatch(vendorActions?.resetUpdateVendorStore());
    //     }
    // }, [isUpdateStoreSuccess]);

    return (
        <>
            <PageFrame
                // isLoading={isFetchingStoreOrders}
                frameLinks={[
                    { text: "Dashboard", url: `/dashboard`, isActive: false },
                    {
                        text: "Store Details",
                        url: `/dashboard/store/${params?.storeId}`,
                        isActive: false,
                    },
                    {
                        text: "Manage Orders",
                        url: `/dashboard/store/${params?.storeId}/orders`,
                        isActive: false,
                    },

                    {
                        text: "Store Earnings",
                        url: "#",
                        isActive: true,
                    },
                ]}
                childrenContainerClass={`p-[30px] bg-white shadow-lg border rounded-lg min-h-[440px] w-full overflow-hidden max-sm:h-screen `}
            >
                <div>
                    <div className="flex flex-col ">
                        {/* <p className="text-xl font-bold ">Store Earnings (Store name here) </p> */}
                        <p className="text-xl font-bold ">Store Earnings ({storeDetails?.name}) </p>
                        <p>
                            {" "}
                            <a
                                className="text-xs text-blue-500 underline cursor-pointer"
                                onClick={() => setDetailModal(true)}
                            >
                                Edit payment information (applies to all stores)
                            </a>
                        </p>
                    </div>

                    <div className="flex flex-row gap-5 items-center my-5">
                        <div className="">
                            <MonthDropdown />
                        </div>

                        <div className="">
                            <YearDropdown startYear={2000} endYear={2025} />{" "}
                            {/* Specify custom start and end year */}
                        </div>
                        <p className="text-sm ">Your next payment date is June 24. </p>
                    </div>
                    <hr className="border-gray-200" />
                    <div className="flex flex-col gap-5  my-5">
                        <p className="text-lg font-bold">Total Earning: ${calculateTotal()} </p>
                        <p className="text-lg font-bold ">Breakdown </p>
                        <table className="table-auto w-[50%] max-sm:w-full rounded-md outline outline-1 outline-gray-400 overflow-hidden">
                            {/* <thead>
                    <tr>
                    <th className="px-6 py-3 text-left text-md font-medium text-gray-500 uppercase border-b border-gray-200">
                        Item
                    </th>
                    <th className="px-6 py-3 text-right text-md font-medium text-gray-500 uppercase border-b border-gray-200">
                        Price
                    </th>
                    </tr>
                </thead> */}
                            <tbody>
                                {tabledata.map((item, index) => (
                                    <tr key={index}>
                                        <td className="px-6 py-2 whitespace-nowrap border-b border-gray-200">
                                            {item.name}
                                        </td>
                                        <td className="px-6 py-2 text-right whitespace-nowrap border-b border-gray-200">
                                            ${item.price}
                                        </td>
                                    </tr>
                                ))}
                                {/* Total Row */}
                                <tr className="font-medium text-gray-800">
                                    <td className="px-6 py-2 whitespace-nowrap border-t border-gray-200">
                                        Total
                                    </td>
                                    <td className="px-6 py-2 text-right whitespace-nowrap border-t border-gray-200">
                                        ${calculateTotal()}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </PageFrame>

            <ModalContainer
                open={detailModal}
                showCloseIcon={false}
                tailwindClassName="w-[48.6%] max-lg:w-1/2 max-sm:w-[95%]"
                closeModal={() => setDetailModal(false)}
            >
                <div className="w-full p-[30px] bg-white rounded-md">
                    <div>
                        <div className="flex flex-col">
                            <div className="flex items-center justify-center">
                                <p className="text-xl font-bold mb-[18px] ">Payment information</p>
                            </div>

                            <div className=" ">
                                <div className=" mb-5 w-full">
                                    <label
                                        for="first_name"
                                        class="mb-2 text-sm font-medium text-gray-900 "
                                    >
                                        First Name*
                                    </label>
                                    <input
                                        type="text"
                                        id="first_name"
                                        class="bg-gray-50 border border-blue-900  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                        placeholder=""
                                        required
                                    />
                                </div>

                                <div className="mb-5">
                                    <label
                                        for="last_name"
                                        class="mb-2 text-sm font-medium text-gray-900 "
                                    >
                                        Last Name*
                                    </label>
                                    <input
                                        type="text"
                                        id="last_name"
                                        class="bg-gray-50 border border-blue-900  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                        placeholder=""
                                        required
                                    />
                                </div>

                                <div className="mb-5">
                                    <label
                                        for="interac_email"
                                        class="mb-2 text-sm font-medium text-gray-900 "
                                    >
                                        Interac Email Address*
                                    </label>
                                    <input
                                        type="text"
                                        id="interac_email"
                                        class="bg-gray-50 border border-blue-900  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                        placeholder=""
                                        required
                                    />
                                </div>
                            </div>

                            <div className="flex flex-row">
                                <div className="w-1/2">
                                    <p onClick={() => setDetailModal(false)} className="underline">
                                        Cancel
                                    </p>
                                </div>
                                <button
                                    className="float-right bg-blue-700 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded w-1/2"
                                    onClick={() => {
                                        /* handle form submission here */
                                    }}
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalContainer>
        </>
    );
};

export default VendorPayment;
