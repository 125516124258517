import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { vendorActions } from "../../redux/actions";
import Loader from "../../common/Loader";
import PageFrame from "../../common/PageFrame";
import ModalContainer from "../../common/ModalContainer";
// import Add_Button from "./assets/Add_Button.svg";
import { flatten, set, truncate, uniqBy } from "lodash";

const ManageProducts = () => {
    const [deleteModal, setDeleteModal] = useState(false);
    const [activeCatId, setActiveCatId] = useState("");
    const [activeCatIdx, setActiveCatIdx] = useState("");
    const [activeSubCatId, setActiveSubCatId] = useState(null);
    const [storeDetails, setStoreDetails] = useState({});

    const [categories, setCategories] = useState([
        {
            id: 1,
            name: "Category1",
            disabled: true,
            uuid: "",
        },
    ]);
    const [subCategories, setSubCategories] = useState([
        {
            id: 1,
            name: "Product1",
            disabled: true,
            uuid: "",
            categories: [],
        },
    ]);
    const [allData, setAllData] = useState([
        {
            id: 1,
            name: "Category1",
            disabled: true,
            uuid: "",
            products: [],
        },
    ]);
    const [selectedCatName, setSelectedCatName] = useState("");
    const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
    const [deleteProductModal, setDeleteProductModal] = useState(false);
    const [activeSubCatIdx, setActiveSubCatIdx] = useState(null);
    const [activeSubCatUuid, setActiveSubCatUuid] = useState(null);
    // const [openCategories, setOpenCategories] = useState([]);
    console.log("categories --->>", categories);
    console.log("subCategories --->>", subCategories);
    console.log("allData --->>", allData);
    const noqUser = localStorage.getItem("noqUser") || sessionStorage.getItem("noqUser");
    const [user] = useState(noqUser);
    const userObj = JSON.parse(user);
    const params = useParams();
    const dispatch = useDispatch();
    const allStoreData = [...allData];
    const activeCatIndex = allData.findIndex((data) => data.uuid === activeCatId);
    const productDetails = allData?.[activeCatIndex]?.products;

    const {
        isFetchingVendors,
        isFetchedVendorsSuccess,
        isFetchingCategories,
        isFetchedCategoriesSuccess,
        isFetchingCategoryProducts,
        isFetchedCategoryProductSuccess,
        isCreatingCategory,
        isUpdatingCategory,
        isCreatingCategorySuccess,
        isCreatingProduct,
        isCreatingProductSuccess,
        isSavingAllProducts,
        isDeletingCategory,
        isDeletingCategorySuccess,
        isFetchingStoreDetails,
        isFetchedStoreDetailsSuccess,
        isDeletingProduct,
        deleteProductSuccess,
    } = useSelector((store) => store.vendor);

    const addCategory = () => {
        setCategories((prev) => {
            return [
                ...prev,
                {
                    id: prev[prev.length - 1].id + 1,
                    name: "Category" + (prev[prev.length - 1].id + 1),
                    disabled: false,
                    uuid: "",
                    products: [],
                },
            ];
        });

        setAllData((prev) => {
            return [
                ...prev,
                {
                    id: prev[prev.length - 1].id + 1,
                    name: "Category" + (prev[prev.length - 1].id + 1),
                    disabled: false,
                    uuid: "",
                    products: [],
                },
            ];
        });
    };

    const addSubCategory = () => {
        const allDataCopy = [...allData];
        const activeId = allDataCopy.findIndex((data) => data.uuid === activeCatId);
        const allDataProducts = allDataCopy[activeId].products;

        setSubCategories((prev) => {
            return [
                ...prev,
                {
                    id: prev[prev.length - 1].id + 1,
                    name: "Product" + (prev[prev.length - 1].id + 1),
                    disabled: true,
                    uuid: "",
                    price: "",
                    isExtra: false,
                    description: "",
                },
            ];
        });

        allDataCopy[activeId].products = [
            ...allDataProducts,
            {
                id: allDataProducts[allDataProducts.length - 1].id + 1,
                name: "Product" + (allDataProducts[allDataProducts.length - 1].id + 1),
                disabled: true,
                uuid: "",
                price: "",
                isExtra: false,
                description: "",
            },
        ];
        setAllData(allDataCopy);
        setActiveSubCatUuid("");
        setActiveSubCatIdx(allDataProducts[allDataProducts.length - 1].id + 1);
        setActiveSubCatId(() => allDataProducts.length);
    };

    const handleEditCategory = (cat, idx) => {
        const allDataCopy = [...allData];
        const activeId = allDataCopy.findIndex((data) => data.uuid === cat.uuid);
        const selectedCat = allDataCopy[activeId];

        setActiveCatId(cat.uuid);
        setActiveSubCatId(null);

        const cats = [...categories];
        const cate = cats[idx];
        cate.disabled = !cate.disabled;
        // allDataCopy[activeId].name = cate.name;
        selectedCat.disabled = false;

        setCategories([...cats]);
        setAllData(allDataCopy);
    };

    const handleSaveCategory = (cat, idx) => {
        const allDataCopy = [...allData];
        const activeId = allDataCopy.findIndex((data) => data.uuid === cat.uuid);
        const selectedCat = allDataCopy[activeId];

        setActiveCatId(cat.uuid);
        setActiveSubCatId(null);

        const cats = [...categories];
        const cate = cats[idx];
        cate.disabled = !cate.disabled;
        selectedCat.disabled = true;

        if (selectedCat?.uuid) {
            dispatch(
                vendorActions.updateCategory(
                    isFetchedVendorsSuccess?.[0]?.id,
                    selectedCat?.uuid,
                    userObj?.token,
                    {
                        name: selectedCat?.name,
                    }
                )
            );
        } else {
            dispatch(
                vendorActions.createCategory(
                    isFetchedVendorsSuccess?.[0]?.id,
                    params?.storeId,
                    userObj?.token,
                    {
                        name: selectedCat?.name,
                    }
                )
            );
        }

        setCategories([...cats]);
        setAllData(allDataCopy);
    };

    const removeCategory = useCallback(
        (id) => {
            const categoriesCopy = [...categories];
            const allDataCopy = [...allData];
            const indexToDelete = categoriesCopy.findIndex(
                (cat) => cat.id === id || cat.uuid === id
            );
            const allDataIndexToDelete = allDataCopy.findIndex(
                (cat) => cat.id === id || cat.uuid === id
            );

            if (indexToDelete !== -1) {
                categoriesCopy.splice(indexToDelete, 1);
                setCategories(() => {
                    return categoriesCopy;
                });

                setActiveCatId(categoriesCopy?.[0].uuid);
                setActiveSubCatId(null);
                setSelectedCatName(categoriesCopy?.[0]?.name);
            }

            if (allDataIndexToDelete !== -1) {
                allDataCopy.splice(allDataIndexToDelete, 1);
                setAllData(() => {
                    return allDataCopy;
                });
            }
        },
        [categories]
    );

    const removeSubCategory = (id) => {
        const subCategoriesCopy = [...subCategories];
        const indexToDelete = subCategoriesCopy.findIndex((cat) => cat.id === id);
        const allDataCopy = [...allData];
        const activeId = allDataCopy.findIndex((data) => data.uuid === activeCatId);
        const productsCopy = allDataCopy[activeId].products;
        const prodIndexToDelete = productsCopy.findIndex((prod) => prod.id === id);

        if (indexToDelete !== -1) {
            subCategoriesCopy.splice(indexToDelete, 1);
            setSubCategories(() => {
                return subCategoriesCopy;
            });
        }

        if (prodIndexToDelete !== -1) {
            productsCopy.splice(prodIndexToDelete, 1);
            setAllData(() => {
                return allDataCopy;
            });
        }
    };

    const handleSaveChanges = () => {
        const cats = [...productDetails];
        const allDataCopy = [...allData];
        const activeId = allDataCopy.findIndex((data) => data.uuid === activeCatId);

        const cate = cats[activeSubCatId || 0];
        const allDataProd = allDataCopy[activeId].products;
        cate.disabled = !cate.disabled;
        allDataProd[activeSubCatId || 0].disabled = !allDataProd.disabled;

        const formData = new FormData();
        const bodyData = allDataCopy?.map((data) => {
            const parsedData = data?.products?.map((prod, idx) => {
                const parsedProd = {
                    id: prod?.uuid,
                    name: prod?.name,
                    price: prod?.price,
                    description: prod?.description,
                    stores: [params.storeId],
                    categories: [data.uuid],
                    // images: [prod?.image || prod?.imagePreview],
                };

                if (prod?.image) {
                    parsedProd.images = [prod?.image];
                }

                if (cate.uuid) {
                    parsedProd.update_base_product = true;
                    // formData.append(
                    //     `products[${idx}][update_base_product]`,
                    //     true
                    // );
                }

                return parsedProd;
            });

            return parsedData;
        });

        console.log("bodyData", bodyData);
        formData.append(`_method`, "PATCH");
        flatten(bodyData).forEach((product, index) => {
            Object.keys(product).forEach((key) => {
                console.log("arr-->", product[key]);
                if (Array.isArray(product[key])) {
                    product[key].forEach((value, i) => {
                        formData.append(`products[${index}][${key}][${i}]`, value);
                    });
                } else {
                    formData.append(
                        `products[${index}][${key}]`,
                        key === "update_base_product"
                            ? product[key] === "true" || cate.uuid
                                ? true
                                : false
                            : product[key]
                    );
                }
            });
        });
        console.log("formdata", Array.from(formData.entries()), bodyData);

        dispatch(
            vendorActions.saveAllProducts(
                isFetchedVendorsSuccess?.[0]?.id,
                // { products: flatten(bodyData) },
                formData,
                userObj?.token
            )
        );

        setSubCategories([...cats]);
    };

    const handleImageChange = (e) => {
        e.preventDefault();

        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();

            reader.onloadend = () => {
                setImagePreviewUrl(reader.result);
            };

            reader.readAsDataURL(file);
        }
    };

    useEffect(() => {
        if (Boolean(isFetchedVendorsSuccess)) {
            dispatch(
                vendorActions.getCategories(
                    isFetchedVendorsSuccess?.[0]?.id,
                    userObj?.token,
                    params?.storeId
                )
            );
        }
    }, [dispatch, params?.storeId, userObj?.token, isFetchedVendorsSuccess]);

    useEffect(() => {
        if (userObj?.token && !Boolean(isFetchedVendorsSuccess)) {
            dispatch(vendorActions.getVendors(userObj.token));
        }
    }, [dispatch, userObj?.token, isFetchedVendorsSuccess]);

    useEffect(() => {
        if (userObj?.token && activeCatId) {
            dispatch(vendorActions.getCategoryProducts(activeCatId, userObj.token));
        }
    }, [dispatch, userObj?.token, activeCatId]);

    useEffect(() => {
        if (userObj?.token && params?.storeId) {
            dispatch(vendorActions.getStoreDetails(params.storeId, userObj?.token));
        }
    }, [dispatch, params?.storeId, userObj?.token]);

    useEffect(() => {
        if (isFetchedStoreDetailsSuccess) {
            setStoreDetails(isFetchedStoreDetailsSuccess);
        }
    }, [isFetchedStoreDetailsSuccess]);

    useEffect(() => {
        if (Boolean(isFetchedCategoryProductSuccess)) {
            console.log("isFetchedCategoryProductSuccess", isFetchedCategoryProductSuccess);
            const selectedCategoryProducts = isFetchedCategoryProductSuccess?.map(
                (product, idx) => ({
                    id: idx + 1,
                    name: product?.name,
                    disabled: true,
                    price: product.price,
                    isExtra: product.is_extra,
                    uuid: product?.id,
                    categories: product.categories,
                    imagePreview: product?.image_url,
                    description: product?.description,
                })
            );
            console.log("isFetchedCategoryProductSuccess", isFetchedCategoryProductSuccess);
            const subCategoryToStore =
                selectedCategoryProducts?.length > 0
                    ? selectedCategoryProducts
                    : [
                          {
                              id: 1,
                              name: "Product1",
                              disabled: true,
                              uuid: "",
                              price: "",
                              isExtra: false,
                              description: "",
                              categories: [],
                              imagePreview: "",
                          },
                      ];

            setSubCategories(subCategoryToStore);

            subCategoryToStore?.forEach((product, idx) => {
                const d = allData.findIndex((cat, index) => {
                    const t = product?.categories?.findIndex((prod) => prod.id === cat.uuid);

                    console.log("product", product, t);

                    if (t === -1) {
                        return false;
                    } else {
                        return true;
                    }
                });

                console.log("Dddd", d);
                if (d !== -1) {
                    const allDataCopy = [...allData];
                    const catProduct = [...allDataCopy[d].products];

                    allDataCopy[d] = {
                        ...allDataCopy[d],
                        products: uniqBy([...allDataCopy[d].products, ...subCategoryToStore], "id"),
                    };
                    setAllData(allDataCopy);
                } else {
                    const allDataCopy = [...allData];
                    const activeId = allDataCopy.findIndex((data) => data.uuid === activeCatId);
                    const catProduct = [...allDataCopy[activeId].products];
                    allDataCopy[activeId] = {
                        ...allDataCopy[activeId],
                        products: uniqBy([...catProduct, ...subCategoryToStore], "id"),
                    };
                    setAllData(allDataCopy);
                }
                console.log("activeCatId", activeCatId);
            });
        }
    }, [isFetchedCategoryProductSuccess, activeCatId]);

    useEffect(() => {
        if (Boolean(isFetchedCategoriesSuccess)) {
            const allCategories = isFetchedCategoriesSuccess?.map((cat, index) => ({
                id: index + 1,
                name: cat?.name,
                disabled: true,
                uuid: cat?.id,
                products: [],
            }));
            console.log("isFetchedCategoriesSuccess", isFetchedCategoriesSuccess);
            const categories =
                allCategories?.length > 0
                    ? allCategories
                    : [
                          {
                              id: 1,
                              name: "Category1",
                              disabled: true,
                              uuid: "",
                              products: [],
                          },
                      ];

            setActiveCatId(allCategories?.[0]?.uuid);
            setCategories(categories);
            setAllData(categories);
            setSelectedCatName(categories?.[0]?.name);
        }
    }, [isFetchedCategoriesSuccess]);

    useEffect(() => {
        if (Boolean(isCreatingCategorySuccess)) {
            const categoriesCopy = [...categories];
            const allDataCopy = [...allData];
            const lastCategory = categoriesCopy[categoriesCopy?.length - 1];
            const lastAllData = allDataCopy[allDataCopy?.length - 1];
            lastCategory.uuid = isCreatingCategorySuccess?.id;
            lastAllData.uuid = isCreatingCategorySuccess?.id;
            lastAllData.products = [
                {
                    id: 1,
                    name: "Product" + 1,
                    disabled: true,
                    uuid: "",
                    price: "",
                    isExtra: false,
                    description: "",
                },
            ];
            setCategories(categoriesCopy);
            setAllData(allDataCopy);
            setActiveSubCatId(null);
            setActiveCatId(isCreatingCategorySuccess?.id);
            setSelectedCatName(isCreatingCategorySuccess?.name);

            dispatch(vendorActions.resetCreateCategorySuccess());
        }
    }, [dispatch, isCreatingCategorySuccess, categories]);

    useEffect(() => {
        if (Boolean(isDeletingCategorySuccess)) {
            removeCategory(activeCatId);
            setDeleteModal(false);
            dispatch(vendorActions.resetDeleteCategorySuccess());
        }
    }, [isDeletingCategorySuccess, dispatch, removeCategory, activeCatId]);

    useEffect(() => {
        if (Boolean(deleteProductSuccess)) {
            removeSubCategory(activeSubCatIdx);
            setDeleteProductModal(false);
            dispatch(vendorActions.resetDeleteProductSuccess());
        }
    }, [dispatch, deleteProductSuccess, activeSubCatIdx]);

    useEffect(() => {
        if (isCreatingProductSuccess) {
            dispatch(vendorActions.resetSaveAllProducts());
        }
    }, [dispatch, isCreatingProductSuccess]);
    console.log("productDetails", productDetails);

    return (
        <>
            <PageFrame
                isLoading={isFetchingVendors || isFetchingCategories || isFetchingStoreDetails}
                frameLinks={[
                    { text: "Dashboard", url: `/dashboard`, isActive: false },
                    { text: "Store Details", url: "#", isActive: true },
                    { text: "Manage Orders", url: `/dashboard/store/${params?.storeId}/orders` },
                    {
                        text: "Store Earnings",
                        url: `/dashboard/store/${params?.storeId}/payment`,
                        isActive: false,
                    },
                ]}
            >
                <Link
                    to={`/dashboard/store/${params?.storeId}`}
                    className="mb-6 flex items-center gap-2 cursor-pointer w-fit"
                >
                    <span className="material-icons text-sm">keyboard_backspace</span>
                    <span className="text-sm">Go Back</span>
                </Link>
                <p className="text-xl font-bold mb-2">Manage Products ({storeDetails?.name})</p>

                <div className="p-4 grid grid-cols-3 max-lg:grid-cols-1 bg-white border rounded-lg min-h-[350px] max-h-[375px] w-full gap-2 overflow-x-auto">
                    {/* First Category Column */}
                    <div className="w-full bg-white">
                        <div className="flex items-center justify-between">
                            <p className="text-xl mb-2 font-semibold">Categories</p>

                            <p
                                onClick={addCategory}
                                className="text-base underline cursor-pointer hidden max-lg:block"
                            >
                                <span>+ Add</span>
                            </p>
                        </div>

                        <div className="max-h-[375px] max-lg:max-h-[300px] overflow-y-auto pr-4 max-lg:pr-0">
                            {allData.map((cat, idx) => {
                                return (
                                    <div key={idx} className="mb-4 text-ellipsis">
                                        <div
                                            // onClick={(e) => {
                                            //     toggleCategoriesAccordion(idx + 1);
                                            // }}
                                            className="flex justify-between items-center pr-2 cursor-pointer text-ellipsis"
                                        >
                                            <div
                                                className="text-base text-ellipsis relative z-10 font-bold flex gap-2 items-center w-[100%]"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setActiveCatId(cat.uuid);
                                                    setActiveSubCatId(null);
                                                    setSelectedCatName(cat.name);
                                                }}
                                            >
                                                {/* <span className="material-icons text-lg">
                                                            visibility
                                                        </span> */}

                                                {cat.disabled ? (
                                                    <p
                                                        className={`rounded-lg relative z-0 pl-4 h-10 flex items-center w-[95%] box-border bg-white  hover:bg-[#5386DB] ${
                                                            cat.uuid === activeCatId &&
                                                            cat.disabled &&
                                                            "!bg-[#5386DB]"
                                                        } ${
                                                            !cat.disabled && "hover:bg-white "
                                                        } cursor-pointer border placeholder:text-sm w-full `}
                                                    >
                                                        {cat.name}
                                                    </p>
                                                ) : (
                                                    <input
                                                        type={"text"}
                                                        name={cat.name}
                                                        value={cat.name}
                                                        disabled={cat.disabled}
                                                        autoFocus
                                                        onKeyDown={(e) => {
                                                            if (e.key === "Enter") {
                                                                handleSaveCategory(cat, idx);
                                                            }
                                                        }}
                                                        onChange={(event) => {
                                                            const cats = [...categories];
                                                            const allDataCopy = [...allData];
                                                            const cate = cats[idx];

                                                            cate.name = event.target.value;
                                                            allDataCopy[idx].name =
                                                                event.target.value;

                                                            setCategories([...cats]);
                                                            setAllData([...allDataCopy]);
                                                        }}
                                                        className={`rounded-lg relative z-0 pl-4 h-10 flex text-ellipsis items-center w-[95%] box-border bg-white hover:bg-[#5386DB] ${
                                                            cat.uuid === activeCatId &&
                                                            cat.disabled &&
                                                            "!bg-[#5386DB]"
                                                        } ${
                                                            !cat.disabled && "hover:bg-white "
                                                        } cursor-pointer border placeholder:text-sm w-full text-ellipsis`}
                                                    />
                                                )}
                                            </div>

                                            <p className="flex gap-1">
                                                {(isCreatingCategory || isUpdatingCategory) &&
                                                activeCatId === cat.uuid ? (
                                                    <Loader size={4} color={"btn-blue"} />
                                                ) : cat.disabled ? (
                                                    <span
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleEditCategory(cat, idx);
                                                        }}
                                                        className="material-icons text-lg"
                                                    >
                                                        mode_edit
                                                    </span>
                                                ) : (
                                                    <span
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleSaveCategory(cat, idx);
                                                        }}
                                                        className="material-icons text-lg"
                                                    >
                                                        done
                                                    </span>
                                                )}
                                                {allData.length > 1 && (
                                                    <span
                                                        className="material-icons text-lg text-red-500 cursor-pointer"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            setActiveCatId(cat.uuid);
                                                            setActiveCatIdx(cat.id);
                                                            setActiveSubCatId(null);
                                                            if (cat.uuid) {
                                                                setDeleteModal(true);
                                                            } else {
                                                                setDeleteModal(true);
                                                            }
                                                        }}
                                                    >
                                                        delete
                                                    </span>
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>

                    <hr className="hidden max-lg:block py-4" />

                    {/* SUB Category Column */}
                    <div className={`w-full ${!activeCatId && " bg-slate-300 "}`}>
                        {activeCatId && (
                            <div className="flex items-center justify-between">
                                <p className="text-xl mb-2 capitalize font-semibold">
                                    {truncate(selectedCatName, { length: 20 })}
                                </p>
                                <p
                                    onClick={addSubCategory}
                                    className="text-base underline cursor-pointer hidden max-lg:block"
                                >
                                    <span>+ Add </span>
                                </p>
                            </div>
                        )}
                        {isFetchingCategoryProducts ? (
                            <Loader color={"#5386DB"} size={5} />
                        ) : activeCatId ? (
                            <div className="max-h-[375px] max-lg:max-h-[300px] overflow-y-auto pr-4 max-lg:pr-0 max-lg:w-full w-[95%]">
                                {allData[activeCatIndex].products?.map((cat, idx) => {
                                    console.log("catttt", cat);
                                    return (
                                        <div key={idx} className="mb-4">
                                            <div
                                                onClick={(e) => {
                                                    setActiveSubCatId(idx);
                                                    setActiveSubCatUuid(cat?.uuid);
                                                    setActiveSubCatIdx(cat?.id);
                                                }}
                                                className="flex justify-between items-center pr-2 cursor-pointer"
                                            >
                                                <p
                                                    className={`text-base font-bold hover:bg-[#5386DB] flex  text-ellipsis items-center w-[85%] max-lg:w-[88%] rounded-lg pl-4 h-10 border ${
                                                        activeSubCatId === idx && "bg-[#5386DB]"
                                                    } `}
                                                >
                                                    {cat.name}
                                                </p>
                                                <p className="flex gap-1">
                                                    {allData[activeCatIndex].products?.length >
                                                        1 && (
                                                        <span
                                                            className="material-icons text-lg text-red-500 cursor-pointer"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                setActiveSubCatUuid(cat?.uuid);
                                                                setActiveSubCatIdx(cat?.id);
                                                                setDeleteProductModal(true);
                                                            }}
                                                        >
                                                            delete
                                                        </span>
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        ) : (
                            <div className="text-lg text-center font-bold my-6 max-lg:rotate-0 max-lg:text-center max-lg:my-6">
                                Select a valid category
                            </div>
                        )}
                    </div>

                    <hr className="hidden max-lg:block py-4" />

                    {/* DETAILS SECTION */}
                    <div
                        className={`w-full ${
                            (!(activeSubCatId >= 0) || activeSubCatId === null) && "bg-slate-300"
                        }`}
                    >
                        {activeSubCatId >= 0 && activeSubCatId !== null && (
                            <div className="flex items-center justify-between mb-4">
                                <p className="text-xl mb-2 font-semibold">Details</p>

                                <button
                                    onClick={() => {
                                        setDeleteProductModal(true);
                                    }}
                                    className="px-3 py-1 rounded-md bg-red-500 flex items-center text-white w-fit justify-center"
                                >
                                    Delete
                                </button>
                            </div>
                        )}

                        <div className=" pr-4 max-lg:pr-0">
                            {activeSubCatId >= 0 && activeSubCatId !== null ? (
                                <div>
                                    <div>
                                        <div className="mb-3 flex items-center">
                                            <label>Name</label>
                                            <input
                                                type={"text"}
                                                placeholder="Product name"
                                                name={"productName"}
                                                value={productDetails?.[activeSubCatId]?.name ?? ""}
                                                onChange={(event) => {
                                                    const cats = [...productDetails];
                                                    const allDataCopy = [...allData];
                                                    const activeId = allDataCopy.findIndex(
                                                        (data) => data.uuid === activeCatId
                                                    );
                                                    const cate = cats[activeSubCatId];
                                                    let allDataProd =
                                                        allDataCopy[activeId].products;

                                                    cate.name = event.target.value;
                                                    // allDataProd = { name: event.target.value };
                                                    allDataProd[activeSubCatId].name =
                                                        event.target.value;
                                                    console.log("allDataProd", allDataProd);

                                                    setSubCategories([...cats]);
                                                    setAllData(allDataCopy);
                                                }}
                                                className={`rounded-lg pl-4 h-10 ml-4 flex items-center w-full box-border border placeholder:text-sm`}
                                            />
                                        </div>
                                        <div className="mb-3 flex">
                                            <label>Price</label>
                                            <input
                                                type="number"
                                                id="price"
                                                name="price"
                                                value={productDetails?.[activeSubCatId]?.price ?? 0}
                                                onWheel={(e) => e.target.blur()}
                                                onKeyDown={(evt) =>
                                                    [
                                                        "e",
                                                        "E",
                                                        "+",
                                                        "-",
                                                        "ArrowUp",
                                                        "ArrowDown",
                                                    ].includes(evt.key) && evt.preventDefault()
                                                }
                                                onChange={(event) => {
                                                    const cats = [...productDetails];
                                                    const allDataCopy = [...allData];
                                                    const activeId = allDataCopy.findIndex(
                                                        (data) => data.uuid === activeCatId
                                                    );
                                                    const cate = cats[activeSubCatId];
                                                    const allDataProd =
                                                        allDataCopy[activeId].products[
                                                            activeSubCatId
                                                        ] || {};
                                                    cate.price = event.target.value;
                                                    allDataProd.price = event.target.value;

                                                    setSubCategories([...cats]);
                                                    setAllData(allDataCopy);
                                                }}
                                                className="border rounded-lg ml-4 pl-4 h-10 w-full"
                                            />
                                        </div>
                                        {/* <div className="mb-3">
                                            <label>Show as Extra</label>
                                            <input
                                                type="checkbox"
                                                id="extra"
                                                checked={productDetails?.[activeSubCatId]?.isExtra}
                                                onChange={(event) => {
                                                    const cats = [...productDetails];
                                                    const allDataCopy = [...allData];
                                                    const activeId = allDataCopy.findIndex(
                                                        (data) => data.uuid === activeCatId
                                                    );
                                                    const cate = cats[activeSubCatId];
                                                    const allDataProd =
                                                        allDataCopy[activeId].products[
                                                            activeSubCatId
                                                        ] || {};
                                                    cate.isExtra = !cate.isExtra;
                                                    allDataProd.isExtra = !allDataProd.isExtra;

                                                    setSubCategories([...cats]);
                                                    setAllData([...allDataCopy]);
                                                }}
                                                name="extra"
                                                className="ml-3"
                                            />
                                        </div> */}
                                        <div className="mb-3 flex flex-col w-full">
                                            <label>Description (max 300 characters)</label>
                                            <textarea
                                                id="description"
                                                name="description"
                                                style={{
                                                    resize: "none",
                                                }}
                                                maxlength="300"
                                                value={
                                                    productDetails?.[activeSubCatId]?.description ||
                                                    ""
                                                }
                                                rows={"3"}
                                                onChange={(event) => {
                                                    const cats = [...productDetails];
                                                    const allDataCopy = [...allData];
                                                    const activeId = allDataCopy.findIndex(
                                                        (data) => data.uuid === activeCatId
                                                    );
                                                    const cate = cats[activeSubCatId];
                                                    const allDataProd =
                                                        allDataCopy[activeId].products[
                                                            activeSubCatId
                                                        ] || {};
                                                    cate.description = event.target.value;
                                                    allDataProd.description = event.target.value;

                                                    setSubCategories([...cats]);
                                                    setAllData(allDataCopy);
                                                }}
                                                className="border rounded-lg p-3 w-full"
                                            />
                                        </div>
                                        <div>
                                            <p>
                                                <span>Product image</span>{" "}
                                                <span className="text-sm">(.jpg and .png)</span>
                                            </p>
                                            <div className="mt-2 grid grid-cols-3 gap-2 max-sm:flex flex-row flex-wrap">
                                                {productDetails?.[activeSubCatId]?.imagePreview ? (
                                                    <div className="w-[90px] h-[90px] border flex items-center justify-center rounded-sm relative cursor-pointer">
                                                        <img
                                                            src={
                                                                productDetails?.[activeSubCatId]
                                                                    ?.imagePreview
                                                            }
                                                            alt="Preview"
                                                            className="w-full h-full bg-cover bg-no-repeat"
                                                        />

                                                        <span
                                                            className="material-icons text-base text-red-500 cursor-pointer absolute top-[2px] right-[5px]"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                const cats = [...productDetails];
                                                                const allDataCopy = [...allData];
                                                                const activeId =
                                                                    allDataCopy.findIndex(
                                                                        (data) =>
                                                                            data.uuid ===
                                                                            activeCatId
                                                                    );
                                                                const cate = cats[activeSubCatId];
                                                                const allDataProd =
                                                                    allDataCopy[activeId].products[
                                                                        activeSubCatId
                                                                    ] || {};
                                                                cate.image = null;
                                                                allDataProd.image = null;
                                                                cate.imagePreview = null;
                                                                allDataProd.imagePreview = null;

                                                                setImagePreviewUrl(null);
                                                                setSubCategories([...cats]);
                                                                setAllData(allDataCopy);
                                                            }}
                                                        >
                                                            delete
                                                        </span>
                                                        <button className="border rounded py-1.5 text-[10px] px-3 absolute bg-white cursor-pointer">
                                                            Change
                                                        </button>

                                                        <input
                                                            type={"file"}
                                                            accept=".jpg, .jpeg, .png"
                                                            name="image1"
                                                            onChange={(event) => {
                                                                console.log(
                                                                    "--->>",
                                                                    event.target.files?.[0]
                                                                );

                                                                const cats = [...productDetails];
                                                                const allDataCopy = [...allData];
                                                                const activeId =
                                                                    allDataCopy.findIndex(
                                                                        (data) =>
                                                                            data.uuid ===
                                                                            activeCatId
                                                                    );
                                                                const cate = cats[activeSubCatId];
                                                                const allDataProd =
                                                                    allDataCopy[activeId].products[
                                                                        activeSubCatId
                                                                    ] || {};
                                                                cate.image =
                                                                    event.target.files?.[0];
                                                                allDataProd.image =
                                                                    event.target.files?.[0];

                                                                // handleImageChange(event);
                                                                const file = event.target.files[0];
                                                                if (file) {
                                                                    const reader = new FileReader();

                                                                    reader.onloadend = () => {
                                                                        cate.imagePreview =
                                                                            reader.result;
                                                                        allDataProd.imagePreview =
                                                                            reader.result;
                                                                        // setImagePreviewUrl(
                                                                        //     reader.result
                                                                        // );
                                                                        setSubCategories([...cats]);
                                                                        setAllData(allDataCopy);
                                                                    };

                                                                    reader.readAsDataURL(file);
                                                                }
                                                                setSubCategories([...cats]);
                                                                setAllData(allDataCopy);
                                                            }}
                                                            className="absolute w-28 opacity-0"
                                                        />
                                                    </div>
                                                ) : (
                                                    <div className="w-[90px] h-[90px] border flex items-center justify-center rounded-sm relative cursor-pointer">
                                                        <button className="border rounded py-1.5 text-xs px-3 cursor-pointer text-[10px]">
                                                            Add Image
                                                        </button>

                                                        <input
                                                            type={"file"}
                                                            accept=".jpg, .jpeg, .png"
                                                            name="image1"
                                                            onChange={(event) => {
                                                                console.log(
                                                                    "--->>",
                                                                    event.target.files?.[0]
                                                                );

                                                                const cats = [...productDetails];
                                                                const allDataCopy = [...allData];
                                                                const activeId =
                                                                    allDataCopy.findIndex(
                                                                        (data) =>
                                                                            data.uuid ===
                                                                            activeCatId
                                                                    );
                                                                const cate = cats[activeSubCatId];
                                                                const allDataProd =
                                                                    allDataCopy[activeId].products[
                                                                        activeSubCatId
                                                                    ] || {};
                                                                cate.image =
                                                                    event.target.files?.[0];
                                                                allDataProd.image =
                                                                    event.target.files?.[0];

                                                                // handleImageChange(event);
                                                                const file = event.target.files[0];
                                                                if (file) {
                                                                    const reader = new FileReader();

                                                                    reader.onloadend = () => {
                                                                        cate.imagePreview =
                                                                            reader.result;
                                                                        allDataProd.imagePreview =
                                                                            reader.result;
                                                                        // setImagePreviewUrl(
                                                                        //     reader.result
                                                                        // );
                                                                        setSubCategories([...cats]);
                                                                        setAllData(allDataCopy);
                                                                    };

                                                                    reader.readAsDataURL(file);
                                                                }
                                                                setSubCategories([...cats]);
                                                                setAllData(allDataCopy);
                                                            }}
                                                            className="absolute w-28 opacity-0"
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="text-lg text-center font-bold my-6 max-lg:rotate-0 max-lg:text-center max-lg:my-6">
                                    Select a valid product
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div className="flex justify-between items-center mt-4">
                    <p
                        onClick={addCategory}
                        className="text-base underline cursor-pointer max-lg:hidden"
                    >
                        <span>+ Add New Category</span>
                    </p>

                    <p
                        onClick={addSubCategory}
                        className="text-base underline cursor-pointer max-lg:hidden"
                    >
                        <span>+ Add New Sub-category</span>
                    </p>

                    <button
                        onClick={() => {
                            handleSaveChanges();
                        }}
                        disabled={isCreatingProduct || isSavingAllProducts}
                        className="py-4 uppercase rounded-md bg-btn-blue flex items-center text-white w-[300px] max-lg:w-full justify-center"
                    >
                        {isCreatingProduct || isSavingAllProducts ? (
                            <Loader color={"white"} size={5} />
                        ) : (
                            "Save Changes"
                        )}
                    </button>
                </div>
            </PageFrame>

            <ModalContainer
                open={deleteModal}
                showCloseIcon={false}
                closeModal={() => setDeleteModal(false)}
                tailwindClassName="w-[30%] max-lg:w-1/2 max-sm:w-[95%]"
            >
                <div className="bg-white rounded-lg border shadow-modalShadow">
                    <div className="relative">
                        <div className="p-6">
                            <p className="text-[20px] text-center mb-2 relative font-rocGroteskBold ">
                                Delete?
                            </p>
                            <p className="text-sm text-center px-4">
                                Are you sure you want to delete this item?
                            </p>
                        </div>
                    </div>
                    <div className="flex">
                        <button
                            type="button"
                            className={`uppercase rounded-bl-lg w-full text-mvx-neutral text-xs font-rocGroteskMedium flex items-center justify-center bg-white py-5 border-t  px-3`}
                            onClick={() => {
                                setDeleteModal(false);
                            }}
                        >
                            cancel
                        </button>
                        <button
                            type="button"
                            onClick={() => {
                                if (activeCatId) {
                                    dispatch(
                                        vendorActions.deleteCategory(
                                            isFetchedVendorsSuccess?.[0]?.id,
                                            activeCatId,
                                            userObj?.token
                                        )
                                    );
                                } else {
                                    removeCategory(activeCatIdx);
                                    setDeleteModal(false);
                                }
                            }}
                            className={`uppercase rounded-br-lg w-full text-gun-metal text-xs font-rocGroteskMedium flex items-center justify-center bg-[#EBECF0] py-5 border-t px-3`}
                        >
                            {isDeletingCategory ? (
                                <Loader color="gun-metal" />
                            ) : (
                                <p className="text-inherit">Delete</p>
                            )}
                        </button>
                    </div>
                </div>
            </ModalContainer>

            <ModalContainer
                open={deleteProductModal}
                showCloseIcon={false}
                closeModal={() => setDeleteProductModal(false)}
                tailwindClassName="w-[30%] max-lg:w-1/2 max-sm:w-[95%]"
            >
                <div className="bg-white rounded-lg border shadow-modalShadow">
                    <div className="relative">
                        <div className="p-6">
                            <p className="text-[20px] text-center mb-2 relative font-rocGroteskBold ">
                                Delete?
                            </p>
                            <p className="text-sm text-center px-4">
                                Are you sure you want to delete this product?
                            </p>
                        </div>
                    </div>
                    <div className="flex">
                        <button
                            type="button"
                            className={`uppercase rounded-bl-lg w-full text-mvx-neutral text-xs font-rocGroteskMedium flex items-center justify-center bg-white py-5 border-t  px-3`}
                            onClick={() => {
                                setDeleteProductModal(false);
                            }}
                        >
                            cancel
                        </button>
                        <button
                            type="button"
                            onClick={() => {
                                if (activeSubCatUuid) {
                                    dispatch(
                                        vendorActions.deleteProduct(
                                            isFetchedVendorsSuccess?.[0]?.id,
                                            activeSubCatUuid,
                                            userObj?.token
                                        )
                                    );
                                } else {
                                    removeSubCategory(activeSubCatIdx);
                                    setDeleteProductModal(false);
                                }
                            }}
                            className={`uppercase rounded-br-lg w-full text-gun-metal text-xs font-rocGroteskMedium flex items-center justify-center bg-[#EBECF0] py-5 border-t px-3`}
                        >
                            {isDeletingProduct ? (
                                <Loader color="gun-metal" />
                            ) : (
                                <p className="text-inherit">Delete</p>
                            )}
                        </button>
                    </div>
                </div>
            </ModalContainer>
        </>
    );
};

export default ManageProducts;
