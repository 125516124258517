import toast from "react-hot-toast";
import { authServices } from "../../services";
import { authTypes } from "../types/authTypes";

const request = (type) => {
    return { type: type };
};

const success = (type, data) => {
    return { type: type, payload: data };
};

const failure = (type, error) => {
    return { type: type, payload: error ?? "" };
};

const loginUser = (data, shouldRemember) => {
    return (dispatch) => {
        dispatch(request(authTypes.USER_LOGIN_REQUEST));
        authServices.login(data).then(
            (res) => {
                if (res?.success) {
                    const data = {
                        ...res.data.user,
                        token: res.data.token,
                    };
                    if (shouldRemember) {
                        localStorage.setItem("noqUser", JSON.stringify(data));
                    } else {
                        sessionStorage.setItem("noqUser", JSON.stringify(data));
                    }
                    toast.success("Login successful!");
                    window.dispatchEvent(new Event("storage"));
                    dispatch(success(authTypes.USER_LOGIN_SUCCESS, data));
                } else {
                    console.log("res", res);
                    toast.error("Error while trying to sign in");
                    dispatch(
                        failure(authTypes.USER_LOGIN_FAILURE, "Error while trying to sign in")
                    );
                }
            },
            (error) => {
                if (error.message) {
                    toast.error(error.message);
                    dispatch(failure(authTypes.USER_LOGIN_FAILURE, error.message));
                }
            }
        );
    };
};

const signup = (data) => {
    return (dispatch) => {
        dispatch(request(authTypes.USER_SIGNUP_REQUEST));
        authServices.signup(data).then(
            (res) => {
                if (res?.token) {
                    const data = {
                        ...res.user,
                        token: res.token,
                    };

                    sessionStorage.setItem("noqUser", JSON.stringify(data));
                    toast.success(
                        "Signup successful! Please check your email to verify your account"
                    );
                    dispatch(success(authTypes.USER_SIGNUP_SUCCESS, data));
                } else {
                    console.log("res", res);
                    toast.error("Error while trying to sign up");
                    dispatch(
                        failure(authTypes.USER_SIGNUP_FAILURE, "Error while trying to sign up")
                    );
                }
            },
            (error) => {
                if (error?.message) {
                    toast.error(error.message);
                    dispatch(failure(authTypes.USER_SIGNUP_FAILURE, error.message));
                }
            }
        );
    };
};

const resquestPasswordReset = (data) => {
    return (dispatch) => {
        dispatch(request(authTypes.REQUEST_PASSWORD_RESET_REQUEST));
        authServices.resetPasswordRequest(data).then(
            (res) => {
                if (res?.success || res?.data) {
                    console.log("res", res);

                    // localStorage.setItem("resetPasswordEmail", JSON.stringify(data));
                    toast.success("Success! Please check your email for your reset link");
                    dispatch(success(authTypes.REQUEST_PASSWORD_RESET_SUCCESS, data));
                } else {
                    console.log("res", res);
                    toast.error("Error occured, please try again later");
                    dispatch(
                        failure(
                            authTypes.REQUEST_PASSWORD_RESET_FAILURE,
                            "Error occured, please try again later"
                        )
                    );
                }
            },
            (error) => {
                if (error?.message) {
                    toast.error(error.message);
                    dispatch(failure(authTypes.REQUEST_PASSWORD_RESET_FAILURE, error.message));
                }
            }
        );
    };
};

const passwordReset = (data) => {
    return (dispatch) => {
        dispatch(request(authTypes.PASSWORD_RESET_REQUEST));
        authServices.resetPassword(data).then(
            (res) => {
                if (res?.success || res?.data) {
                    console.log("res", res);

                    // localStorage.setItem("resetPasswordEmail", JSON.stringify(data));
                    toast.success(`Success! ${res?.message}`);
                    dispatch(success(authTypes.PASSWORD_RESET_SUCCESS, res));
                } else {
                    console.log("res", res);
                    toast.error("Error occured, please try again later");
                    dispatch(
                        failure(
                            authTypes.PASSWORD_RESET_FAILURE,
                            "Error occured, please try again later"
                        )
                    );
                }
            },
            (error) => {
                if (error?.message) {
                    toast.error(error.message);
                    dispatch(failure(authTypes.PASSWORD_RESET_FAILURE, error.message));
                }
            }
        );
    };
};

const resetSignUpSuccess = () => {
    return (dispatch) => {
        dispatch(request(authTypes.USER_SIGNUP_SUCCESS, null));
    };
};

export const authActions = {
    loginUser,
    signup,
    resetSignUpSuccess,
    resquestPasswordReset,
    passwordReset,
};
