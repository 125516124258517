import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import stepIcon from "../assets/icons/login_step_icon.svg";
import "./Login/styles.css";
import { authActions } from "../redux/actions";
import { useNavigate, useParams } from "react-router-dom";

const ResetPassword = () => {
    const [resetForm, setResetForm] = useState({
        email: "",
        password: "",
        password_confirmation: "",
    });
    const [showPassword, setShowPassword] = useState({
        password: false,
        confirmPassword: false,
    });
    const [error, setError] = useState(false);

    const { token } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isPasswordResetting, isPasswordResetSuccess } = useSelector((state) => state.auth);

    const handleResetPassword = () => {
        if (resetForm?.password === resetForm?.password_confirmation) {
            setError(false);
            dispatch(authActions.passwordReset({ token, ...resetForm }));
        } else {
            setError(true);
        }
    };

    useEffect(() => {
        if (Boolean(isPasswordResetSuccess)) {
            navigate(`/login`);
        }
    }, [isPasswordResetSuccess]);

    return (
        <div className="login-container">
            <div className="card">
                <div className="left">
                    <div className="left-content">
                        <div>
                            <p className="left-title">no-Q</p>
                        </div>
                        <div className="login-steps">
                            <div>
                                <div className="step-icon">
                                    <img src={stepIcon} alt="step icon" />
                                </div>
                                <p className="login-step">Step 1</p>
                                <p className="step-desc">
                                    Create an Account with your Business and personal details.
                                </p>
                            </div>
                            <div>
                                <div className="step-icon">
                                    <img src={stepIcon} alt="step icon" />
                                </div>
                                <p className="step">Step 2</p>
                                <p className="step-desc">
                                    Log into your account and set up one or multiple store fronts
                                    per event.
                                </p>
                            </div>
                            <div>
                                <div className="step-icon">
                                    <img src={stepIcon} alt="step icon" />
                                </div>
                                <p className="step">Step 3</p>
                                <p className="step-desc">
                                    Share your storefront URL or print a QR Code for event goers to
                                    shop online. No queues, No missing out on the event.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="right">
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleResetPassword();
                        }}
                        className="w-1/2"
                    >
                        <div className="header">
                            <p className="font-bold text-xl ">Reset Password</p>
                            <p className="text-sm ">
                                Please fill in the details below to reset password
                            </p>
                        </div>
                        <div>
                            <div className="mb-4">
                                <p className="text-base mb-1">Email</p>
                                <input
                                    type={"text"}
                                    placeholder="Email"
                                    className="input-field"
                                    name="email"
                                    value={resetForm.email}
                                    required
                                    onChange={(e) => {
                                        setResetForm((prev) => ({
                                            ...prev,
                                            email: e.target.value,
                                        }));
                                    }}
                                />
                            </div>

                            <div className="mb-4 ">
                                <p className="text-base mb-1">Password</p>
                                <div className="flex relative items-center">
                                    <input
                                        type={showPassword.password ? "text" : "password"}
                                        placeholder="Password"
                                        className="input-field"
                                        name="password"
                                        value={resetForm.password}
                                        required
                                        onChange={(e) => {
                                            setResetForm((prev) => ({
                                                ...prev,
                                                password: e.target.value,
                                            }));
                                        }}
                                    />
                                    <div className="h-full absolute right-0 flex items-center pr-4">
                                        {!showPassword.password ? (
                                            <i
                                                onClick={() =>
                                                    setShowPassword((prev) => ({
                                                        ...prev,
                                                        password: true,
                                                    }))
                                                }
                                                className="ri-eye-close-line absolute top-[14px] right-[20px] text-gray-500 cursor-pointer"
                                            ></i>
                                        ) : (
                                            <i
                                                onClick={() =>
                                                    setShowPassword((prev) => ({
                                                        ...prev,
                                                        password: false,
                                                    }))
                                                }
                                                className="ri-eye-line absolute top-[14px] right-[20px] text-gray-500 cursor-pointer"
                                            ></i>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="mb-6 ">
                                <p className="text-base mb-1">Confirm password</p>
                                <div className="flex relative items-center">
                                    <input
                                        type={showPassword.confirmPassword ? "text" : "password"}
                                        placeholder="Confirm password"
                                        className="input-field"
                                        name="password_confirmation"
                                        value={resetForm.password_confirmation}
                                        required
                                        onChange={(e) => {
                                            setResetForm((prev) => ({
                                                ...prev,
                                                password_confirmation: e.target.value,
                                            }));
                                        }}
                                    />
                                    <div className="h-full absolute right-0 flex items-center pr-4">
                                        {!showPassword.confirmPassword ? (
                                            <i
                                                onClick={() =>
                                                    setShowPassword((prev) => ({
                                                        ...prev,
                                                        confirmPassword: true,
                                                    }))
                                                }
                                                className="ri-eye-close-line absolute top-[14px] right-[20px] text-gray-500 cursor-pointer"
                                            ></i>
                                        ) : (
                                            <i
                                                onClick={() =>
                                                    setShowPassword((prev) => ({
                                                        ...prev,
                                                        confirmPassword: false,
                                                    }))
                                                }
                                                className="ri-eye-line absolute top-[14px] right-[20px] text-gray-500 cursor-pointer"
                                            ></i>
                                        )}
                                    </div>
                                </div>
                                {error && (
                                    <span className="block text-xs font-semibold text-red-500">
                                        *Passwords don't match
                                    </span>
                                )}
                            </div>
                            <button
                                type="submit"
                                // disabled={isLoggingIn}
                                className="bg-btn-blue  w-full text-base text-white py-3 rounded"
                                id="loginButton"
                            >
                                {isPasswordResetting ? "Resetting..." : "Reset Password"}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;
