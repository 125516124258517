// src/ContactUsModal.js

import React from 'react';

const ContactUsModal = ({ show, handleClose, handleSubmit }) => {
  const showHideClassName = show ? "block" : "hidden";

  return (
    <div className={`fixed inset-0 flex items-center justify-center ${showHideClassName}`}>
      <div className="bg-white p-8 rounded shadow-lg w-1/2">
        <h2 className="text-2xl mb-4">Contact Us</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700">Full Name</label>
            <input
              type="text"
              name="name"
              className="w-full px-3 py-2 border rounded"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Email</label>
            <input
              type="email"
              name="email"
              className="w-full px-3 py-2 border rounded"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Message</label>
            <textarea
              name="message"
              className="w-full px-3 py-2 border rounded"
              required
            />
          </div>
          <div className="flex justify-end">
            <button type="button" onClick={handleClose} className="mr-4 px-4 py-2 bg-gray-500 text-white rounded">
              Close
            </button>
            <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded">
              Send
            </button>
          </div>
        </form>
      </div>
      
    </div>
  );
};

export default ContactUsModal;
