import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CustomTable from "../../common/CustomTable";
import ModalContainer from "../../common/ModalContainer";
import food_placeholder from "../../assets/images/food_placeholder.png";
import { vendorActions } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { truncate } from "lodash";
import PageFrame from "../../common/PageFrame";
import ResponsivePagination from "react-responsive-pagination";
import "react-responsive-pagination/themes/classic.css";

const ManageOrders = () => {
    const [activeTab, setActiveTab] = useState(0);
    const [detailModal, setDetailModal] = useState(false);
    const [ordersData, setOrdersData] = useState([]);
    const [pendingOrdersData, setPendingOrdersData] = useState([]);
    const [completedOrdersData, setCompletedOrdersData] = useState([]);
    const [cancelledOrdersData, setCancelledOrdersData] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState({});
    const [storeDetails, setStoreDetails] = useState({});
    const [pageData, setPageData] = useState({
        currentPage: 0,
        lastPage: 1,
    });
    const [pendingPageData, setPendingPageData] = useState({
        currentPage: 0,
        lastPage: 1,
    });
    const [completedPageData, setCompletedPageData] = useState({
        currentPage: 0,
        lastPage: 1,
    });
    const [cancelledPageData, setCancelledPageData] = useState({
        currentPage: 0,
        lastPage: 1,
    });
    const params = useParams();
    const dispatch = useDispatch();
    const {
        isFetchingStoreOrders,
        isFetchedStoreOrdersSuccess,
        isFetchingPendingStoreOrders,
        isFetchedPendingStoreOrdersSuccess,
        isFetchingCompletedStoreOrders,
        isFetchedCompletedStoreOrdersSuccess,
        isFetchingCancelledStoreOrders,
        isFetchedCancelledStoreOrdersSuccess,
        isProcessingOrder,
        processedOrderSuccess,
        isMarkingOrderAsProcessed,
        markOrderAsProcessedSuccess,
        isMarkingOrderAsDelivered,
        markOrderAsDeliveredSuccess,
        isCancellingOrder,
        isFetchingStoreDetails,
        isFetchedStoreDetailsSuccess,
        cancelOrderSuccess,
    } = useSelector((state) => state.vendor);

    const noqUser = localStorage.getItem("noqUser") || sessionStorage.getItem("noqUser");
    const [user] = useState(noqUser);
    const userObj = JSON.parse(user);

    const tabs = ["Pending Orders", "Completed Orders", "Cancelled Orders", "All Orders"];
    const tableHeader = [
        { title: "S/N", minWidth: "2rem" }, // Use minWidth for responsiveness
        { title: "Order ID", minWidth: "10rem" },
        { title: "Order", flex: 1 }, // Use flex for remaining space
        { title: "Quantity", minWidth: "10rem" }, // Use flex for remaining space
        { title: "Status", minWidth: "10rem" },
        { title: "Amount", minWidth: "12rem" },
    ];

    const tableBody = (
        activeTab === 0
            ? pendingOrdersData
            : activeTab === 1
            ? completedOrdersData
            : activeTab === 2
            ? cancelledOrdersData
            : ordersData
    )?.map((data, idx) => {
        return [
            {
                content: (
                    <div className={`w-full py-4 border-slate-100 text-sm text-slate-900 `}>
                        {idx + 1}
                    </div>
                ),
                cellClickAction: () => {
                    setSelectedOrder(data);
                    setDetailModal(true);
                },
                // tableDataDivClass: "!pl-0",
            },
            {
                content: (
                    <div className={`w-full h-full py-4 border-slate-100 text-sm text-slate-900 `}>
                        {data?.order_number}
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`w-full py-4 flex items-center h-full border-slate-100 text-sm text-slate-900`}
                    >
                        {truncate(
                            data?.order_items
                                ?.map((item, idx) => {
                                    return item?.product_name;
                                })
                                ?.join(", "),
                            { length: 45 }
                        )}
                    </div>
                ),
            },

            {
                content: (
                    <div
                        className={`w-full py-4 flex flex-col  justify-center h-full border-slate-100 text-sm text-slate-900`}
                    >
                        {data?.order_items?.map((item, idx) => {
                            return (
                                <div key={idx}>
                                    {truncate(item?.product_name, { length: 20 })} -{" "}
                                    {item?.quantity}
                                </div>
                            );
                        })}
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`w-full py-4 flex items-center h-full border-slate-100 text-sm text-slate-900`}
                    >
                        {data?.status?.split("_").join(" ")}
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`w-full py-4 flex items-center h-full border-slate-100 text-sm text-slate-900`}
                    >
                        {data?.currency}$ {data?.total}
                    </div>
                ),
            },
        ];
    });

    const modalTableHeader = [
        { title: "S/N", minWidth: "1rem" }, // Use minWidth for responsiveness
        { title: "Order", flex: 1 }, // Use flex for remaining space
        { title: "Quantity", minWidth: "5rem" }, // Use flex for remaining space
        { title: "Status", minWidth: "5rem" },
        { title: "Amount", minWidth: "6rem" },
    ];

    const modalTableBody = selectedOrder?.order_items?.map((data, idx) => {
        return [
            {
                content: (
                    <div className={`w-full py-4 border-slate-100 text-sm text-slate-900 `}>
                        {idx + 1}
                    </div>
                ),

                // tableDataDivClass: "!pl-0",
            },
            {
                content: (
                    <div
                        className={`w-full py-4 flex items-center h-full border-slate-100 text-sm text-slate-900`}
                    >
                        {data?.product_name}
                    </div>
                ),
            },

            {
                content: (
                    <div
                        className={`w-full py-4 flex flex-col  justify-center h-full border-slate-100 text-sm text-slate-900`}
                    >
                        {data?.quantity}
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`w-full py-4 flex items-center h-full border-slate-100 text-sm text-slate-900`}
                    >
                        {selectedOrder?.status?.split("_").join(" ")}
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`w-full py-4 flex items-center h-full border-slate-100 text-sm text-slate-900`}
                    >
                        {selectedOrder?.currency} {data?.amount}
                    </div>
                ),
            },
        ];
    });

    const handlePageClick = (event) => {
        const newOffset = (event.selected * 10) % 10;
        console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
        // setItemOffset(newOffset);
        console.log("event", event);
    };
    console.log("ordersData", ordersData, activeTab);
    const displayTabContent = () => {
        switch (activeTab) {
            case 0:
                return (
                    <div>
                        {pendingOrdersData && pendingOrdersData?.length > 0 ? (
                            <>
                                <p className="text-sm italic">
                                    Click on an order to open and mark as Completed.
                                </p>
                                <div className="max-h-[43vh] overflow-y-auto">
                                    <CustomTable
                                        tableBody={tableBody}
                                        tableHeader={tableHeader}
                                        isAllselectable={false}
                                        headerContainerClass="!bg-[#CCD6E4]"
                                        tableClass=" w-full"
                                        contentContainer="!pl-0"
                                        isScrollable={false}
                                        isCellBordered={false}
                                    />
                                </div>
                                <div className="flex justify-end mt-6">
                                    <ResponsivePagination
                                        current={pendingPageData?.currentPage}
                                        total={pendingPageData.lastPage}
                                        onPageChange={(page) => {
                                            setPendingPageData((prev) => {
                                                return {
                                                    ...prev,
                                                    currentPage: page,
                                                };
                                            });
                                        }}
                                        maxWidth={200}
                                    />
                                </div>
                            </>
                        ) : (
                            <div>
                                <p className="font-semibold text-center py-10">
                                    You currently have no pending orders available
                                </p>
                            </div>
                        )}
                    </div>
                );

            case 1:
                return (
                    <div>
                        {completedOrdersData && completedOrdersData?.length > 0 ? (
                            <>
                                <p className="text-sm italic">
                                    Click on an order to open and mark as delivered.
                                </p>
                                <div className="max-h-[43vh] overflow-y-auto">
                                    <CustomTable
                                        tableBody={tableBody}
                                        tableHeader={tableHeader}
                                        isAllselectable={false}
                                        headerContainerClass="!bg-[#CCD6E4]"
                                        tableClass=" w-full"
                                        contentContainer="!pl-0"
                                        isScrollable={false}
                                        isCellBordered={false}
                                    />
                                </div>
                                <div className="flex justify-end mt-6">
                                    <ResponsivePagination
                                        current={completedPageData?.currentPage}
                                        total={completedPageData.lastPage}
                                        onPageChange={(page) => {
                                            setCompletedPageData((prev) => {
                                                return {
                                                    ...prev,
                                                    currentPage: page,
                                                };
                                            });
                                        }}
                                        maxWidth={200}
                                    />
                                </div>
                            </>
                        ) : (
                            <div>
                                <p className="font-semibold text-center py-10">
                                    You currently have no completed orders available
                                </p>
                            </div>
                        )}
                    </div>
                );

            case 2:
                return (
                    <div>
                        {cancelledOrdersData && cancelledOrdersData?.length > 0 ? (
                            <>
                                <div className="max-h-[43vh] overflow-y-auto">
                                    <CustomTable
                                        tableBody={tableBody}
                                        tableHeader={tableHeader}
                                        isAllselectable={false}
                                        headerContainerClass="!bg-[#CCD6E4]"
                                        tableClass=" w-full"
                                        contentContainer="!pl-0"
                                        isScrollable={false}
                                        isCellBordered={false}
                                    />
                                </div>
                                <div className="flex justify-end mt-6">
                                    <ResponsivePagination
                                        current={cancelledPageData?.currentPage}
                                        total={cancelledPageData.lastPage}
                                        onPageChange={(page) => {
                                            setCancelledPageData((prev) => {
                                                return {
                                                    ...prev,
                                                    currentPage: page,
                                                };
                                            });
                                        }}
                                        maxWidth={200}
                                    />
                                </div>
                            </>
                        ) : (
                            <div>
                                <p className="font-semibold text-center py-10">
                                    You currently have no cancelled orders available
                                </p>
                            </div>
                        )}
                    </div>
                );

            case 3:
                return (
                    <div>
                        {ordersData && ordersData?.length > 0 ? (
                            <>
                                <p className="text-sm italic">
                                    Click on an order to open and mark as Started or Completed.
                                </p>
                                <div className="max-h-[43vh] overflow-y-auto">
                                    <CustomTable
                                        tableBody={tableBody}
                                        tableHeader={tableHeader}
                                        isAllselectable={false}
                                        headerContainerClass="!bg-[#CCD6E4]"
                                        tableClass=" w-full"
                                        contentContainer="!pl-0"
                                        isScrollable={false}
                                        isCellBordered={false}
                                    />
                                </div>
                                <div className="flex justify-end mt-6">
                                    <ResponsivePagination
                                        current={pageData?.currentPage}
                                        total={pageData.lastPage}
                                        onPageChange={(page) => {
                                            setPageData((prev) => {
                                                return {
                                                    ...prev,
                                                    currentPage: page,
                                                };
                                            });
                                        }}
                                        maxWidth={200}
                                    />
                                </div>
                            </>
                        ) : (
                            <div>
                                <p className="font-semibold text-center py-10">
                                    You currently have no orders available
                                </p>
                            </div>
                        )}
                    </div>
                );

            default:
                break;
        }
    };

    useEffect(() => {
        if (userObj?.token) {
            dispatch(
                vendorActions.getStoreOrders(
                    params?.storeId,
                    userObj.token,
                    Boolean(pageData?.currentPage) ? pageData?.currentPage : 1
                )
            );
        }
    }, [dispatch, userObj?.token, pageData?.currentPage, params?.storeId]);

    useEffect(() => {
        if (userObj?.token) {
            dispatch(
                vendorActions.getPendingStoreOrders(
                    params?.storeId,
                    userObj.token,
                    Boolean(pendingPageData?.currentPage) ? pendingPageData?.currentPage : 1
                )
            );
        }
    }, [dispatch, userObj?.token, pendingPageData?.currentPage, params?.storeId]);

    useEffect(() => {
        if (userObj?.token) {
            dispatch(
                vendorActions.getCompletedStoreOrders(
                    params?.storeId,
                    userObj.token,
                    Boolean(completedPageData?.currentPage) ? completedPageData?.currentPage : 1
                )
            );
        }
    }, [dispatch, userObj?.token, completedPageData?.currentPage, params?.storeId]);

    useEffect(() => {
        if (userObj?.token) {
            dispatch(
                vendorActions.getCancelledStoreOrders(
                    params?.storeId,
                    userObj.token,
                    Boolean(cancelledPageData?.currentPage) ? cancelledPageData?.currentPage : 1
                )
            );
        }
    }, [dispatch, userObj?.token, cancelledPageData?.currentPage, params?.storeId]);

    useEffect(() => {
        if (Boolean(isFetchedStoreOrdersSuccess)) {
            console.log("isFetchedStoreOrdersSuccess", isFetchedStoreOrdersSuccess);
            setOrdersData(isFetchedStoreOrdersSuccess?.data);
            setPageData({
                currentPage: isFetchedStoreOrdersSuccess?.meta?.current_page,
                lastPage: isFetchedStoreOrdersSuccess?.meta?.last_page,
            });
        }
    }, [isFetchedStoreOrdersSuccess]);

    useEffect(() => {
        if (Boolean(isFetchedPendingStoreOrdersSuccess)) {
            console.log("isFetchedPendingStoreOrdersSuccess", isFetchedPendingStoreOrdersSuccess);
            setPendingOrdersData(isFetchedPendingStoreOrdersSuccess?.data);
            setPendingPageData({
                currentPage: isFetchedPendingStoreOrdersSuccess?.meta?.current_page,
                lastPage: isFetchedPendingStoreOrdersSuccess?.meta?.last_page,
            });
        }
    }, [isFetchedPendingStoreOrdersSuccess, processedOrderSuccess, markOrderAsProcessedSuccess]);

    useEffect(() => {
        if (userObj?.token && params?.storeId) {
            dispatch(vendorActions.getStoreDetails(params.storeId, userObj?.token));
        }
    }, [dispatch, params?.storeId, userObj?.token]);

    useEffect(() => {
        if (isFetchedStoreDetailsSuccess) {
            setStoreDetails(isFetchedStoreDetailsSuccess);
        }
    }, [isFetchedStoreDetailsSuccess]);

    useEffect(() => {
        if (Boolean(isFetchedCompletedStoreOrdersSuccess)) {
            console.log(
                "isFetchedCompletedStoreOrdersSuccess",
                isFetchedCompletedStoreOrdersSuccess
            );
            setCompletedOrdersData(isFetchedCompletedStoreOrdersSuccess?.data);
            setCompletedPageData({
                currentPage: isFetchedCompletedStoreOrdersSuccess?.meta?.current_page,
                lastPage: isFetchedCompletedStoreOrdersSuccess?.meta?.last_page,
            });
        }
    }, [
        isFetchedCompletedStoreOrdersSuccess,
        markOrderAsProcessedSuccess,
        markOrderAsDeliveredSuccess,
    ]);

    useEffect(() => {
        if (Boolean(isFetchedCancelledStoreOrdersSuccess)) {
            console.log(
                "isFetchedCancelledStoreOrdersSuccess",
                isFetchedCancelledStoreOrdersSuccess
            );
            setCancelledOrdersData(isFetchedCancelledStoreOrdersSuccess?.data);
            setCancelledPageData({
                currentPage: isFetchedCancelledStoreOrdersSuccess?.meta?.current_page,
                lastPage: isFetchedCancelledStoreOrdersSuccess?.meta?.last_page,
            });
        }
    }, [isFetchedCancelledStoreOrdersSuccess, cancelOrderSuccess]);

    useEffect(() => {
        if (Boolean(processedOrderSuccess)) {
            console.log("processedOrderSuccess", processedOrderSuccess);
            const orderDataCopy = [...ordersData];
            const pendingOrderDataCopy = [...pendingOrdersData];
            const orderIndex = orderDataCopy.findIndex(
                (order) => order.id === processedOrderSuccess.id
            );
            const pendingOrderIndex = pendingOrderDataCopy.findIndex(
                (order) => order.id === processedOrderSuccess.id
            );

            if (orderIndex !== -1) {
                orderDataCopy[orderIndex] = processedOrderSuccess;
                setOrdersData(orderDataCopy);
            }

            if (pendingOrderIndex !== -1) {
                pendingOrderDataCopy[pendingOrderIndex] = processedOrderSuccess;
                setPendingOrdersData(pendingOrderDataCopy);
            }

            setDetailModal(false);
            dispatch(vendorActions.resetProcessOrderSuccess());
        }
    }, [dispatch, processedOrderSuccess]);

    useEffect(() => {
        if (Boolean(markOrderAsProcessedSuccess)) {
            console.log("markOrderAsProcessedSuccess", markOrderAsProcessedSuccess);
            const orderDataCopy = [...ordersData];
            const completedOrderDataCopy = [...completedOrdersData];
            const orderIndex = orderDataCopy.findIndex(
                (order) => order.id === markOrderAsProcessedSuccess.id
            );
            const completedOrderIndex = completedOrderDataCopy.findIndex(
                (order) => order.id === markOrderAsProcessedSuccess.id
            );

            if (orderIndex !== -1) {
                orderDataCopy[orderIndex] = markOrderAsProcessedSuccess;
                setOrdersData(orderDataCopy);
            }

            if (completedOrderIndex !== -1) {
                completedOrderDataCopy[completedOrderIndex] = markOrderAsProcessedSuccess;
                setCompletedOrdersData(completedOrderDataCopy);
            }

            setDetailModal(false);
            dispatch(vendorActions.resetMarkOrderAsProcessedSuccess());
        }
    }, [dispatch, markOrderAsProcessedSuccess]);

    useEffect(() => {
        if (Boolean(markOrderAsDeliveredSuccess)) {
            console.log("markOrderAsDeliveredSuccess", markOrderAsDeliveredSuccess);
            const orderDataCopy = [...ordersData];
            const completedOrderDataCopy = [...completedOrdersData];
            const orderIndex = orderDataCopy.findIndex(
                (order) => order.id === markOrderAsDeliveredSuccess.id
            );
            const completedOrderIndex = completedOrderDataCopy.findIndex(
                (order) => order.id === markOrderAsDeliveredSuccess.id
            );

            if (orderIndex !== -1) {
                orderDataCopy[orderIndex] = markOrderAsDeliveredSuccess;
                setOrdersData(orderDataCopy);
            }

            if (completedOrderIndex !== -1) {
                completedOrderDataCopy[completedOrderIndex] = markOrderAsDeliveredSuccess;
                setCompletedOrdersData(completedOrderDataCopy);
            }

            setDetailModal(false);
            dispatch(vendorActions.resetMarkOrderAsDeliveredSuccess());
        }
    }, [dispatch, markOrderAsDeliveredSuccess]);

    useEffect(() => {
        if (Boolean(cancelOrderSuccess)) {
            console.log("cancelOrderSuccess", cancelOrderSuccess);
            const orderDataCopy = [...ordersData];
            const cancelledOrderDataCopy = [...cancelledOrdersData];
            const orderIndex = orderDataCopy.findIndex(
                (order) => order.id === cancelOrderSuccess.id
            );
            const cancelledOrderIndex = cancelledOrderDataCopy.findIndex(
                (order) => order.id === cancelOrderSuccess.id
            );

            if (orderIndex !== -1) {
                orderDataCopy[orderIndex] = cancelOrderSuccess;
                setOrdersData(orderDataCopy);
            }

            if (cancelledOrderIndex !== -1) {
                cancelledOrderDataCopy[cancelledOrderIndex] = cancelOrderSuccess;
                setCancelledOrdersData(cancelledOrderDataCopy);
            }

            setDetailModal(false);
            dispatch(vendorActions.resetCancelOrderSuccess());
        }
    }, [dispatch, cancelOrderSuccess]);
    console.log("selectedOrder", selectedOrder);
    return (
        <>
            <PageFrame
                isLoading={
                    isFetchingStoreOrders &&
                    pageData?.currentPage < 1 &&
                    isFetchingPendingStoreOrders &&
                    pendingPageData?.currentPage < 1 &&
                    isFetchingCompletedStoreOrders &&
                    completedPageData?.currentPage < 1 &&
                    isFetchingCancelledStoreOrders &&
                    cancelledPageData?.currentPage < 1
                }
                frameLinks={[
                    { text: "Dashboard", url: `/dashboard`, isActive: false },
                    {
                        text: "Store Details",
                        url: `/dashboard/store/${params?.storeId}`,
                        isActive: false,
                    },
                    {
                        text: "Manage Orders",
                        url: `/dashboard/store/${params?.storeId}/orders`,
                        isActive: true,
                    },

                    {
                        text: "Store Earnings",
                        url: `/dashboard/store/${params?.storeId}/payment`,
                        isActive: false,
                    },
                ]}
                childrenContainerClass={`p-[30px] bg-white shadow-lg border rounded-lg min-h-[440px] w-full overflow-hidden max-sm:h-screen `}
            >
                <div>
                    <p className="text-xl font-bold mb-2 max-sm:text-center">
                        Manage Orders ({storeDetails?.name}){" "}
                    </p>

                    <p className="mb-5 font-semibold max-sm:text-center">
                        -- Refresh page to see new orders --{" "}
                    </p>
                    <div className="mb-4 flex items-center justify-between max-sm:flex-col max-sm:gap-3 ">
                        <div className="flex items-center gap-6">
                            {tabs?.map((tab, idx) => (
                                <div
                                    key={tab + idx}
                                    className={`cursor-pointer ${
                                        activeTab === idx && "border-b border-[#000] font-semibold"
                                    }`}
                                    onClick={() => setActiveTab(idx)}
                                >
                                    <p className={`text-sm mb-[2px]`}>{tab}</p>
                                </div>
                            ))}
                        </div>
                        <div className="relative flex items-center ">
                            <span className="material-icons text-base absolute left-[10px]">
                                search
                            </span>
                            <input
                                type={"text"}
                                placeholder="Search"
                                className="border pl-[30px] t- pr-2 py-1 w-[300px] rounded text-sm"
                            />
                        </div>
                    </div>

                    {displayTabContent()}
                </div>
            </PageFrame>

            <ModalContainer
                open={detailModal}
                showCloseIcon={false}
                tailwindClassName="w-[48.6%] max-lg:w-1/2 max-sm:w-[95%]"
                closeModal={() => setDetailModal(false)}
            >
                <div className="w-full p-[30px] bg-white rounded-md">
                    <div>
                        <div>
                            <p className="text-xl font-bold mb-[18px]">
                                Order #{selectedOrder?.order_number}
                            </p>
                            <div className="flex gap-8">
                                <div className="text-base w-full flex flex-col justify-between space-y-3">
                                    <div className="text-base">
                                        {/* <ol className="text-base list-decimal pl-4 mb-1 ">
                                            {selectedOrder?.order_items?.map((item, idx) => (
                                                <li key={idx} className="text-sm font-semibold">
                                                    {item?.product_name} - {item?.quantity}
                                                </li>
                                            ))}
                                        </ol> */}
                                        <CustomTable
                                            tableBody={modalTableBody}
                                            tableHeader={modalTableHeader}
                                            isAllselectable={false}
                                            headerContainerClass="!bg-[#CCD6E4]"
                                            tableClass=" w-full"
                                            contentContainer="!pl-0"
                                            isScrollable={false}
                                            isCellBordered={false}
                                        />
                                        <div className="flex justify-end mt-4 ">
                                            <p className="mb-3 font-bold">
                                                Total: {selectedOrder?.currency}
                                                {selectedOrder?.total}
                                            </p>
                                        </div>
                                    </div>

                                    <div>
                                        {selectedOrder?.status?.toLowerCase() === "in_queue" && (
                                            <div className="w-full flex items-center gap-5 mb-3">
                                                {/* <input
                                                    type={"text"}
                                                    placeholder="Wait time: 5mins"
                                                    className="border h-[45px] px-4 py-1 w-1/2 rounded text-base"
                                                /> */}
                                                <button
                                                    type="button"
                                                    className="bg-[#F2CB61] h-[45px] w-full flex items-center justify-center gap-2.5 text-base text-black py-1 px-4 rounded"
                                                    onClick={() => {
                                                        dispatch(
                                                            vendorActions.processOrder(
                                                                params?.storeId,
                                                                selectedOrder?.id,
                                                                userObj.token
                                                            )
                                                        );
                                                    }}
                                                >
                                                    <span className="material-icons-outlined text-lg">
                                                        timer
                                                    </span>
                                                    <span>
                                                        {isProcessingOrder
                                                            ? "Starting..."
                                                            : "Start Order"}
                                                    </span>
                                                </button>
                                            </div>
                                        )}
                                        {selectedOrder?.status?.toLowerCase() !== "processed" &&
                                            selectedOrder?.status?.toLowerCase() !==
                                                "delivered" && (
                                                <button
                                                    type="button"
                                                    className="bg-[#59A985] h-[45px] w-full flex items-center justify-center gap-2.5 text-base text-black py-1 px-4 rounded"
                                                    onClick={() => {
                                                        dispatch(
                                                            vendorActions.markOrderAsProcessed(
                                                                params?.storeId,
                                                                selectedOrder?.id,
                                                                userObj.token
                                                            )
                                                        );
                                                    }}
                                                >
                                                    <span className="material-icons-outlined text-lg">
                                                        check_circle
                                                    </span>
                                                    <span>
                                                        {isMarkingOrderAsProcessed
                                                            ? "Marking as complete"
                                                            : "Mark Complete"}
                                                    </span>
                                                </button>
                                            )}
                                        {selectedOrder?.status?.toLowerCase() === "processed" && (
                                            <button
                                                type="button"
                                                className="bg-blue-400 mt-3 h-[45px] w-full flex items-center justify-center gap-2.5 text-base text-black py-1 px-4 rounded"
                                                onClick={() => {
                                                    dispatch(
                                                        vendorActions.markOrderAsDelivered(
                                                            params?.storeId,
                                                            selectedOrder?.id,
                                                            userObj.token
                                                        )
                                                    );
                                                }}
                                            >
                                                <span className="material-icons-outlined text-lg">
                                                    check_circle
                                                </span>
                                                <span>
                                                    {isMarkingOrderAsDelivered
                                                        ? "Delivering order"
                                                        : "Deliver Order"}
                                                </span>
                                            </button>
                                        )}
                                        {selectedOrder?.status?.toLowerCase() === "in_queue" && (
                                            <button
                                                type="button"
                                                className="bg-red-400 mt-3 h-[45px] w-full flex items-center justify-center gap-2.5 text-base text-black py-1 px-4 rounded"
                                                onClick={() => {
                                                    dispatch(
                                                        vendorActions.cancelOrder(
                                                            params?.storeId,
                                                            selectedOrder?.id,
                                                            userObj.token
                                                        )
                                                    );
                                                }}
                                            >
                                                <i className="ri-close-circle-line text-lg"></i>
                                                <span>
                                                    {isCancellingOrder
                                                        ? "Cancelling order"
                                                        : "Cancel Order"}
                                                </span>
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalContainer>
        </>
    );
};

export default ManageOrders;
