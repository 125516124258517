import { useEffect, useState } from "react";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";

const AddressInput = ({ placeholder, className, setData, savedAddress, required }) => {
    const [value, setValue] = useState({});
    const {
        placesService,
        placePredictions,
        getPlacePredictions,
        isPlacePredictionsLoading,
        clearSuggestions,
    } = usePlacesService({
        apiKey: process.env.REACT_APP_GOOGLE_MAP_API,
    });

    const handleSelect = (address) => {
        // Get the selected place's lat/lng
        placesService?.getDetails(
            {
                placeId: address.place_id,
            },
            (placeDetails) => {
                // setValue(placeDetails);
                // setData(placeDetails);
                console.log(placeDetails);
                const { lat, lng } = placeDetails.geometry.location;

                setValue(address?.description);
                setData({ ...address, lng: lng(), lat: lat() });
                // Clear suggestions after selection
                getPlacePredictions({ input: "" });
            }
        );
    };

    useEffect(() => {
        if (savedAddress) {
            setValue(savedAddress?.description);
        }
    }, [savedAddress]);

    return (
        <div>
            <input
                placeholder={placeholder}
                value={value || ""}
                className={`input-field ${className}`}
                onChange={(evt) => {
                    getPlacePredictions({ input: evt.target.value });
                    setValue(evt.target.value);
                    !evt.target.value && setData({});
                }}
                loading={String(isPlacePredictionsLoading)}
                required={required}
            />
            {!isPlacePredictionsLoading && placePredictions.length > 0 && (
                <div className="border rounded-lg">
                    {placePredictions.map((item, idx) => {
                        return (
                            <span
                                className="p-2 block cursor-pointer"
                                key={item?.place_id}
                                onClick={() => handleSelect(item)}
                            >
                                {item?.description}
                            </span>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default AddressInput;
