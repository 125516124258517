import React from "react";

const CustomTable = ({
    tableBody,
    tableHeader,
    tbodyClass,
    isScrollable,
    headerContainerClass,
    headerItemClass,
    bodyItemClass,
    tableClass,
    isCellBordered,
}) => {
    return (
        <div className={`${isScrollable ? "overflow-auto" : "overflow-hidden"} w-full`}>
            <table
                className={`table-fixed bg-white w-full ${tableClass} rounded-[6px] border border-slate-100`}
            >
                <thead>
                    <tr className={`text-left bg-white ${headerContainerClass}`}>
                        {tableHeader?.map((header, idx) => (
                            <th
                                key={header?.title + idx}
                                className={`${header?.widthClass} ${
                                    isCellBordered && "border-r"
                                } border-slate-100 ${
                                    idx === 0 && isScrollable && "sticky left-0 bg-inherit"
                                }`}
                            >
                                <div
                                    className={`p-3 text-xs text-slate-700 flex items-center gap-3 ${headerItemClass}`}
                                >
                                    {header?.title}
                                </div>
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody className={`${tbodyClass}`}>
                    {tableBody?.map((item, index) => {
                        return (
                            <tr
                                key={index}
                                className={`text-left bg-white  border-t hover:bg-slate-50 border-slate-100 cursor-pointer ellipsis ${bodyItemClass}`}
                                onClick={() => item?.[0]?.cellClickAction?.()}
                            >
                                {item?.map((col, idx) => (
                                    <td
                                        key={index + "-" + idx}
                                        className={`${col?.widthClass}  ${
                                            isCellBordered && idx !== item?.length - 1 && "border-r"
                                        } border-slate-100 ${
                                            idx === 0 && isScrollable && "sticky left-0 bg-inherit"
                                        }`}
                                    >
                                        <div
                                            className={`flex pl-3  items-center gap-3 ${col?.tableDataDivClass}`}
                                        >
                                            {col?.content}
                                        </div>
                                    </td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default CustomTable;
