const getVendorStores = async (vendorId, page) => {
    try {
        const rawData = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/vendors/${vendorId}/stores?size=10&page=${page}`,
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            }
        );
        const res = await rawData.json();

        return res;
    } catch (error) {
        console.log("error", error);
    }
};

const getVendors = async (token) => {
    // returns one vendor attached to use if fetched with token
    try {
        const rawData = await fetch(`${process.env.REACT_APP_API_BASE_URL}/vendors`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        const res = await rawData.json();

        return res;
    } catch (error) {
        console.log("error", error);
    }
};

const addVendorStore = async (vendorId, data, token) => {
    try {
        const rawData = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/vendors/${vendorId}/stores`,
            {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            }
        );
        const res = await rawData.json();

        return res;
    } catch (error) {
        console.log("error", error);
    }
};

const updateVendorStore = async (storeId, data, token) => {
    try {
        const rawData = await fetch(`${process.env.REACT_APP_API_BASE_URL}/stores/${storeId}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });
        const res = await rawData.json();

        return res;
    } catch (error) {
        console.log("error", error);
    }
};

const publishStore = async (storeId, token) => {
    try {
        const rawData = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/store/${storeId}/publish`,
            {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            }
        );
        const res = await rawData.json();

        return res;
    } catch (error) {
        console.log("error", error);
    }
};

const unpublishStore = async (storeId, token) => {
    try {
        const rawData = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/store/${storeId}/unpublish`,
            {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            }
        );
        const res = await rawData.json();

        return res;
    } catch (error) {
        console.log("error", error);
    }
};

const getAStore = async (storeId, token) => {
    try {
        const rawData = await fetch(`${process.env.REACT_APP_API_BASE_URL}/stores/${storeId}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        const res = await rawData.json();

        return res;
    } catch (error) {
        console.log("error", error);
    }
};

const getStoreProducts = async (storeId, token) => {
    try {
        const rawData = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/stores/${storeId}/products`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            }
        );
        const res = await rawData.json();

        return res;
    } catch (error) {
        console.error("error", error);
    }
};

const getCategories = async (vendorId, token, storeId, page) => {
    try {
        const rawData = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/vendors/${vendorId}/categories?store=${storeId}&size=10&page=${page}`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            }
        );
        const res = await rawData.json();

        return res;
    } catch (error) {
        console.error("error", error);
    }
};

const createCategory = async (vendorId, storeId, token, data) => {
    try {
        const rawData = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/vendors/${vendorId}/categories?store=${storeId}`,
            {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            }
        );
        const res = await rawData.json();

        return res;
    } catch (error) {
        console.error("error", error);
    }
};

const getCategoryProducts = async (categoryId, token, page) => {
    try {
        const rawData = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/categories/${categoryId}/products?size=10&page=${page}`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            }
        );
        const res = await rawData.json();

        return res;
    } catch (error) {
        console.error("error", error);
    }
};

const createProduct = async (vendorId, productId, token, data) => {
    try {
        const rawData = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/vendors/${vendorId}/products${
                productId ? "/" + productId : ""
            }`,
            {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            }
        );
        const res = await rawData.json();

        return res;
    } catch (error) {
        console.error("error", error);
    }
};

const updateCategory = async (vendorId, categoryId, token, data) => {
    try {
        const rawData = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/vendors/${vendorId}/categories/${categoryId}`,
            {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            }
        );
        const res = await rawData.json();

        return res;
    } catch (error) {
        console.error("error", error);
    }
};

const deleteCategory = async (vendorId, categoryId, token) => {
    try {
        const rawData = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/vendors/${vendorId}/categories/${categoryId}`,
            {
                method: "DELETE",
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            }
        );
        const res = await rawData.json();

        return res;
    } catch (error) {
        console.error("error", error);
    }
};

const getStoreOrders = async (storeId, token, page) => {
    try {
        const rawData = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/stores/${storeId}/orders${
                page ? "?page=" + page : ""
            }`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            }
        );
        const res = await rawData.json();

        return res;
    } catch (error) {
        console.error("error", error);
    }
};

const getPendingStoreOrders = async (storeId, token, page) => {
    try {
        const rawData = await fetch(
            `${
                process.env.REACT_APP_API_BASE_URL
            }/stores/${storeId}/orders?status=in_queue,processing${page ? "&page=" + page : ""}`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            }
        );
        const res = await rawData.json();

        return res;
    } catch (error) {
        console.error("error", error);
    }
};

const getCompletedStoreOrders = async (storeId, token, page) => {
    try {
        const rawData = await fetch(
            `${
                process.env.REACT_APP_API_BASE_URL
            }/stores/${storeId}/orders?status=processed,delivered${page ? "?page=" + page : ""}`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            }
        );
        const res = await rawData.json();

        return res;
    } catch (error) {
        console.error("error", error);
    }
};

const getCancelledStoreOrders = async (storeId, token, page) => {
    try {
        const rawData = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/stores/${storeId}/orders?status=cancelled${
                page ? "?page=" + page : ""
            }`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            }
        );
        const res = await rawData.json();

        return res;
    } catch (error) {
        console.error("error", error);
    }
};

const processOrder = async (storeId, orderId, token) => {
    try {
        const rawData = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/stores/${storeId}/orders/pick/${orderId}`,
            {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            }
        );
        const res = await rawData.json();

        return res;
    } catch (error) {
        console.error("error", error);
    }
};

const markOrderAsProcessed = async (storeId, orderId, token) => {
    try {
        const rawData = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/stores/${storeId}/orders/${orderId}/process`,
            {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            }
        );
        const res = await rawData.json();

        return res;
    } catch (error) {
        console.error("error", error);
    }
};

const markOrderAsDelivered = async (storeId, orderId, token) => {
    try {
        const rawData = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/stores/${storeId}/orders/${orderId}/deliver`,
            {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            }
        );
        const res = await rawData.json();

        return res;
    } catch (error) {
        console.error("error", error);
    }
};

const cancelOrder = async (storeId, orderId, token) => {
    try {
        const rawData = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/stores/${storeId}/orders/${orderId}/cancel`,
            {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            }
        );
        const res = await rawData.json();

        return res;
    } catch (error) {
        console.error("error", error);
    }
};

const saveAllProducts = async (vendorId, data, token) => {
    try {
        const rawData = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/vendors/${vendorId}/products`,
            {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: "application/json",
                    // "Content-Type": "application/json",
                },
                body: data,
            }
        );
        const res = await rawData.json();

        return res;
    } catch (error) {
        console.error("error", error);
    }
};

const deleteProduct = async (vendorId, productId, token) => {
    try {
        const rawData = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/vendors/${vendorId}/products/${productId}`,
            {
                method: "DELETE",
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: "application/json",
                    // "Content-Type": "application/json",
                },
            }
        );
        const res = await rawData.json();

        return res;
    } catch (error) {
        console.error("error", error);
    }
};

const getAStoreMetric = async (storeId, token) => {
    try {
        const rawData = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/metric/stores/${storeId}`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            }
        );
        const res = await rawData.json();

        return res;
    } catch (error) {
        console.log("error", error);
    }
};

const getStorePayouts = async (storeId, fromDate, toDate, page, token) => {
    try {
        const rawData = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/stores/${storeId}/payouts?size=10&page=${page}&fromDate=${fromDate}&toDate=${toDate}`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            }
        );
        const res = await rawData.json();

        return res;
    } catch (error) {
        console.log("error", error);
    }
};

const getPayoutTransactions = async (payoutId, fromDate, toDate, page, token) => {
    try {
        const rawData = await fetch(
            `${
                process.env.REACT_APP_API_BASE_URL
            }/payouts/${payoutId}/transactions?size=10&page=${page}${
                fromDate ? `&fromDate=${fromDate}` : ""
            }${toDate ? `&toDate=${toDate}` : ""}`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            }
        );
        const res = await rawData.json();

        return res;
    } catch (error) {
        console.log("error", error);
    }
};

export const vendorServices = {
    getVendorStores,
    getVendors,
    addVendorStore,
    updateVendorStore,
    getAStore,
    getStoreProducts,
    getCategories,
    createCategory,
    getCategoryProducts,
    createProduct,
    updateCategory,
    deleteCategory,
    getStoreOrders,
    getPendingStoreOrders,
    getCompletedStoreOrders,
    getCancelledStoreOrders,
    processOrder,
    markOrderAsProcessed,
    markOrderAsDelivered,
    cancelOrder,
    saveAllProducts,
    deleteProduct,
    publishStore,
    unpublishStore,
    getAStoreMetric,
    getStorePayouts,
    getPayoutTransactions,
};
