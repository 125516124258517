import GoogleMapReact from "google-map-react";
import { useEffect, useState } from "react";
import Loader from "./Loader";

const Map = ({ lat, lng, locationData, onMapMarkerClick, mapLoading }) => {
    const [marker, setMarker] = useState({});
    const [coordinates, setCoordinates] = useState({
        lat: null,
        lng: null,
    });

    const defaultProps = {
        center: {
            lat: coordinates?.lat,
            lng: coordinates?.lng,
        },
        zoom: 19,
    };

    const handleMapClick = (event) => {
        const { lat, lng } = event;
        // console.log("e.latLng.lat(),", event.latLng.lat(), event.latLng.lng());
        setMarker(() => ({
            lat,
            lng,
        }));
        console.log("eventlatlng", event);
    };

    useEffect(() => {
        setCoordinates({
            lat,
            lng,
        });
    }, [lat, lng]);

    return (
        <div style={{ height: "300px", width: "100%", borderRadius: "50%" }}>
            <GoogleMapReact
                bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_API }}
                defaultCenter={defaultProps?.center}
                center={{
                    lat: coordinates?.lat,
                    lng: coordinates?.lng,
                }}
                zoom={19}
                defaultZoom={defaultProps.zoom}
                onClick={handleMapClick}
                options={{
                    disableDefaultUI: true,
                    mapId: "7a4e1c6804abc222",
                    zoomControl: true,
                    fullscreenControl: true,
                    zoomControlOptions: { position: 5 },
                }}
            >
                <i
                    lat={marker?.lat}
                    lng={marker?.lng}
                    className="ri-map-pin-fill text-3xl text-red-500"
                ></i>
            </GoogleMapReact>
        </div>
    );
};
export default Map;
