import { useState } from "react";

const FormOne = ({ formData, setFormData, handleBack }) => {
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const isPasswordMatch = formData.user.password === formData.user.confirm_password;

    return (
        <div>
            <div className="flex flex-col gap-4">
                <div className="flex justify-between" id="formdetails">
                    <div className="field-container">
                        <p>First Name*</p>
                        <input
                            type={"text"}
                            className="input-field"
                            value={formData.user.first_name}
                            onChange={(e) => {
                                setFormData((prev) => ({
                                    ...prev,
                                    user: {
                                        ...prev.user,
                                        first_name: e.target.value,
                                    },
                                }));
                            }}
                            required
                        />
                    </div>
                    <div className="field-container">
                        <p>Last Name*</p>
                        <input
                            type={"text"}
                            className="input-field"
                            value={formData.user.last_name}
                            onChange={(e) => {
                                setFormData((prev) => ({
                                    ...prev,
                                    user: {
                                        ...prev.user,
                                        last_name: e.target.value,
                                    },
                                }));
                            }}
                            required
                        />
                    </div>
                </div>
                <div className="flex justify-between" id="formdetails">
                    <div className="field-container">
                        <p>Email*</p>
                        <input
                            type={"email"}
                            className="input-field"
                            value={formData.user.email}
                            onChange={(e) => {
                                setFormData((prev) => ({
                                    ...prev,
                                    user: {
                                        ...prev.user,
                                        email: e.target.value,
                                    },
                                }));
                            }}
                            required
                            pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$"
                        />
                    </div>
                    <div className="field-container">
                        <p>Phone Number*</p>
                        <input
                            type={"number"}
                            className="input-field"
                            value={formData.user.phone_number}
                            onChange={(e) => {
                                setFormData((prev) => ({
                                    ...prev,
                                    user: {
                                        ...prev.user,
                                        phone_number: e.target.value,
                                    },
                                }));
                            }}
                            required
                            minLength={10}
                            pattern={"^[0-9]+$"}
                            title="must be a valid phone number"
                        />
                    </div>
                </div>
                <div className="flex justify-between" id="formdetails">
                    <div className="field-container">
                        <p>Password*</p>
                        <div className="relative flex">
                            <input
                                type={showPassword ? "text" : "password"}
                                className="input-field"
                                value={formData.user.password}
                                onChange={(e) => {
                                    setFormData((prev) => ({
                                        ...prev,
                                        user: {
                                            ...prev.user,
                                            password: e.target.value,
                                        },
                                    }));
                                }}
                                required
                            />
                            {!showPassword ? (
                                <i
                                    onClick={() => setShowPassword(true)}
                                    className="ri-eye-close-line absolute top-[14px] right-[20px] text-gray-500 cursor-pointer"
                                ></i>
                            ) : (
                                <i
                                    onClick={() => setShowPassword(false)}
                                    className="ri-eye-line absolute top-[14px] right-[20px] text-gray-500 cursor-pointer"
                                ></i>
                            )}
                        </div>
                    </div>
                    <div className="field-container">
                        <p>Retype Password*</p>
                        <div className="relative flex">
                            <input
                                type={showConfirmPassword ? "text" : "password"}
                                className="input-field"
                                value={formData.user.confirm_password}
                                onChange={(e) => {
                                    setFormData((prev) => ({
                                        ...prev,
                                        user: {
                                            ...prev.user,
                                            confirm_password: e.target.value,
                                        },
                                    }));
                                }}
                                required
                            />

                            {!showConfirmPassword ? (
                                <i
                                    onClick={() => setShowConfirmPassword(true)}
                                    className="ri-eye-close-line absolute top-[14px] right-[20px] text-gray-500 cursor-pointer"
                                ></i>
                            ) : (
                                <i
                                    onClick={() => setShowConfirmPassword(false)}
                                    className="ri-eye-line absolute top-[14px] right-[20px] text-gray-500 cursor-pointer"
                                ></i>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex mt-6 items-center justify-between">
                <div>
                    <p className="cursor-pointer" onClick={handleBack}>
                        &lt; Back to Login
                    </p>
                </div>
                <div className="w-[48%]">
                    <button
                        className="bg-btn-blue w-full text-base text-white py-3 rounded disabled:bg-btn-blue/70 disabled:cursor-not-allowed"
                        type="submit"
                        disabled={!isPasswordMatch}
                    >
                        Next
                    </button>
                </div>
            </div>
        </div>
    );
};

export default FormOne;
