import React, { useCallback, useEffect, useState } from "react";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";

const containerStyle = {
    width: "100%",
    height: "350px",
};

const GoogleMaps = ({ lat, lng, setLatLng }) => {
    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API,
    });

    const [map, setMap] = useState(null);
    const [marker, setMarker] = useState({});
    const [coordinates, setCoordinates] = useState({
        lat: null,
        lng: null,
    });

    const handleMapClick = (event) => {
        setMarker(() => ({
            lat: event.latLng.lat(),
            lng: event.latLng.lng(),
        }));

        setLatLng({
            lat: event.latLng.lat(),
            lng: event.latLng.lng(),
        });
        console.log("eventlatlng", event);
    };

    const onLoad = useCallback(function callback(map) {
        // This is just an example of getting and using the map instance!!! don't just blindly copy!
        const bounds = new window.google.maps.LatLngBounds({ lat, lng });
        map.fitBounds(bounds);

        setMap(map);
    }, []);

    const onUnmount = useCallback(function callback(map) {
        setMap(null);
    }, []);

    useEffect(() => {
        setCoordinates({
            lat,
            lng,
        });
        setMarker({
            lat,
            lng,
        });
    }, [lat, lng]);

    return isLoaded ? (
        <>
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={{
                    lat: coordinates?.lat,
                    lng: coordinates?.lng,
                }}
                zoom={10}
                onLoad={onLoad}
                onUnmount={onUnmount}
                onClick={handleMapClick}
            >
                {/* Child components, such as markers, info windows, etc. */}
                <Marker position={{ lat: marker?.lat, lng: marker?.lng }}></Marker>
            </GoogleMap>
        </>
    ) : (
        <></>
    );
};

export default React.memo(GoogleMaps);
