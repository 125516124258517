const login = async (data) => {
    try {
        const rawData = await fetch(`${process.env.REACT_APP_API_BASE_URL}/auth/vendor/login`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });
        const res = await rawData.json();

        return res;
    } catch (error) {
        console.log("error", error);
    }
};

const signup = async (data) => {
    try {
        const rawData = await fetch(`${process.env.REACT_APP_API_BASE_URL}/vendors`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                // "Content-Type": "multipart/form-data",
            },
            // body: JSON.stringify(data),
            body: data,
        });
        const res = await rawData.json();

        return res;
    } catch (error) {
        console.log("error", error);
    }
};

const resetPasswordRequest = async (data) => {
    try {
        const rawData = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/auth/vendor/password-reset-request`,
            {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            }
        );
        const res = await rawData.json();

        return res;
    } catch (error) {
        console.log("error", error);
    }
};

const resetPassword = async (data) => {
    try {
        const rawData = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/auth/vendor/reset-password`,
            {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            }
        );
        const res = await rawData.json();

        return res;
    } catch (error) {
        console.log("error", error);
    }
};

export const authServices = {
    login,
    signup,
    resetPasswordRequest,
    resetPassword,
};
