import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import stepIcon from "../assets/icons/login_step_icon.svg";
import "./Login/styles.css";
import { authActions } from "../redux/actions";
import { useNavigate, useParams } from "react-router-dom";
import landingPageBG from "../assets/images/landingPageBG.png";
import { Link } from "react-router-dom";
import emailjs from "emailjs-com";
import ContactUsModal from "../common/ContactUsModal";

const LandingPage = () => {
    const getCurrentYear = () => {
        return new Date().getFullYear();
    };

    const [showModal, setShowModal] = useState(false);

    const handleOpenModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    const handleSubmit = (event) => {
        event.preventDefault();

        emailjs.sendForm("service_jgrrm2n", "template_bmh9rdf", event.target, "YOUR_USER_ID").then(
            (result) => {
                console.log(result.text);
                alert("Message sent!");
                handleCloseModal();
            },
            (error) => {
                console.log(error.text);
                alert("Failed to send message.");
            }
        );
    };

    return (
        <div className="">
            <header
                className="relative w-full h-screen bg-cover bg-center"
                style={{ backgroundImage: `url(${landingPageBG})` }}
            >
                <nav className="absolute top-0 right-0 flex p-4 z-30 space-x-4">
                    {/*         
        <Link  className="text-white p-2 hover:border-2 hover:font-bold max-sm:underline" to={"/"}>
        Pricing
        </Link>

        
        <Link className="text-white p-2 hover:border-2 hover:font-bold max-sm:underline" to={"/"}>
        Event Manager Access
        </Link> */}

                    <Link
                        className="text-white p-2 hover:border-2 hover:font-bold max-sm:underline "
                        to={"/login"}
                    >
                        Vendor Login
                    </Link>
                </nav>
                <div className="absolute inset-0 flex flex-col items-start mx-24 justify-center text-left max-sm:mx-5">
                    <h1 className="text-white text-6xl font-bold w-1/2 max-lg:w-full max-sm:text-5xl ">
                        Manage event Queues and reach nearby customers{" "}
                    </h1>

                    <Link className="" to={"/register"}>
                        <button className="mt-10 px-20 py-3 bg-[#DE0000] text-white rounded font-bold hover:bg-red-700">
                            Sign up
                        </button>
                    </Link>
                </div>
            </header>

            <div className="flex flex-col gap-5 w-full justify-center items-center text-center">
                <section className="my-8 ">
                    <h2 className="text-2xl font-bold mb-4">How it works</h2>
                    <div className="grid grid-cols-4 gap-5 text-left p-6 mx-10 max-lg:grid-cols-2 max-md:grid-cols-1">
                        <div>
                            <p className="text-lg font-bold">Event Analytics & Insights</p>
                            <p>
                                Access real-time sales data and post-event reports to monitor vendor
                                performance, attendee engagement, and purchasing trends, helping you
                                optimize future events for success.
                            </p>{" "}
                        </div>
                        <div>
                            <p className="text-lg font-bold">Improve Attendee experience</p>
                            <p>
                                Attendees can pre-order food, merchandise, and services through the
                                No-Q web app, paying securely in advance. This eliminates long
                                queues, allowing attendees to spend more time enjoying the event
                                experience instead of waiting in lines.
                            </p>{" "}
                        </div>
                        <div>
                            <p className="text-lg font-bold">Real-Time Order Fulfillment </p>
                            <p>
                                Event Vendors receive live order updates and notify attendees when
                                their items are ready for pickup, ensuring fast and smooth
                                transactions without delays.
                            </p>{" "}
                        </div>
                        <div>
                            <p className="text-lg font-bold">Inventory Management</p>
                            <p>
                                Vendors can easily upload menus, manage inventory, and update order
                                statuses in real-time, ensuring smooth operations and minimizing
                                errors.
                            </p>{" "}
                        </div>
                    </div>
                    <Link className="" to={"/register"}>
                        <button className="mt-10 p-8 py-3 border-2 border-black text-black rounded font-bold hover:bg-black hover:text-white">
                            Sign up
                        </button>
                    </Link>
                </section>
                <div className="w-full bg-[#CCD6E4] p-0  bottom-0">
                    <section className="my-16">
                        {/* <a href className="p-5 underline cursor-pointer" onClick={handleOpenModal}> Contact Us </a> */}
                        {/* <a
                            href="mailto:contact@no-Q.ca?subject=NO-Q Inquiry"
                            className="underline p-5 my-5"
                        >
                            Contact Us
                        </a> */}

                        <div
                            className="flex justify-center"
                            dangerouslySetInnerHTML={{
                                __html: `
                                <div id="mc_embed_shell">
      
                                <div id="mc_embed_signup">
                                    <form action="https://no-q.us3.list-manage.com/subscribe/post?u=cd71d5e41a5b14dea8e7ec891&amp;id=a9440f29f2&amp;f_id=0047c9e1f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank">
                                        <div id="mc_embed_signup_scroll"><h2 style="padding-top: 10px;">Join waitlist</h2>
                                            <div class="indicates-required"><span class="asterisk">*</span> indicates required</div>
                                            <div class="mc-field-group"><label for="mce-FNAME">First Name <span class="asterisk">*</span></label><input type="text" name="FNAME" class="required text" id="mce-FNAME" required="" value=""></div><div class="mc-field-group"><label for="mce-LNAME">Last Name <span class="asterisk">*</span></label><input type="text" name="LNAME" class="required text" id="mce-LNAME" required="" value=""></div><div class="mc-field-group"><label for="mce-COMPANY">Business Name <span class="asterisk">*</span></label><input type="text" name="COMPANY" class="required text" id="mce-COMPANY" required="" value=""></div><div class="mc-field-group"><label for="mce-EMAIL">Email Address <span class="asterisk">*</span></label><input type="email" name="EMAIL" class="required email" id="mce-EMAIL" required="" value=""></div><div class="mc-field-group"><label for="mce-PHONE">Phone Number (optional) </label><input type="text" name="PHONE" class="REQ_CSS" id="mce-PHONE" value=""></div><div class="mc-field-group"><label for="mce-MMERGE7">Message (optional) </label><input type="text" name="MMERGE7" class=" text" id="mce-MMERGE7" value=""></div>
                                        <div id="mce-responses" class="clear foot">
                                            <div class="response" id="mce-error-response" style="display: none;"></div>
                                            <div class="response" id="mce-success-response" style="display: none;"></div>
                                        </div>
                                    <div aria-hidden="true" style="position: absolute; left: -5000px;">
                                        /* real people should not fill this in and expect good things - do not remove this or risk form bot signups */
                                        <input type="text" name="b_cd71d5e41a5b14dea8e7ec891_a9440f29f2" tabindex="-1" value="">
                                    </div>
                                        <div class="optionalParent">
                                            <div class="clear foot">
                                                <input type="submit" name="subscribe" id="mc-embedded-subscribe" class="button" value="Join">
                                                
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                </div>
                                <script type="text/javascript" src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"></script><script type="text/javascript">(function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[1]='FNAME';ftypes[1]='text';fnames[2]='LNAME';ftypes[2]='text';fnames[6]='COMPANY';ftypes[6]='text';fnames[0]='EMAIL';ftypes[0]='email';fnames[4]='PHONE';ftypes[4]='phone';fnames[7]='MMERGE7';ftypes[7]='text';fnames[3]='ADDRESS';ftypes[3]='address';fnames[5]='BIRTHDAY';ftypes[5]='birthday';}(jQuery));var $mcj = jQuery.noConflict(true);</script></div>
                                `,
                            }}
                        />

                        <p className="my-5">
                            {" "}
                            &copy; {getCurrentYear()} NO-Q. All rights reserved.{" "}
                        </p>
                    </section>
                </div>
            </div>
            <ContactUsModal
                show={showModal}
                handleClose={handleCloseModal}
                handleSubmit={handleSubmit}
            />
        </div>
    );
};
export default LandingPage;
