import React from "react";
import CreateStore from "../../common/CreateStore";
import PageFrame from "../../common/PageFrame";

const EditStore = () => {
    return (
        <PageFrame
            // isLoading={isLoading && !loadMoreStores}
            frameLinks={[
                { text: "Dashboard", url: "/dashboard" },
                { text: "Store Details", url: `/dashboard/store/:storeId` },
            ]}
            childrenContainerClass={`p-[30px] bg-white shadow-lg border rounded-lg min-h-[440px] w-full overflow-y-auto max-sm:shadow-none max-sm:border-none`}
        >
            <CreateStore />
        </PageFrame>
    );
};

export default EditStore;
