import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import StoreDetail from "./pages/StoreDetail";
import ManageProducts from "./pages/Dashboard/ManageProducts";
import Signup from "./pages/Signup";
import ManageOrders from "./pages/Dashboard/ManageOrders";
import VendorPayment from "./pages/Dashboard/vendorPayment";
import EditStore from "./pages/EditStore";
import ResetPassword from "./pages/ResetPassword";
import LandingPage from "./pages/LandingPage";

export const routes = [
    // {
    //     path: "/",
    //     element: <Login />,
    //     isPrivate: false,
    // },
    {
        path: "/",
        element: <LandingPage />,
        isPrivate: false,
    },
    {
        path: "/login",
        element: <Login />,
        isPrivate: false,
    },
    {
        path: "/register",
        element: <Signup />,
        isPrivate: false,
    },

    {
        path: "/LandingPage",
        element: <LandingPage />,
        isPrivate: false,
    },

    {
        path: "/reset-password/:token",
        element: <ResetPassword />,
        isPrivate: false,
    },
    {
        path: "/dashboard",
        element: <Dashboard />,
        isPrivate: true,
    },
    {
        path: "/dashboard/store/:id",
        element: <StoreDetail />,
        isPrivate: true,
    },
    {
        path: "/dashboard/store/:storeId/products",
        element: <ManageProducts />,
        isPrivate: true,
    },
    {
        path: "/dashboard/store/:storeId/edit",
        element: <EditStore />,
        isPrivate: true,
    },
    {
        path: "/dashboard/store/:storeId/orders",
        element: <ManageOrders />,
        isPrivate: true,
    },
    {
        path: "/dashboard/store/:storeId/payment",
        element: <VendorPayment />,
        isPrivate: true,
    },
    {
        path: "*",
        element: <div>404</div>,
        isPrivate: false,
    },
];
