import React, { useState } from "react";

function YearDropdown({
    startYear = 2000,
    endYear = new Date().getFullYear(),
    value,
    handleChange,
}) {
    // const [selectedYear, setSelectedYear] = useState(endYear);

    const years = Array.from({ length: endYear - startYear + 1 }, (_, i) => startYear + i);

    // const handleYearChange = (event) => {
    //     console.log("first", event?.target?.value);
    //     setSelectedYear(event.target.value);
    // };

    return (
        <div className="relative inline-block w-full max-w-sm">
            <select
                value={value}
                onChange={(e) => handleChange("year", e.target.value)}
                id="year-dropdown"
                className="inline-flex justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
                {years.map((year, index) => (
                    <option key={index} value={year}>
                        {year}
                    </option>
                ))}
            </select>

            {/* <i className="ri-arrow-down-s-line absolute right-2 top-1/2 transform -translate-y-1/2 transition-all"></i> */}
        </div>
    );
}

export default YearDropdown;
