import React, { useEffect, useState } from "react";
import default_logo from "../../assets/images/default_logo.svg";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import { vendorActions } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../common/Loader";
import { toast } from "react-hot-toast";
import PageFrame from "../../common/PageFrame";
import vendorPayment from "../Dashboard/vendorPayment";
import { link } from "fs";
// import { link } from "fs";
// import { url } from "inspector";

const StoreDetail = () => {
    const params = useParams();
    const {
        isFetchingStoreDetails,
        isFetchedStoreDetailsSuccess,
        isUpdatingStore,
        isUpdateStoreSuccess,
        isPublishingStore,
        publishStoreSuccess,
        isUnpublishingStore,
        unpublishStoreSuccess,
    } = useSelector((store) => store.vendor);
    const noqUser = localStorage.getItem("noqUser") || sessionStorage.getItem("noqUser");
    const vendorInfo = JSON.parse(sessionStorage.getItem("vendorInfo") || "{}");
    console.log("vendorInfo", vendorInfo);

    const [storeDetails, setStoreDetails] = useState({});
    const [isEnabled, setIsEnabled] = useState(false);
    const [user] = useState(noqUser);
    const userObj = JSON.parse(user);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const copyText = (text) => {
        navigator.clipboard.writeText(text);
        toast.success("Copied Successfully");
    };

    useEffect(() => {
        dispatch(vendorActions.getStoreDetails(params.id, userObj?.token));
    }, [dispatch, params?.id, userObj?.token]);

    useEffect(() => {
        if (isFetchedStoreDetailsSuccess) {
            setStoreDetails(isFetchedStoreDetailsSuccess);
        }
    }, [isFetchedStoreDetailsSuccess]);

    useEffect(() => {
        if (Boolean(publishStoreSuccess)) {
            console.log("publishStoreSuccess", publishStoreSuccess);
            setStoreDetails(publishStoreSuccess);
            dispatch(vendorActions?.resetPublishStoreSuccess());
        }
    }, [publishStoreSuccess]);

    useEffect(() => {
        if (Boolean(unpublishStoreSuccess)) {
            console.log("unpublishStoreSuccess", unpublishStoreSuccess);
            setStoreDetails(unpublishStoreSuccess);
            dispatch(vendorActions?.resetUnpublishStoreSuccess());
        }
    }, [unpublishStoreSuccess]);

    return (
        <PageFrame
            isLoading={isFetchingStoreDetails}
            frameLinks={[
                { text: "Dashboard", url: `/dashboard`, isActive: false },
                {
                    text: "Store Details",
                    url: `/dashboard/store/${params?.id}`,
                    isActive: true,
                },
                {
                    text: "Manage Orders",
                    url: `/dashboard/store/${params?.id}/orders`,
                    isActive: false,
                },

                {
                    text: "Store Earnings",
                    url: `/dashboard/store/${params?.id}/payment`,
                    isActive: false,
                },
            ]}
            childrenContainerClass={`p-[30px] bg-white shadow-lg border min-h-[440px] max-h-[65vh] rounded-lg w-full overflow-y-auto`}
        >
            <div>
                <div className="flex max-sm:flex-col justify-between mb-8">
                    <div
                        className="flex items-center gap-1.5 cursor-pointer max-sm:mb-3"
                        onClick={() => navigate("/dashboard")}
                    >
                        {/* <span className="material-icons-outlined">menu</span> */}
                        <span className="material-icons text-base">west</span>
                        <span className="text-sm">Back</span>
                    </div>
                    <div className="flex">
                        <a
                            href={`/dashboard/store/${params.id}/edit`}
                            className="flex items-center gap-1.5 pr-4"
                        >
                            <i className="ri-edit-2-fill text-sm"></i>
                            <span className="text-sm max-sm:hidden">Edit Store</span>
                            <span className="text-sm max-sm:block hidden">Edit</span>
                        </a>
                        <a
                            href={`/dashboard/store/${params.id}/products`}
                            className="flex items-center gap-1.5 pr-4"
                        >
                            <span className="material-icons text-sm">inventory</span>
                            <span className="text-sm max-sm:hidden">Manage Products</span>
                            <span className="text-sm max-sm:block hidden">Products</span>
                        </a>
                        <button
                            type="button"
                            className={` ${
                                storeDetails?.status === "published" ? "bg-red-500" : "bg-btn-blue"
                            } w-fit font-semibold text-white px-3 py-2 rounded flex items-center space-x-2.5`}
                            disabled={isPublishingStore || isUnpublishingStore}
                            onClick={() => {
                                // dispatch(
                                //     vendorActions.updateVendorStore(
                                //         params.id,
                                //         {
                                //             name: storeDetails?.name,
                                //             services: storeDetails?.services,
                                //             latitude: Number(storeDetails?.latitude),
                                //             longitude: Number(storeDetails?.longitude),
                                //             enabled: !storeDetails?.enabled,
                                //         },
                                //         userObj?.token
                                //     )
                                // );
                                if (storeDetails?.status === "published") {
                                    dispatch(
                                        vendorActions.unpublishStore(params.id, userObj?.token)
                                    );
                                } else {
                                    dispatch(vendorActions.publishStore(params.id, userObj?.token));
                                }
                            }}
                        >
                            <span className="text-sm">
                                {storeDetails?.status === "published"
                                    ? "Unpublish Store"
                                    : "Publish Store"}
                            </span>
                            {(isPublishingStore || isUnpublishingStore) && (
                                <Loader size={4} color={"white"} />
                            )}
                        </button>
                    </div>
                </div>
                <div className="flex justify-center mb-6">
                    <div className="w-[75%] max-sm:w-full">
                        <p className="text-3xl font-bold mb-4">{storeDetails?.name}</p>
                        <div className="flex items-center gap-8 max-lg:flex-col max-lg:items-start">
                            <div
                                className="w-1/2 max-sm:w-full qrcode-container"
                                dangerouslySetInnerHTML={{
                                    __html: storeDetails?.qr_code,
                                }}
                            />
                            <div className="w-1/2">
                                <img
                                    src={vendorInfo?.logo_url || default_logo}
                                    width={vendorInfo?.logo_url ? 80 : 60}
                                    height={vendorInfo?.logo_url ? 80 : 60}
                                    alt="logo"
                                    className="mb-3"
                                />
                                <hr />
                                <div className="py-2 text-xl">
                                    <p className="font-bold">Store URL:</p>
                                    <p className="flex items-center gap-2">
                                        <span className="text-base max-sm:text-wrap">
                                            {storeDetails?.url}{" "}
                                        </span>
                                        <span
                                            onClick={() => copyText(storeDetails?.url)}
                                            className="material-icons-outlined cursor-pointer text-lg"
                                        >
                                            content_copy
                                        </span>
                                    </p>
                                </div>
                                <hr />
                                <div className="py-2 text-xl">
                                    <p className="font-bold">Services:</p>
                                    <p className="text-base">{storeDetails?.services}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </PageFrame>
    );
};

export default StoreDetail;
