import { authTypes } from "../types/authTypes";

const initialState = {
    isLoggingIn: false,
    isLoggedInSuccess: null,
    isLoggedInFailure: null,
    isSigningUp: false,
    isSignUpInSuccess: null,
    isSignUpFailure: null,
    isRequestingPasswordReset: false,
    isRequestingPasswordResetSuccess: null,
    isRequestingPasswordResetFailure: null,
    isPasswordResetting: false,
    isPasswordResetSuccess: null,
    isPasswordResetFailure: null,
};

export function auth(state = initialState, action: { type: string, payload: any }) {
    switch (action.type) {
        case authTypes.USER_LOGIN_REQUEST:
            return {
                ...state,
                isLoggingIn: true,
                isLoggedInSuccess: null,
                isLoggedInFailure: null,
            };
        case authTypes.USER_LOGIN_SUCCESS:
            return {
                ...state,
                isLoggingIn: false,
                isLoggedInSuccess: action.payload,
                isLoggedInFailure: null,
            };
        case authTypes.USER_LOGIN_FAILURE:
            return {
                ...state,
                isLoggingIn: false,
                isLoggedInSuccess: null,
                isLoggedInFailure: action.payload,
            };

        case authTypes.USER_SIGNUP_REQUEST:
            return {
                ...state,
                isSigningUp: true,
                isSignUpInSuccess: null,
                isSignUpFailure: null,
            };
        case authTypes.USER_SIGNUP_SUCCESS:
            return {
                ...state,
                isSigningUp: false,
                isSignUpInSuccess: action.payload,
                isSignUpFailure: null,
            };
        case authTypes.USER_SIGNUP_FAILURE:
            return {
                ...state,
                isSigningUp: false,
                isSignUpInSuccess: null,
                isSignUpFailure: action.payload,
            };

        case authTypes.REQUEST_PASSWORD_RESET_REQUEST:
            return {
                ...state,
                isRequestingPasswordReset: true,
                isRequestingPasswordResetSuccess: null,
                isRequestingPasswordResetFailure: null,
            };
        case authTypes.REQUEST_PASSWORD_RESET_SUCCESS:
            return {
                ...state,
                isRequestingPasswordReset: false,
                isRequestingPasswordResetSuccess: action.payload,
                isRequestingPasswordResetFailure: null,
            };
        case authTypes.REQUEST_PASSWORD_RESET_FAILURE:
            return {
                ...state,
                isRequestingPasswordReset: false,
                isRequestingPasswordResetSuccess: null,
                isRequestingPasswordResetFailure: action.payload,
            };

        case authTypes.PASSWORD_RESET_REQUEST:
            return {
                ...state,
                isPasswordResetting: true,
                isPasswordResetSuccess: null,
                isPasswordResetFailure: null,
            };
        case authTypes.PASSWORD_RESET_SUCCESS:
            return {
                ...state,
                isPasswordResetting: false,
                isPasswordResetSuccess: action.payload,
                isPasswordResetFailure: null,
            };
        case authTypes.PASSWORD_RESET_FAILURE:
            return {
                ...state,
                isPasswordResetting: false,
                isPasswordResetSuccess: null,
                isPasswordResetFailure: action.payload,
            };

        default:
            return state;
    }
}
