import { vendorTypes } from "../types";

const initialState = {
    isFetchingVendorStore: false,
    isFetchedVendorStoreSuccess: null,
    isFetchedVendorStoreFailure: null,
    isAddingVendorStore: false,
    hasAddedVendorStoreSuccess: null,
    hasAddedVendorStoreFailure: null,
    isFetchingVendors: false,
    isFetchedVendorsSuccess: null,
    isFetchedVendorsFailure: null,
    isFetchingStoreDetails: false,
    isFetchedStoreDetailsSuccess: null,
    isFetchedStoreDetailsFailure: null,
    isFetchingStoreProducts: false,
    isFetchedStoreProductsSuccess: null,
    isFetchedStoreProductsFailure: null,
    isFetchingCategories: false,
    isFetchedCategoriesSuccess: null,
    isFetchedCategoriesFailure: null,
    isFetchingCategoryProducts: false,
    isFetchedCategoryProductSuccess: null,
    isFetchedCategoryProductFailure: null,
    isCreatingCategory: false,
    isCreatingCategorySuccess: null,
    isCreatingCategoryFailure: null,
    isUpdatingCategory: false,
    isUpdatingCategorySuccess: null,
    isUpdatingCategoryFailure: null,
    isDeletingCategory: false,
    isDeletingCategorySuccess: null,
    isDeletingCategoryFailure: null,
    isCreatingProduct: false,
    isCreatingProductSuccess: null,
    isCreatingProductFailure: null,
    isFetchingStoreOrders: false,
    isFetchedStoreOrdersSuccess: null,
    isFetchedStoreOrdersFailure: null,
    isProcessingOrder: false,
    processedOrderSuccess: null,
    processedOrderFailure: null,
    isMarkingOrderAsProcessed: false,
    markOrderAsProcessedSuccess: null,
    markOrderAsProcessedFailure: null,
    isMarkingOrderAsDelivered: false,
    markOrderAsDeliveredSuccess: null,
    markOrderAsDeliveredFailure: null,
    isCancellingOrder: false,
    cancelOrderSuccess: null,
    cancelOrderFailure: null,
    isFetchingPendingStoreOrders: false,
    isFetchedPendingStoreOrdersSuccess: null,
    isFetchedPendingStoreOrdersFailure: null,
    isFetchingCompletedStoreOrders: false,
    isFetchedCompletedStoreOrdersSuccess: null,
    isFetchedCompletedStoreOrdersFailure: null,
    isFetchingCancelledStoreOrders: false,
    isFetchedCancelledStoreOrdersSuccess: null,
    isFetchedCancelledStoreOrdersFailure: null,
    isUpdatingStore: false,
    isUpdateStoreSuccess: null,
    isUpdateStoreFailure: null,
    isSavingAllProducts: false,
    isSaveAllProductsSuccess: null,
    isSaveAllProductsFailure: null,
    isDeletingProduct: false,
    deleteProductSuccess: null,
    deleteProductFailure: null,
    isPublishingStore: false,
    publishStoreSuccess: null,
    publishStoreFailure: null,
    isUnpublishingStore: false,
    unpublishStoreSuccess: null,
    unpublishStoreFailure: null,
    fetchingStoreMetric: false,
    fetchStoreMetricSuccess: null,
    fetchStoreMetricFailure: null,
};

export function vendor(state = initialState, action: { type: string, payload: any }) {
    switch (action.type) {
        case vendorTypes.FETCH_STORE_METRIC_REQUEST:
            return {
                ...state,
                fetchingStoreMetric: true,
                fetchStoreMetricSuccess: null,
                fetchStoreMetricFailure: null,
            };
        case vendorTypes.FETCH_STORE_METRIC_SUCCESS:
            return {
                ...state,
                fetchingStoreMetric: false,
                fetchStoreMetricSuccess: action.payload,
                fetchStoreMetricFailure: null,
            };
        case vendorTypes.FETCH_STORE_METRIC_FAILURE:
            return {
                ...state,
                fetchingStoreMetric: false,
                fetchStoreMetricSuccess: null,
                fetchStoreMetricFailure: action.payload,
            };

        case vendorTypes.UNPUBLISH_STORE_REQUEST:
            return {
                ...state,
                isUnpublishingStore: true,
                unpublishStoreSuccess: null,
                unpublishStoreFailure: null,
            };
        case vendorTypes.UNPUBLISH_STORE_SUCCESS:
            return {
                ...state,
                isUnpublishingStore: false,
                unpublishStoreSuccess: action.payload,
                unpublishStoreFailure: null,
            };
        case vendorTypes.UNPUBLISH_STORE_FAILURE:
            return {
                ...state,
                isUnpublishingStore: false,
                unpublishStoreSuccess: null,
                unpublishStoreFailure: action.payload,
            };

        case vendorTypes.PUBLISH_STORE_REQUEST:
            return {
                ...state,
                isPublishingStore: true,
                publishStoreSuccess: null,
                publishStoreFailure: null,
            };
        case vendorTypes.PUBLISH_STORE_SUCCESS:
            return {
                ...state,
                isPublishingStore: false,
                publishStoreSuccess: action.payload,
                publishStoreFailure: null,
            };
        case vendorTypes.PUBLISH_STORE_FAILURE:
            return {
                ...state,
                isPublishingStore: false,
                publishStoreSuccess: null,
                publishStoreFailure: action.payload,
            };

        case vendorTypes.DELETE_PRODUCT_REQUEST:
            return {
                ...state,
                isDeletingProduct: true,
                deleteProductSuccess: null,
                deleteProductFailure: null,
            };
        case vendorTypes.DELETE_PRODUCT_SUCCESS:
            return {
                ...state,
                isDeletingProduct: false,
                deleteProductSuccess: action.payload,
                deleteProductFailure: null,
            };
        case vendorTypes.DELETE_PRODUCT_FAILURE:
            return {
                ...state,
                isDeletingProduct: false,
                deleteProductSuccess: null,
                deleteProductFailure: action.payload,
            };

        case vendorTypes.SAVE_ALL_PRODUCTS_REQUEST:
            return {
                ...state,
                isSavingAllProducts: true,
                isSaveAllProductsSuccess: null,
                isSaveAllProductsFailure: null,
            };
        case vendorTypes.SAVE_ALL_PRODUCTS_SUCCESS:
            return {
                ...state,
                isSavingAllProducts: false,
                isSaveAllProductsSuccess: action.payload,
                isSaveAllProductsFailure: null,
            };
        case vendorTypes.SAVE_ALL_PRODUCTS_FAILURE:
            return {
                ...state,
                isSavingAllProducts: false,
                isSaveAllProductsSuccess: null,
                isSaveAllProductsFailure: action.payload,
            };

        case vendorTypes.UPDATE_VENDOR_STORE_REQUEST:
            return {
                ...state,
                isUpdatingStore: true,
                isUpdateStoreSuccess: null,
                isUpdateStoreFailure: null,
            };
        case vendorTypes.UPDATE_VENDOR_STORE_SUCCESS:
            return {
                ...state,
                isUpdatingStore: false,
                isUpdateStoreSuccess: action.payload,
                isUpdateStoreFailure: null,
            };
        case vendorTypes.UPDATE_VENDOR_STORE_FAILURE:
            return {
                ...state,
                isUpdatingStore: false,
                isUpdateStoreSuccess: null,
                isUpdateStoreFailure: action.payload,
            };

        case vendorTypes.GET_CANCELLED_STORE_ORDERS_REQUEST:
            return {
                ...state,
                isFetchingCancelledStoreOrders: true,
                isFetchedCancelledStoreOrdersSuccess: null,
                isFetchedCancelledStoreOrdersFailure: null,
            };
        case vendorTypes.GET_CANCELLED_STORE_ORDERS_SUCCESS:
            return {
                ...state,
                isFetchingCancelledStoreOrders: false,
                isFetchedCancelledStoreOrdersSuccess: action.payload,
                isFetchedCancelledStoreOrdersFailure: null,
            };
        case vendorTypes.GET_CANCELLED_STORE_ORDERS_FAILURE:
            return {
                ...state,
                isFetchingCancelledStoreOrders: false,
                isFetchedCancelledStoreOrdersSuccess: null,
                isFetchedCancelledStoreOrdersFailure: action.payload,
            };

        case vendorTypes.GET_COMPLETED_STORE_ORDERS_REQUEST:
            return {
                ...state,
                isFetchingCompletedStoreOrders: true,
                isFetchedCompletedStoreOrdersSuccess: null,
                isFetchedCompletedStoreOrdersFailure: null,
            };
        case vendorTypes.GET_COMPLETED_STORE_ORDERS_SUCCESS:
            return {
                ...state,
                isFetchingCompletedStoreOrders: false,
                isFetchedCompletedStoreOrdersSuccess: action.payload,
                isFetchedCompletedStoreOrdersFailure: null,
            };
        case vendorTypes.GET_COMPLETED_STORE_ORDERS_FAILURE:
            return {
                ...state,
                isFetchingCompletedStoreOrders: false,
                isFetchedCompletedStoreOrdersSuccess: null,
                isFetchedCompletedStoreOrdersFailure: action.payload,
            };

        case vendorTypes.GET_PENDING_STORE_ORDERS_REQUEST:
            return {
                ...state,
                isFetchingPendingStoreOrders: true,
                isFetchedPendingStoreOrdersSuccess: null,
                isFetchedPendingStoreOrdersFailure: null,
            };
        case vendorTypes.GET_PENDING_STORE_ORDERS_SUCCESS:
            return {
                ...state,
                isFetchingPendingStoreOrders: false,
                isFetchedPendingStoreOrdersSuccess: action.payload,
                isFetchedPendingStoreOrdersFailure: null,
            };
        case vendorTypes.GET_PENDING_STORE_ORDERS_FAILURE:
            return {
                ...state,
                isFetchingPendingStoreOrders: false,
                isFetchedPendingStoreOrdersSuccess: null,
                isFetchedPendingStoreOrdersFailure: action.payload,
            };

        case vendorTypes.CANCEL_ORDER_REQUEST:
            return {
                ...state,
                isCancellingOrder: true,
                cancelOrderSuccess: null,
                cancelOrderFailure: null,
            };
        case vendorTypes.CANCEL_ORDER_SUCCESS:
            return {
                ...state,
                isCancellingOrder: false,
                cancelOrderSuccess: action.payload,
                cancelOrderFailure: null,
            };
        case vendorTypes.CANCEL_ORDER_FAILURE:
            return {
                ...state,
                isCancellingOrder: false,
                cancelOrderSuccess: null,
                cancelOrderFailure: action.payload,
            };

        case vendorTypes.MARK_ORDER_AS_DELIVERED_REQUEST:
            return {
                ...state,
                isMarkingOrderAsDelivered: true,
                markOrderAsDeliveredSuccess: null,
                markOrderAsDeliveredFailure: null,
            };
        case vendorTypes.MARK_ORDER_AS_DELIVERED_SUCCESS:
            return {
                ...state,
                isMarkingOrderAsDelivered: false,
                markOrderAsDeliveredSuccess: action.payload,
                markOrderAsDeliveredFailure: null,
            };
        case vendorTypes.MARK_ORDER_AS_DELIVERED_FAILURE:
            return {
                ...state,
                isMarkingOrderAsDelivered: false,
                markOrderAsDeliveredSuccess: null,
                markOrderAsDeliveredFailure: action.payload,
            };

        case vendorTypes.MARK_ORDER_AS_PROCESSED_REQUEST:
            return {
                ...state,
                isMarkingOrderAsProcessed: true,
                markOrderAsProcessedSuccess: null,
                markOrderAsProcessedFailure: null,
            };
        case vendorTypes.MARK_ORDER_AS_PROCESSED_SUCCESS:
            return {
                ...state,
                isMarkingOrderAsProcessed: false,
                markOrderAsProcessedSuccess: action.payload,
                markOrderAsProcessedFailure: null,
            };
        case vendorTypes.MARK_ORDER_AS_PROCESSED_FAILURE:
            return {
                ...state,
                isMarkingOrderAsProcessed: false,
                markOrderAsProcessedSuccess: null,
                markOrderAsProcessedFailure: action.payload,
            };

        case vendorTypes.PROCESS_ORDER_REQUEST:
            return {
                ...state,
                isProcessingOrder: true,
                processedOrderSuccess: null,
                processedOrderFailure: null,
            };
        case vendorTypes.PROCESS_ORDER_SUCCESS:
            return {
                ...state,
                isProcessingOrder: false,
                processedOrderSuccess: action.payload,
                processedOrderFailure: null,
            };
        case vendorTypes.PROCESS_ORDER_FAILURE:
            return {
                ...state,
                isProcessingOrder: false,
                processedOrderSuccess: null,
                processedOrderFailure: action.payload,
            };

        case vendorTypes.GET_VENDOR_STORES_REQUEST:
            return {
                ...state,
                isFetchingVendorStore: true,
                isFetchedVendorStoreSuccess: null,
                isFetchedVendorStoreFailure: null,
            };
        case vendorTypes.GET_VENDOR_STORES_SUCCESS:
            return {
                ...state,
                isFetchingVendorStore: false,
                isFetchedVendorStoreSuccess: action.payload,
                isFetchedVendorStoreFailure: null,
            };
        case vendorTypes.GET_VENDOR_STORES_FAILURE:
            return {
                ...state,
                isFetchingVendorStore: false,
                isFetchedVendorStoreSuccess: null,
                isFetchedVendorStoreFailure: action.payload,
            };

        case vendorTypes.ADD_VENDOR_STORE_REQUEST:
            return {
                ...state,
                isAddingVendorStore: true,
                hasAddedVendorStoreSuccess: null,
                hasAddedVendorStoreFailure: null,
            };
        case vendorTypes.ADD_VENDOR_STORE_SUCCESS:
            return {
                ...state,
                isAddingVendorStore: false,
                hasAddedVendorStoreSuccess: action.payload,
                hasAddedVendorStoreFailure: null,
            };
        case vendorTypes.ADD_VENDOR_STORE_FAILURE:
            return {
                ...state,
                isAddingVendorStore: false,
                hasAddedVendorStoreSuccess: null,
                hasAddedVendorStoreFailure: action.payload,
            };

        case vendorTypes.GET_VENDORS_REQUEST:
            return {
                ...state,
                isFetchingVendors: true,
                isFetchedVendorsSuccess: null,
                isFetchedVendorsFailure: null,
            };
        case vendorTypes.GET_VENDORS_SUCCESS:
            return {
                ...state,
                isFetchingVendors: false,
                isFetchedVendorsSuccess: action.payload,
                isFetchedVendorsFailure: null,
            };
        case vendorTypes.GET_VENDORS_FAILURE:
            return {
                ...state,
                isFetchingVendors: false,
                isFetchedVendorsSuccess: null,
                isFetchedVendorsFailure: action.payload,
            };

        case vendorTypes.GET_STORE_DETAIL_REQUEST:
            return {
                ...state,
                isFetchingStoreDetails: true,
                isFetchedStoreDetailsSuccess: null,
                isFetchedStoreDetailsFailure: null,
            };
        case vendorTypes.GET_STORE_DETAIL_SUCCESS:
            return {
                ...state,
                isFetchingStoreDetails: false,
                isFetchedStoreDetailsSuccess: action.payload,
                isFetchedStoreDetailsFailure: null,
            };
        case vendorTypes.GET_STORE_DETAIL_FAILURE:
            return {
                ...state,
                isFetchingStoreDetails: false,
                isFetchedStoreDetailsSuccess: null,
                isFetchedStoreDetailsFailure: action.payload,
            };

        case vendorTypes.CREATE_CATEGORY_REQUEST:
            return {
                ...state,
                isCreatingCategory: true,
                isCreatingCategorySuccess: null,
                isCreatingCategoryFailure: null,
            };
        case vendorTypes.CREATE_CATEGORY_SUCCESS:
            return {
                ...state,
                isCreatingCategory: false,
                isCreatingCategorySuccess: action.payload,
                isCreatingCategoryFailure: null,
            };
        case vendorTypes.CREATE_CATEGORY_FAILURE:
            return {
                ...state,
                isCreatingCategory: false,
                isCreatingCategorySuccess: null,
                isCreatingCategoryFailure: action.payload,
            };

        case vendorTypes.UPDATE_CATEGORY_REQUEST:
            return {
                ...state,
                isUpdatingCategory: true,
                isUpdatingCategorySuccess: null,
                isUpdatingCategoryFailure: null,
            };
        case vendorTypes.UPDATE_CATEGORY_SUCCESS:
            return {
                ...state,
                isUpdatingCategory: false,
                isUpdatingCategorySuccess: action.payload,
                isUpdatingCategoryFailure: null,
            };
        case vendorTypes.UPDATE_CATEGORY_FAILURE:
            return {
                ...state,
                isUpdatingCategory: false,
                isUpdatingCategorySuccess: null,
                isUpdatingCategoryFailure: action.payload,
            };

        case vendorTypes.DELETE_CATEGORY_REQUEST:
            return {
                ...state,
                isDeletingCategory: true,
                isDeletingCategorySuccess: null,
                isDeletingCategoryFailure: null,
            };
        case vendorTypes.DELETE_CATEGORY_SUCCESS:
            return {
                ...state,
                isDeletingCategory: false,
                isDeletingCategorySuccess: action.payload,
                isDeletingCategoryFailure: null,
            };
        case vendorTypes.DELETE_CATEGORY_FAILURE:
            return {
                ...state,
                isDeletingCategory: false,
                isDeletingCategorySuccess: null,
                isDeletingCategoryFailure: action.payload,
            };

        case vendorTypes.GET_STORE_PRODUCTS_REQUEST:
            return {
                ...state,
                isFetchingStoreProducts: true,
                isFetchedStoreProductsSuccess: null,
                isFetchedStoreProductsFailure: null,
            };
        case vendorTypes.GET_STORE_PRODUCTS_SUCCESS:
            return {
                ...state,
                isFetchingStoreProducts: false,
                isFetchedStoreProductsSuccess: action.payload,
                isFetchedStoreProductsFailure: null,
            };
        case vendorTypes.GET_STORE_PRODUCTS_FAILURE:
            return {
                ...state,
                isFetchingStoreProducts: false,
                isFetchedStoreProductsSuccess: null,
                isFetchedStoreProductsFailure: action.payload,
            };

        case vendorTypes.GET_CATEGORIES_REQUEST:
            return {
                ...state,
                isFetchingCategories: true,
                isFetchedCategoriesSuccess: null,
                isFetchedCategoriesFailure: null,
            };
        case vendorTypes.GET_CATEGORIES_SUCCESS:
            return {
                ...state,
                isFetchingCategories: false,
                isFetchedCategoriesSuccess: action.payload,
                isFetchedCategoriesFailure: null,
            };
        case vendorTypes.GET_CATEGORIES_FAILURE:
            return {
                ...state,
                isFetchingCategories: false,
                isFetchedCategoriesSuccess: null,
                isFetchedCategoriesFailure: action.payload,
            };

        case vendorTypes.GET_CATEGORY_PRODUCTS_REQUEST:
            return {
                ...state,
                isFetchingCategoryProducts: true,
                isFetchedCategoryProductSuccess: null,
                isFetchedCategoryProductFailure: null,
            };
        case vendorTypes.GET_CATEGORY_PRODUCTS_SUCCESS:
            return {
                ...state,
                isFetchingCategoryProducts: false,
                isFetchedCategoryProductSuccess: action.payload,
                isFetchedCategoryProductFailure: null,
            };
        case vendorTypes.GET_CATEGORY_PRODUCTS_FAILURE:
            return {
                ...state,
                isFetchingCategoryProducts: false,
                isFetchedCategoryProductSuccess: null,
                isFetchedCategoryProductFailure: action.payload,
            };

        case vendorTypes.CREATE_PRODUCT_REQUEST:
            return {
                ...state,
                isCreatingProduct: true,
                isCreatingProductSuccess: null,
                isCreatingProductFailure: null,
            };
        case vendorTypes.CREATE_PRODUCT_SUCCESS:
            return {
                ...state,
                isCreatingProduct: false,
                isCreatingProductSuccess: action.payload,
                isCreatingProductFailure: null,
            };
        case vendorTypes.CREATE_PRODUCT_FAILURE:
            return {
                ...state,
                isCreatingProduct: false,
                isCreatingProductSuccess: null,
                isCreatingProductFailure: action.payload,
            };

        case vendorTypes.GET_STORE_ORDERS_REQUEST:
            return {
                ...state,
                isFetchingStoreOrders: true,
                isFetchedStoreOrdersSuccess: null,
                isFetchedStoreOrdersFailure: null,
            };
        case vendorTypes.GET_STORE_ORDERS_SUCCESS:
            return {
                ...state,
                isFetchingStoreOrders: false,
                isFetchedStoreOrdersSuccess: action.payload,
                isFetchedStoreOrdersFailure: null,
            };
        case vendorTypes.GET_STORE_ORDERS_FAILURE:
            return {
                ...state,
                isFetchingStoreOrders: false,
                isFetchedStoreOrdersSuccess: null,
                isFetchedStoreOrdersFailure: action.payload,
            };

        default:
            return state;
    }
}
