import { truncate } from "lodash";
import React, { useState } from "react";

const FormTwo = ({ formData, setFormData, handleBack, isSigningUp }) => {
    const [uploadedImg, setUploadedImg] = useState();
    return (
        <div>
            <div className="flex flex-col gap-4">
                <div className="flex justify-between" id="formdetails">
                    <div className="field-container">
                        <p>Business Name*</p>
                        <input
                            type={"text"}
                            className="input-field"
                            value={formData.name}
                            onChange={(e) => {
                                setFormData((prev) => ({
                                    ...prev,
                                    name: e.target.value,
                                }));
                            }}
                            required
                        />
                    </div>
                    <div className="field-container">
                        <p>Type of Business</p>
                        <input
                            type={"text"}
                            className="input-field"
                            value={formData.type}
                            onChange={(e) => {
                                setFormData((prev) => ({
                                    ...prev,
                                    type: e.target.value,
                                }));
                            }}
                            required
                        />
                    </div>
                </div>
                <div className="flex justify-between" id="formdetails">
                    <div className="field-container">
                        <p>Business Email*</p>
                        <input
                            type={"email"}
                            className="input-field"
                            value={formData.email}
                            onChange={(e) => {
                                setFormData((prev) => ({
                                    ...prev,
                                    email: e.target.value,
                                }));
                            }}
                            required
                            placeholder="b...cd@xyz.com"
                            pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$"
                        />
                    </div>

                    <div className="field-container">
                        <p>Phone Number</p>
                        <input
                            type={"number"}
                            className="input-field"
                            value={formData.phone_number}
                            onChange={(e) => {
                                setFormData((prev) => ({
                                    ...prev,
                                    phone_number: e.target.value,
                                }));
                            }}
                            required
                            minLength={10}
                            pattern={"^[0-9]+$"}
                            placeholder="+1 123 456 7890"
                            title="must be a valid phone number"
                        />
                    </div>
                </div>
                <div className="flex justify-between" id="formdetails">
                    <div className="field-container">
                        <p>Business Street Address*</p>
                        <input
                            type={"text"}
                            className="input-field"
                            value={formData.street}
                            onChange={(e) => {
                                setFormData((prev) => ({
                                    ...prev,
                                    street: e.target.value,
                                }));
                            }}
                            required
                        />
                    </div>

                    <div className="field-container">
                        <p>City</p>
                        <input
                            type={"text"}
                            className="input-field"
                            value={formData.city}
                            onChange={(e) => {
                                setFormData((prev) => ({
                                    ...prev,
                                    city: e.target.value,
                                }));
                            }}
                            required
                        />
                    </div>
                </div>
                <div className="flex justify-between" id="formdetails">
                    <div className="field-container">
                        <p>Postal Code</p>
                        <input
                            type={"text"}
                            className="input-field"
                            value={formData.postal_code}
                            onChange={(e) => {
                                setFormData((prev) => ({
                                    ...prev,
                                    postal_code: e.target.value,
                                }));
                            }}
                            required
                        />
                    </div>

                    <div className="field-container">
                        <p>Upload Logo (.jpg and .png supported, max file size 400KB)</p>
                        <div className="flex relative">
                            <input
                                type={"text"}
                                className="input-field text-sm !pr-44"
                                defaultValue={
                                    formData?.logo?.name
                                        ? truncate(formData?.logo?.name, { length: 30 })
                                        : ""
                                }
                                // defaultValue={uploadedImg ? uploadedImg?.name : ""}
                                required
                                disabled
                            />
                            <button
                                type="button"
                                className="py-3 text-base text-black absolute bg-[#71A0FC] right-[1px] top-[1px] px-14 rounded-[5px] cursor-pointer"
                            >
                                Upload
                            </button>
                            <input
                                type={"file"}
                                accept="image/*"
                                className="absolute w-full h-full opacity-0"
                                onChange={(event) => {
                                    console.log("first", event?.target?.files?.[0], formData);
                                    setUploadedImg(event?.target?.files?.[0]);
                                    setFormData((prev) => ({
                                        ...prev,
                                        logo: event?.target?.files?.[0],
                                    }));
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="textarea-field-container">
                    <p>Services</p>
                    <textarea
                        className="textarea-field p-3"
                        rows={7}
                        value={formData.services}
                        onChange={(e) => {
                            setFormData((prev) => ({
                                ...prev,
                                services: e.target.value,
                            }));
                        }}
                        required
                    />
                </div>
            </div>
            <div className="flex items-center justify-between mt-6">
                <div>
                    <p className="cursor-pointer" onClick={handleBack}>
                        &lt; Back
                    </p>
                </div>

                <div className="w-[48%]">
                    <button
                        className="bg-btn-blue w-full text-base text-white py-3 rounded"
                        type="submit"
                    >
                        {isSigningUp ? "Registering..." : "Finish"}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default FormTwo;
