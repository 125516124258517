import React, { useState, useEffect  } from 'react';
import { Link } from "react-router-dom";
import NoQ_logo from "../assets/icons/NO-Q_logo.png"

const Docs = () => {
  // Set default page to 'terms'
  const [selectedDoc, setSelectedDoc] = useState('terms');
  const [title, setTitle] = useState('Terms of Service');

  useEffect(() => {
    // When the page loads, check the URL hash to determine which document to show
    const hash = window.location.hash;
    if (hash) {
      const docType = hash.replace('#', '');
      setSelectedDoc(docType);
      if (docType === 'terms') {
        setTitle('Terms of Service');
      } else if (docType === 'privacy') {
        setTitle('Privacy Policy');
      } else if (docType === 'cookies') {
        setTitle('Cookie Use');
      }
    }
  }, []);

  const renderContent = () => {
    switch (selectedDoc) {
      case 'terms':
        return (
          <div>
            <h2 className="text-xl font-semibold mb-4 mt-20">Terms of Service</h2>
           <h2 className='text-md font-semibold mb-4'>Introduction</h2>
            
            <p className='mb-4'>
These Terms of Service govern your use of No-Q ("the App"), a platform that facilitates seamless ordering from vendors at events. By using our App, you agree to these terms.
</p>
<h2 className='text-md font-semibold mb-4'>
User Responsibilities </h2>
<p>
    <ol className='list-decimal pl-5 space-y-2'>
        <li><p className='font-semibold'>Accuracy of Information:</p>
            <ul className='list-disc pl-5 space-y-2'>
                    <li>Users must provide accurate and current information during registration and when using the App.
                        </li>
             </ul>
         </li>

        <li><p className='font-semibold'>Prohibited Activities</p>
            <ul className='list-disc pl-5 space-y-2'>
                <li>You may not use the App for fraudulent or unlawful purposes or engage in any activity that disrupts the functionality of the App.
                    </li>
            </ul>

        </li>
    </ol>

  
    <h2 className='text-md font-semibold mb-4 mt-8'>
Vendor Responsibilities</h2>

<ol className='list-decimal pl-5 space-y-2'>
<li><p className='font-semibold'>Required Certifications</p>

<ul className='list-disc pl-5 space-y-2'>
    <li>
Vendors must possess all necessary certifications and licenses to sell the products or services offered on the App (e.g., food handling certifications).
</li>
<li>
Vendors are solely responsible for ensuring compliance with all local regulations. No-Q assumes no liability for vendors’ non-compliance.
</li></ul>
</li>

<li> <p className='font-semibold'>
Liability</p>
    <ul className='list-disc pl-5 space-y-2'>
        <li>Vendors accept full responsibility for any claims, liabilities, or damages arising from non-compliance with applicable laws and regulations.
    </li></ul>
</li>

</ol>

<h2 className='text-md font-semibold mb-4 mt-8'>Payment and Charges
</h2>
<ol className='list-decimal pl-5 space-y-2'><p className='font-semibold'>
    <li>Transaction Processing</li></p>

    <ul className='list-disc pl-5 space-y-2'>
        <li>All payments are securely processed via third-party payment systems. No-Q does not store sensitive payment information.</li>
    </ul>

<p className='font-semibold'><li>Changes to Charges
</li></p>
<ul className='list-disc pl-5 space-y-2'><li>No-Q reserves the right to modify charges for using the App or specific features without prior notice.
</li></ul>
</ol>

<h2 className='text-md font-semibold mb-4 mt-8'>Limitation of Liability</h2>

No-Q is a facilitator and is not responsible for the quality, safety, or legality of goods or services provided by vendors.


<h2 className='text-md font-semibold mb-4 mt-8'>Termination</h2>

We reserve the right to suspend or terminate accounts for violations of these terms or misuse of the App.

<h2 className='text-md font-semibold mb-4 mt-8'>Changes to These Terms</h2>

No-Q may modify these Terms of Service at any time. Changes will be posted on the App, and continued use constitutes acceptance.

            </p>
          </div>
        );
      case 'privacy':
        return (
          <div id='privacy'>
            <h2 className="text-xl font-semibold mb-4 mt-20">Privacy Policy</h2>
            <p>
            <h2 className='text-md font-semibold mb-4'>Introduction</h2>
            <p className='mb-4'>No-Q ("the App") is committed to protecting the privacy of our users, including event attendees, vendors, and organizers. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our App. Please read it carefully.</p>


<h2 className='text-md font-semibold'>Information We Collect
</h2><p className='mb-4'>We collect the following types of information:</p>
<ol className='list-decimal pl-5 space-y-2'>
  <li className='font-semibold'>Personal Information
  </li>
  <ul className='list-disc pl-5 space-y-2'><li>Names, email addresses, and contact information when you register or make a purchase.</li>
  <li><p  className='mb-4'>Payment details (processed securely via third-party payment providers).</p>
  </li>
  </ul>


<li className='font-semibold'>Non-Personal Information
</li>
<ul className='list-disc pl-5 space-y-2'><li><p className='mb-4'>Device information, browser type, and app usage data collected through cookies and analytics tools.
</p>
</li></ul>
<li className='font-semibold'> Vendor-Specific Information
</li>
<ul className='list-disc pl-5 space-y-2'><li><p  className=' mb-4'>Business registration details, certifications, and other documents provided during vendor onboarding.</p>
</li></ul>
</ol>

<h2 className='text-md font-semibold mb-4'>How We Use Your Information
</h2>
<ul className='list-disc pl-5 space-y-2'>
  <li>To facilitate transactions between attendees and vendors.
  </li>
  <li>To provide event organizers with vendor and attendee analytics.
  </li>
  <li>To improve our App and services.
  </li>
  <li><p  className='mb-4'>To communicate important updates, offers, and notifications.</p>
  </li>
  </ul>

  <h2 className='text-md font-semibold'>How We Share Your Information</h2>
    <p>We do not sell your personal information. However, we may share it:</p>

<ul className='list-disc pl-5 space-y-2'>

<li>With event organizers for event-related analytics and insights.</li>
<li>With third-party payment processors to complete transactions.</li>
<li><p  className='mb-4'>As required by law to comply with legal obligations.</p></li>

</ul>


<h2 className='text-md font-semibold'>Your Data Rights</h2>
<ul className='list-disc pl-5 space-y-2'>

<li>You may request access to, correction of, or deletion of your personal information by contacting us at <a href=''>contact@no-q.ca</a>. 
</li>
<li> <p  className='mb-4'>You may opt-out of marketing communications at any time.</p>
</li></ul>

<h2 className='text-md font-semibold'>Data Security</h2>
<p className='mb-4'>We implement industry-standard measures to protect your information. However, no electronic transmission or storage is completely secure, and we cannot guarantee absolute security.</p>


<h2 className='text-md font-semibold'>Changes to This Privacy Policy</h2>
<ul className='list-disc pl-5 space-y-2'>
  <li>We may update this Privacy Policy periodically. </li>
  <li className='mb-4'>Updates will be posted on the App, and your continued use constitutes acceptance.
  </li>
  </ul>
            </p>
          </div>
        );
      case 'cookies':
        return (
          <div id='cookies'>
            <h2 className="text-xl font-semibold mb-4 mt-20">Cookie Use</h2>
            <h2 className="text-md font-semibold">Introduction</h2>
            

            <p> No-Q uses cookies and similar technologies to improve your experience, provide analytics, and personalise content.

<h2 className="text-md font-semibold mt-4">
What Are Cookies?</h2>
Cookies are small text files stored on your device that help us understand user behaviour and improve functionality.

<h2 className="text-md font-semibold mt-4">
Types of Cookies We Use</h2>
<ol className='list-decimal pl-5 space-y-2'>
  <li>Essential Cookies
    <p>Necessary for the App to function properly (e.g., login and transaction sessions).
    </p>
    </li>
    <li>Performance Cookies</li>
    <p>Collect information on how users interact with the App for analytics purposes.</p>
    
    <li>Functional Cookies    </li>
<p className='mb-4'>Enhance functionality and remember user preferences.</p>

    </ol>
    <h2 className="text-md font-semibold mt-4">
Managing Cookies</h2>
You can control and delete cookies through your browser settings. However, disabling cookies may affect the functionality of the App.

<h2  className="text-md font-semibold mt-4">Third-Party Cookies</h2>

We use third-party services (e.g., Google Analytics) that may place cookies on your device. These services are subject to their own privacy policies.

            </p>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="flex h-screen">
      {/* Left Panel */}
      <div className="w-64 bg-white border-r border-gray-200 p-4 flex flex-col mt-24 gap-5 max-sm:w-28">
        {/* Links for Terms of Service, Privacy Policy, and Cookie Use */}
        <div
          onClick={() => setSelectedDoc('terms')}
          className={`cursor-pointer text-md font-bold ${selectedDoc === 'terms' ? 'underline' : ''}`}
        >
          Terms of Service
        </div>
        
        <div
          onClick={() => setSelectedDoc('privacy')}
          className={`cursor-pointer text-md font-bold ${selectedDoc === 'privacy' ? 'underline' : ''}`}
        >
          Privacy Policy
        </div>
        <div
          onClick={() => setSelectedDoc('cookies')}
          className={`cursor-pointer text-md font-bold ${selectedDoc === 'cookies' ? 'underline' : ''}`}
        >
          Cookie Use
        </div>
      </div>

      {/* Main Content Area */}
      <div className="flex-1 p-8 overflow-y-auto ml-20 max-sm:ml-3">
        {/* Top Fixed Bar */}
        <div className="fixed top-0 left-0 right-0 bg-white shadow-md p-4 z-10">
            <div className='flex flex-row items-center  gap-3'>
        <Link className="" to={"/"}>
                                <img src= {NoQ_logo} alt="logo" width={"60px"} height={"60px"}/>
                                </Link>
          <h1 className="text-xl font-semibold">{title}</h1>
        </div>
        </div>

        {/* Content Section */}
        <div className="mt-16">{renderContent()}</div>
      </div>
    </div>
  );
};

export default Docs;
