import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { vendorActions } from "../../redux/actions";
import Loader from "../../common/Loader";
import PageFrame from "../../common/PageFrame";
import ModalContainer from "../../common/ModalContainer";
// import Add_Button from "./assets/Add_Button.svg";
import { flatten, set, uniqBy } from "lodash";
import MonthDropdown from "../Signup/components/MonthDropdown";
import YearDropdown from "../Signup/components/YearDropdown";
import ResponsivePagination from "react-responsive-pagination";
import "react-responsive-pagination/themes/classic.css";
import { formatMoney } from "../../helpers/formatMoney";

const VendorPayment = () => {
    const params = useParams();

    const {
        isFetchingStoreDetails,
        isFetchedStoreDetailsSuccess,
        isUpdatingStore,
        isUpdateStoreSuccess,
        fetchingStoreMetric,
        fetchStoreMetricSuccess,
    } = useSelector((store) => store.vendor);

    const calculateTotal = () => tabledata.reduce((acc, item) => acc + item.price, 0);
    const [storeDetails, setStoreDetails] = useState({});
    const [storeMetrics, setStoreMetrics] = useState({});
    const noqUser = localStorage.getItem("noqUser") || sessionStorage.getItem("noqUser");
    const [user] = useState(noqUser);
    const userObj = JSON.parse(user);
    const dispatch = useDispatch();
    const [detailModal, setDetailModal] = useState(false);
    const [breakdownModal, setBreakdownModal] = useState(false);
    const [currentHistoryPage, setCurrentHistoryPage] = useState(1);
    const [breakdownHistoryPage, setBreakdownHistoryPage] = useState(1);
    const [startDate, setStartDate] = useState({
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
    });
    const [endDate, setEndDate] = useState({
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
    });
    const [currentMonth, setCurrentMonth] = useState("");
    const [nextMonth, setNextMonth] = useState("");

    const tabledata = [
        { name: "Earnings", price: Number(formatMoney().format(storeMetrics?.total_earnings)) },
        {
            name: "Platform Fee",
            price: Number(formatMoney().format(storeMetrics?.total_earnings * -0.15)),
        },
        // { name: "Taxes", price: -110 },
        // { name: "Others", price: -40 },
    ];

    const payments = [
        { month: "November, 2024", amount: "$1200" },
        { month: "December, 2024", amount: "$1200" },
        { month: "January, 2025", amount: "$1200" },
        { month: "November, 2025", amount: "$1200" },
    ];

    const paymentBreakdown = [
        { date: "12 Nov, 2024", item: "Burger", qty: "3", price: "$1200" },
        { date: "10 Dec, 2024", item: "Burger", qty: "1", price: "$1200" },
        { date: "09 Oct, 2025", item: "Burger", qty: "2", price: "$1200" },
        { date: "08 Sept, 2025", item: "Burger", qty: "3", price: "$1200" },
    ];

    const handleStartDateChange = (type, value) => {
        setStartDate((prev) => ({
            ...prev,
            [type]: value,
        }));
    };

    const handleEndDateChange = (type, value) => {
        setEndDate((prev) => ({
            ...prev,
            [type]: value,
        }));
    };

    useEffect(() => {
        const date = new Date();

        // Get current month
        const current = date.toLocaleString("en-US", { month: "long" });

        // Move to the next month, ensuring day is set to 1
        date.setDate(1);
        date.setMonth(date.getMonth() + 1);
        const next = date.toLocaleString("en-US", { month: "long" });

        setCurrentMonth(current);
        setNextMonth(next);
    }, []);

    useEffect(() => {
        dispatch(vendorActions.getStoreDetails(params.storeId, userObj?.token));
    }, [dispatch, params?.storeId, userObj?.token]);

    useEffect(() => {
        if (isFetchedStoreDetailsSuccess) {
            setStoreDetails(isFetchedStoreDetailsSuccess);
        }
    }, [isFetchedStoreDetailsSuccess]);

    useEffect(() => {
        dispatch(vendorActions.getStoreMetric(params.storeId, userObj?.token));
    }, [dispatch, params?.storeId, userObj?.token]);

    useEffect(() => {
        if (fetchStoreMetricSuccess) {
            setStoreMetrics(fetchStoreMetricSuccess);
            // console.log("fetchStoreMetricSuccess", fetchStoreMetricSuccess);
        }
    }, [fetchStoreMetricSuccess]);

    return (
        <>
            <PageFrame
                // isLoading={isFetchingStoreOrders}
                frameLinks={[
                    { text: "Dashboard", url: `/dashboard`, isActive: false },
                    {
                        text: "Store Details",
                        url: `/dashboard/store/${params?.storeId}`,
                        isActive: false,
                    },
                    {
                        text: "Manage Orders",
                        url: `/dashboard/store/${params?.storeId}/orders`,
                        isActive: false,
                    },

                    {
                        text: "Store Earnings",
                        url: "#",
                        isActive: true,
                    },
                ]}
                childrenContainerClass={`p-[30px] bg-white shadow-lg border rounded-lg min-h-[440px] w-full overflow-hidden max-sm:h-screen `}
            >
                <div>
                    <div className="flex justify-between items-center mb-3">
                        <p className="text-2xl font-bold ">
                            Monthly Earnings ({storeDetails?.name})
                        </p>
                        <p>
                            {" "}
                            <p
                                className="text-xs text-black cursor-pointer w-fit space-x-1 flex items-center"
                                onClick={() => setDetailModal(true)}
                            >
                                <i className="ri-add-circle-fill text-sm"></i>
                                <span className="underline">
                                    Edit payment information (applies to all stores)
                                </span>
                            </p>
                        </p>
                    </div>
                    <div className="">
                        <p className="text-xl font-semibold">
                            Total earning for {currentMonth}: ${storeMetrics?.balance?.toFixed(2)}
                        </p>
                        <p className="text-sm italic">Your next payment date is {nextMonth} 1.</p>
                    </div>

                    <table className="table-auto w-[50%] max-sm:w-full rounded-md outline outline-1 outline-gray-400 overflow-hidden my-5">
                        <tbody>
                            {tabledata.map((item, index) => (
                                <tr key={index} className="text-sm">
                                    <td className="px-6 py-2 whitespace-nowrap border-b border-gray-200">
                                        {item.name}
                                    </td>
                                    <td className="px-6 py-2 text-right whitespace-nowrap border-b border-gray-200">
                                        {item.name?.toLowerCase().includes("platform")
                                            ? `(${String(item.price).substring(1)})`
                                            : item.price}
                                    </td>
                                </tr>
                            ))}
                            {/* Total Row */}
                            <tr className="font-medium text-gray-800">
                                <td className="px-6 py-2 whitespace-nowrap border-t border-gray-200">
                                    Total
                                </td>
                                <td className="px-6 py-2 text-right whitespace-nowrap border-t border-gray-200">
                                    ${calculateTotal()}
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <hr className="border-gray-200 my-8" />

                    <div>
                        <p className="text-2xl font-bold ">Payment history</p>

                        <div className="flex items-center space-x-4">
                            <div className="flex flex-row space-x-4 items-center my-5">
                                <div className="">
                                    <MonthDropdown
                                        value={startDate.month}
                                        handleChange={handleStartDateChange}
                                    />
                                </div>

                                <div className="">
                                    <YearDropdown
                                        value={startDate.year}
                                        handleChange={handleStartDateChange}
                                        startYear={2020}
                                        endYear={2050}
                                    />{" "}
                                    {/* Specify custom start and end year */}
                                </div>
                            </div>

                            <span>to</span>

                            <div className="flex flex-row space-x-4 items-center my-5">
                                <div className="">
                                    <MonthDropdown
                                        value={endDate.month}
                                        handleChange={handleEndDateChange}
                                    />
                                </div>

                                <div className="">
                                    <YearDropdown
                                        value={endDate.year}
                                        handleChange={handleEndDateChange}
                                        startYear={2020}
                                        endYear={2050}
                                    />{" "}
                                    {/* Specify custom start and end year */}
                                </div>
                            </div>
                        </div>

                        <div className="w-1/2">
                            <table className="w-full border border-gray-200 bg-white rounded-[10px]">
                                <thead>
                                    <tr className="bg-gray-100 text-gray-700">
                                        <th className="px-4 py-2 text-left">Month</th>
                                        <th className="px-4 py-2 text-left">Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {payments.map((payment, index) => (
                                        <tr
                                            key={index}
                                            onClick={() => {
                                                setBreakdownModal(true);
                                            }}
                                            className="border-t border-gray-200 cursor-pointer text-sm"
                                        >
                                            <td className="px-4 py-2 space-x-2">
                                                <span>{payment.month}</span>{" "}
                                                <i className="ri-external-link-line text-blue-500"></i>
                                            </td>
                                            <td className="px-4 py-2">{payment.amount}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            <div className="flex justify-end mt-6">
                                <ResponsivePagination
                                    current={currentHistoryPage}
                                    total={5}
                                    onPageChange={(page) => {
                                        setCurrentHistoryPage(page);
                                    }}
                                    maxWidth={200}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </PageFrame>

            <ModalContainer
                open={detailModal}
                showCloseIcon={false}
                tailwindClassName="w-[48.6%] max-lg:w-1/2 max-sm:w-[95%]"
                closeModal={() => setDetailModal(false)}
            >
                <div className="w-full p-[30px] bg-white rounded-md">
                    <div>
                        <div className="flex flex-col">
                            <div className="flex flex-col text-center justify-center mb-[18px]">
                                <p className="text-xl font-bold mb-2 ">Payment information</p>
                                <p className="text-sm font-semibold text-red-500">
                                    *Fill in the interac email address you would like to receive
                                    payment through. Please ensure to double check the email as once
                                    saved can not be changed
                                </p>
                            </div>

                            <div className=" ">
                                {/* <div className=" mb-5 w-full">
                                    <label
                                        for="first_name"
                                        class="mb-2 text-sm font-medium text-gray-900 "
                                    >
                                        First Name*
                                    </label>
                                    <input
                                        type="text"
                                        id="first_name"
                                        class="bg-gray-50 border border-blue-900  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                        placeholder=""
                                        required
                                    />
                                </div> */}

                                {/* <div className="mb-5">
                                    <label
                                        for="last_name"
                                        class="mb-2 text-sm font-medium text-gray-900 "
                                    >
                                        Last Name*
                                    </label>
                                    <input
                                        type="text"
                                        id="last_name"
                                        class="bg-gray-50 border border-blue-900  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                        placeholder=""
                                        required
                                    />
                                </div> */}

                                <div className="mb-5">
                                    <label
                                        for="interac_email"
                                        class="mb-2 text-sm font-medium text-gray-900 "
                                    >
                                        Interac Email Address*
                                    </label>
                                    <input
                                        type="text"
                                        id="interac_email"
                                        class="bg-gray-50 border border-blue-900  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                        placeholder=""
                                        required
                                    />
                                </div>
                            </div>

                            <div className="flex flex-row">
                                <div className="w-1/2">
                                    <p
                                        onClick={() => setDetailModal(false)}
                                        className="underline cursor-pointer"
                                    >
                                        Cancel
                                    </p>
                                </div>
                                <button
                                    className="float-right bg-blue-700 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded w-1/2"
                                    onClick={() => {
                                        /* handle form submission here */
                                    }}
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalContainer>

            <ModalContainer
                open={breakdownModal}
                showCloseIcon={false}
                tailwindClassName="w-[48.6%] max-lg:w-1/2 max-sm:w-[95%]"
                closeModal={() => setBreakdownModal(false)}
            >
                <div className="w-full p-[30px] bg-white rounded-md">
                    <div>
                        <div className="flex flex-col">
                            <div className="flex justify-end">
                                <i
                                    className="ri-close-fill text-xl cursor-pointer"
                                    onClick={() => setBreakdownModal(false)}
                                ></i>
                            </div>
                            <div className="flex items-center justify-center">
                                <p className="text-xl font-bold mb-[18px] ">
                                    November 2024 ($1200)
                                </p>
                            </div>

                            <div className="w-full mb-5">
                                <table className="w-full border border-gray-200 bg-white rounded-[10px]">
                                    <thead>
                                        <tr className="bg-gray-100 text-gray-700">
                                            <th className="px-4 py-2 text-left">Date</th>
                                            <th className="px-4 py-2 text-left">Item</th>
                                            <th className="px-4 py-2 text-left">Quantity</th>
                                            <th className="px-4 py-2 text-left">Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {paymentBreakdown.map((payment, index) => (
                                            <tr
                                                key={index}
                                                className="border-t border-gray-200 cursor-pointer text-sm"
                                            >
                                                <td className="px-4 py-2">{payment.date}</td>
                                                <td className="px-4 py-2">{payment.item}</td>
                                                <td className="px-4 py-2">{payment.qty}</td>
                                                <td className="px-4 py-2">{payment.price}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                            <div className="flex flex-row justify-between">
                                <div className="w-1/2">
                                    <p
                                        onClick={() => setBreakdownModal(false)}
                                        className="underline cursor-pointer"
                                    >
                                        Cancel
                                    </p>
                                </div>

                                <ResponsivePagination
                                    current={breakdownHistoryPage}
                                    total={5}
                                    onPageChange={(page) => {
                                        setBreakdownHistoryPage(page);
                                    }}
                                    maxWidth={200}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </ModalContainer>
        </>
    );
};

export default VendorPayment;
