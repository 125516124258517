import { React, useState } from 'react';
import { Link } from 'react-router-dom';
import NoQ_logo from "../../assets/icons/NO-Q_logo.png"
import { List, X } from "@phosphor-icons/react";
// import { useState } from 'react';



const Navbar = (links) => {
    links = [
        // { href: "#projects", label: "Projects" },
        { href: "/FAQ", label: "Frequently Asked Questions (F.A.Q.)" },
        // { href: "https://event.no-q.ca/", label: "Vendors Nearby" },
        // { href: "/#waitlist", label: "Contact" },
    ];

    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };


    return (
        <div className=''>
            <nav className="fixed top-0 right-0 flex justify-between p-4 z-30 space-x-4 w-full bg-[#181818] h-20 items-center  max-sm:h-14">

                <div className="left-0 flex w-36 ml-10 max-sm:ml-3">


                    <Link to={"/"}>
                        <img src={NoQ_logo} className="w-16 h-16" /></Link>
                </div>

                <div>
                    <div className='flex flex-row gap-1 max-sm:hidden'>

                        {links.map((link, index) => {
                            // Check if the link is external
                            const isExternal = link.href.startsWith("http://") || link.href.startsWith("https://");

                            return isExternal ? (
                                // Render <a> tag for external links

                                <a
                                    key={index} // Pass a unique key to each item
                                    href={link.href}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-white p-2 mr-6 hover:border w-fit"
                                >
                                    {link.label}
                                </a>
                            ) : (
                                // Render <Link> for internal links
                                <Link
                                    key={index} // Pass a unique key to each item
                                    to={link.href}
                                    className="text-white p-2 mr-6  hover:border w-fit"
                                >
                                    {link.label}
                                </Link>
                            );
                        })}


                    </div>


                </div>


                {/* Mobile Menu */}
                <div className="w-full right-0 items-end justify-end  hidden max-sm:flex">
                    {/* Hamburger Icon */}
                    <button
                        className="md:hidden p-2 focus:outline-none"
                        onClick={toggleMenu}
                        aria-label="Toggle Menu"
                    >
                        {isOpen ? (
                            <X size={30} className=" text-white" />
                        ) : (
                            <List size={30} className=" text-white" />
                        )}
                    </button>

                    {/* Foldable Menu */}
                    <div
                        className={`absolute w-[90%] top-24 bg-[#181818]  border-t-4 border border-t-red-600 transform transition-transform duration-100 ease-in-out ${isOpen ? '-translate-y-[40px] ' : '-translate-y-[500px]'
                            }`}
                    >
                        <div className="flex flex-col space-y-8 text-lg font-bold text-right p-4">



                            {links.map((link, index) => {
                                // Check if the link is external
                                const isExternal = link.href.startsWith("http://") || link.href.startsWith("https://");

                                return isExternal ? (
                                    // Render <a> tag for external links

                                    <a
                                        key={index} // Pass a unique key to each item
                                        href={link.href}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-white p-2 mr-6 hover:border-b w-full"
                                    >
                                        {link.label}
                                    </a>
                                ) : (
                                    // Render <Link> for internal links
                                    <Link
                                        key={index} // Pass a unique key to each item
                                        to={link.href}
                                        className="text-white p-2 mr-6  hover:border-b w-full"
                                    >
                                        {link.label}
                                    </Link>
                                );
                            })}



                        </div>
                    </div>
                </div>









            </nav >
        </div >
    )
}

export default Navbar