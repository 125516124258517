/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import store_front from "../../assets/images/store_front_asset.svg";
import disabled_store_front from "../../assets/images/disabledStoreFront.svg";
import NoQ_logo from "../../assets/icons/NO-Q_logo.png";

import Loader from "../../common/Loader";
import { vendorActions } from "../../redux/actions";
import { onScroll } from "../../helpers/infiniteScroll";
import PageFrame from "../../common/PageFrame";
import AddressInput from "../../common/InputFields/Addressinput";
import CreateStore from "../../common/CreateStore";

const Dashboard = () => {
    const limit = 10;
    const [addStore, setAddStore] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [vendorStores, setVendorStores] = useState([]);
    const [vendors, setVendors] = useState({});
    const [addStoreData, setAddStoreData] = useState({
        name: "",
        services: "",
    });
    const [storeLimit, setStoreLimit] = useState(limit);
    const [storePagination, setStorePagination] = useState({
        current: "",
        number_of_pages: "",
    });
    const [address, setAddress] = useState({});
    const [loadMoreStores, setLoadMoreStores] = useState(false);

    const noqUser = localStorage.getItem("noqUser") || sessionStorage.getItem("noqUser");

    const [user] = useState(noqUser);
    const userObj = JSON.parse(user);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {
        isFetchingVendorStore,
        isFetchedVendorStoreSuccess,
        isAddingVendorStore,
        hasAddedVendorStoreSuccess,
        // isFetchingVendors,
        isFetchedVendorsSuccess,
    } = useSelector((state) => state.vendor);

    const container = document.getElementById("scrollContainer");

    const handleChange = (name, value) => {
        setAddStoreData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const logout = () => {
        // remove user from local storage to log user out
        localStorage.removeItem("noqUser");
        sessionStorage.removeItem("noqUser");
        window.dispatchEvent(new Event("storage"));
    };

    const onStoreContainerScroll = useCallback(
        () =>
            onScroll(container, storePagination?.current, storePagination?.number_of_pages, () => {
                // setStoreLimit(() => storeLimit + limit);
                setStorePagination((prev) => ({
                    ...prev,
                    current: prev.current + 1,
                }));
                setLoadMoreStores(true);
            }),
        [container, storePagination]
    );

    useEffect(() => {
        if (userObj?.token) {
            // setIsLoading(true);
            dispatch(vendorActions.getVendors(userObj.token));
        }
    }, [dispatch, userObj?.token]);

    useEffect(() => {
        if (isFetchedVendorsSuccess) {
            setVendors(isFetchedVendorsSuccess?.[0]);
            sessionStorage.setItem("vendorInfo", JSON.stringify(isFetchedVendorsSuccess?.[0]));
            // setAddStore(false);
            // setAddStoreData({ name: "", services: "" });
            // setAddress({});

            dispatch(
                vendorActions.getVendorStores(
                    isFetchedVendorsSuccess?.[0]?.id,
                    storePagination.current
                )
            );
        }
    }, [dispatch, isFetchedVendorsSuccess, hasAddedVendorStoreSuccess, storePagination.current]);

    useEffect(() => {
        if (isFetchedVendorStoreSuccess) {
            setIsLoading(false);
            setVendorStores((prev) =>
                isFetchedVendorStoreSuccess?.meta?.current_page === 1
                    ? [...isFetchedVendorStoreSuccess.data]
                    : [...prev, ...isFetchedVendorStoreSuccess.data]
            );
            setLoadMoreStores(false);
            setStorePagination({
                current: isFetchedVendorStoreSuccess?.meta?.current_page,
                number_of_pages: isFetchedVendorStoreSuccess?.meta?.last_page,
            });
        }
    }, [isFetchedVendorStoreSuccess]);

    useEffect(() => {
        container?.addEventListener("scroll", onStoreContainerScroll);
        return () => {
            container?.removeEventListener("scroll", onStoreContainerScroll);
        };
    }, [container, onStoreContainerScroll]);

    return (
        // <PageFrame
        //     isLoading={isLoading && !loadMoreStores}
        //     frameLinks={[{ text: "Dashboard", url: "#", isActive: true }]}
        //     childrenContainerClass={`p-[30px] bg-white shadow-lg border rounded-lg min-h-[440px] w-full overflow-y-auto max-sm:shadow-none max-sm:border-none`}
        // >
        <div className="bg-[#181818] py-10 min-h-screen flex font-sans">
            {!addStore ? (
                <>
                    <div className="flex flex-col relative   w-[50%] p-10 h-[700px]  m-auto bg-white overflow-y-auto  max-sm:w-full  max-sm:p-5 ">
                        <div className="flex flex-row justify-between items-center mb-5">
                            <p className="text-3xl font-bold">My Storefronts</p>
                            <div className="flex gap-1 pr-3 text-red-700 text-sm  w-fit  items-center">
                                <span
                                    onClick={() => logout()}
                                    className="material-icons-outlined  text-lg cursor-pointer"
                                >
                                    logout
                                </span>
                                <span onClick={() => logout()} className="cursor-pointer">
                                    Logout
                                </span>
                            </div>
                        </div>
                        <hr className="mb-5"></hr>

                        {isFetchingVendorStore && storePagination?.current === 1 && (
                            <div className="flex justify-center items-center">
                                <Loader color="dark-blue" />
                            </div>
                        )}

                        <div id="scrollContainer" className="overflow-y-auto max-h-[70vh] pb-5">
                            {vendorStores?.map((store, idx) => {
                                return (
                                    <div className="flex flex-col gap-1 mb-5 ">
                                        <div
                                            key={idx}
                                            className="w-fit mb-3 flex flex-row gap-5  cursor-pointer items-center"
                                            onClick={() =>
                                                navigate(`/dashboard/store/${store?.id}`)
                                            }
                                        >
                                            <img
                                                className="object-cover h-auto w-[85px] p-2 flex items-center justify-center border cursor-pointer rounded-[5px]"
                                                src={
                                                    store?.status?.toLowerCase() !== "published"
                                                        ? disabled_store_front
                                                        : vendors?.[0]?.logo_url || store_front
                                                }
                                                // width={"150px"}
                                                // height={"150px"}
                                                alt="store logo"
                                            />

                                            <p className="text-sm mt-3">
                                                {store.name}{" "}
                                                {store?.status?.toLowerCase() !== "published" &&
                                                    "(Inactive)"}
                                            </p>
                                        </div>
                                    </div>
                                );
                            })}

                            {loadMoreStores && (
                                <div className="flex my-4 justify-center">
                                    <Loader color="dark-blue" />
                                </div>
                            )}
                            {storePagination?.current === storePagination?.number_of_pages &&
                                vendorStores?.length > 0 && (
                                    <div className="flex mb-14 justify-center text-sm font-semibold">
                                        End of list
                                    </div>
                                )}
                        </div>

                        <div
                            className="my-3 bg-white relative  "
                            onClick={() => {
                                setAddStore(true);
                            }}
                        >
                            <button className=" flex w-full h-fit  bg-[#D90000] text-white items-center justify-center border cursor-pointer rounded-md gap-5 hover:bg-red-600">
                                <span className="material-icons text-2xl">add_circle</span>

                                <p className="text-sm ">Add New Storefront</p>
                            </button>
                        </div>
                    </div>
                </>
            ) : (
                <CreateStore setAddStore={setAddStore} />
            )}
        </div>
        // </PageFrame>
    );
};

export default Dashboard;
