export const vendorTypes = {
    GET_VENDOR_STORES_REQUEST: "GET_VENDOR_STORES_REQUEST",
    GET_VENDOR_STORES_SUCCESS: "GET_VENDOR_STORES_SUCCESS",
    GET_VENDOR_STORES_FAILURE: "GET_VENDOR_STORES_FAILURE",
    ADD_VENDOR_STORE_REQUEST: "ADD_VENDOR_STORE_REQUEST",
    ADD_VENDOR_STORE_SUCCESS: "ADD_VENDOR_STORE_SUCCESS",
    ADD_VENDOR_STORE_FAILURE: "ADD_VENDOR_STORE_FAILURE",
    UPDATE_VENDOR_STORE_REQUEST: "UPDATE_VENDOR_STORE_REQUEST",
    UPDATE_VENDOR_STORE_SUCCESS: "UPDATE_VENDOR_STORE_SUCCESS",
    UPDATE_VENDOR_STORE_FAILURE: "UPDATE_VENDOR_STORE_FAILURE",
    GET_VENDORS_REQUEST: "GET_VENDORS_REQUEST",
    GET_VENDORS_SUCCESS: "GET_VENDORS_SUCCESS",
    GET_VENDORS_FAILURE: "GET_VENDORS_FAILURE",
    GET_STORE_DETAIL_REQUEST: "GET_STORE_DETAIL_REQUEST",
    GET_STORE_DETAIL_SUCCESS: "GET_STORE_DETAIL_SUCCESS",
    GET_STORE_DETAIL_FAILURE: "GET_STORE_DETAIL_FAILURE",
    GET_STORE_PRODUCTS_REQUEST: "GET_STORE_PRODUCTS_REQUEST",
    GET_STORE_PRODUCTS_SUCCESS: "GET_STORE_PRODUCTS_SUCCESS",
    GET_STORE_PRODUCTS_FAILURE: "GET_STORE_PRODUCTS_FAILURE",
    GET_CATEGORIES_REQUEST: "GET_CATEGORIES_REQUEST",
    GET_CATEGORIES_SUCCESS: "GET_CATEGORIES_SUCCESS",
    GET_CATEGORIES_FAILURE: "GET_CATEGORIES_FAILURE",
    CREATE_CATEGORY_REQUEST: "CREATE_CATEGORY_REQUEST",
    CREATE_CATEGORY_SUCCESS: "CREATE_CATEGORY_SUCCESS",
    CREATE_CATEGORY_FAILURE: "CREATE_CATEGORY_FAILURE",
    UPDATE_CATEGORY_REQUEST: "UPDATE_CATEGORY_REQUEST",
    UPDATE_CATEGORY_SUCCESS: "UPDATE_CATEGORY_SUCCESS",
    UPDATE_CATEGORY_FAILURE: "UPDATE_CATEGORY_FAILURE",
    DELETE_CATEGORY_REQUEST: "DELETE_CATEGORY_REQUEST",
    DELETE_CATEGORY_SUCCESS: "DELETE_CATEGORY_SUCCESS",
    DELETE_CATEGORY_FAILURE: "DELETE_CATEGORY_FAILURE",
    GET_CATEGORY_PRODUCTS_REQUEST: "GET_CATEGORY_PRODUCTS_REQUEST",
    GET_CATEGORY_PRODUCTS_SUCCESS: "GET_CATEGORY_PRODUCTS_SUCCESS",
    GET_CATEGORY_PRODUCTS_FAILURE: "GET_CATEGORY_PRODUCTS_FAILURE",
    CREATE_PRODUCT_REQUEST: "CREATE_PRODUCT_REQUEST",
    CREATE_PRODUCT_SUCCESS: "CREATE_PRODUCT_SUCCESS",
    CREATE_PRODUCT_FAILURE: "CREATE_PRODUCT_FAILURE",
    GET_STORE_ORDERS_REQUEST: "GET_STORE_ORDERS_REQUEST",
    GET_STORE_ORDERS_SUCCESS: "GET_STORE_ORDERS_SUCCESS",
    GET_STORE_ORDERS_FAILURE: "GET_STORE_ORDERS_FAILURE",
    GET_PENDING_STORE_ORDERS_REQUEST: "GET_PENDING_STORE_ORDERS_REQUEST",
    GET_PENDING_STORE_ORDERS_SUCCESS: "GET_PENDING_STORE_ORDERS_SUCCESS",
    GET_PENDING_STORE_ORDERS_FAILURE: "GET_PENDING_STORE_ORDERS_FAILURE",
    GET_COMPLETED_STORE_ORDERS_REQUEST: "GET_COMPLETED_STORE_ORDERS_REQUEST",
    GET_COMPLETED_STORE_ORDERS_SUCCESS: "GET_COMPLETED_STORE_ORDERS_SUCCESS",
    GET_COMPLETED_STORE_ORDERS_FAILURE: "GET_COMPLETED_STORE_ORDERS_FAILURE",
    GET_CANCELLED_STORE_ORDERS_REQUEST: "GET_CANCELLED_STORE_ORDERS_REQUEST",
    GET_CANCELLED_STORE_ORDERS_SUCCESS: "GET_CANCELLED_STORE_ORDERS_SUCCESS",
    GET_CANCELLED_STORE_ORDERS_FAILURE: "GET_CANCELLED_STORE_ORDERS_FAILURE",
    PROCESS_ORDER_REQUEST: "PROCESS_ORDER_REQUEST",
    PROCESS_ORDER_SUCCESS: "PROCESS_ORDER_SUCCESS",
    PROCESS_ORDER_FAILURE: "PROCESS_ORDER_FAILURE",
    MARK_ORDER_AS_PROCESSED_REQUEST: "MARK_ORDER_AS_PROCESSED_REQUEST",
    MARK_ORDER_AS_PROCESSED_SUCCESS: "MARK_ORDER_AS_PROCESSED_SUCCESS",
    MARK_ORDER_AS_PROCESSED_FAILURE: "MARK_ORDER_AS_PROCESSED_FAILURE",
    MARK_ORDER_AS_DELIVERED_REQUEST: "MARK_ORDER_AS_DELIVERED_REQUEST",
    MARK_ORDER_AS_DELIVERED_SUCCESS: "MARK_ORDER_AS_DELIVERED_SUCCESS",
    MARK_ORDER_AS_DELIVERED_FAILURE: "MARK_ORDER_AS_DELIVERED_FAILURE",
    CANCEL_ORDER_REQUEST: "CANCEL_ORDER_REQUEST",
    CANCEL_ORDER_SUCCESS: "CANCEL_ORDER_SUCCESS",
    CANCEL_ORDER_FAILURE: "CANCEL_ORDER_FAILURE",
    SAVE_ALL_PRODUCTS_REQUEST: "SAVE_ALL_PRODUCTS_REQUEST",
    SAVE_ALL_PRODUCTS_SUCCESS: "SAVE_ALL_PRODUCTS_SUCCESS",
    SAVE_ALL_PRODUCTS_FAILURE: "SAVE_ALL_PRODUCTS_FAILURE",
    DELETE_PRODUCT_REQUEST: "DELETE_PRODUCT_REQUEST",
    DELETE_PRODUCT_SUCCESS: "DELETE_PRODUCT_SUCCESS",
    DELETE_PRODUCT_FAILURE: "DELETE_PRODUCT_FAILURE",
    PUBLISH_STORE_REQUEST: "PUBLISH_STORE_REQUEST",
    PUBLISH_STORE_SUCCESS: "PUBLISH_STORE_SUCCESS",
    PUBLISH_STORE_FAILURE: "PUBLISH_STORE_FAILURE",
    UNPUBLISH_STORE_REQUEST: "UNPUBLISH_STORE_REQUEST",
    UNPUBLISH_STORE_SUCCESS: "UNPUBLISH_STORE_SUCCESS",
    UNPUBLISH_STORE_FAILURE: "UNPUBLISH_STORE_FAILURE",
    FETCH_STORE_METRIC_REQUEST: "FETCH_STORE_METRIC_REQUEST",
    FETCH_STORE_METRIC_SUCCESS: "FETCH_STORE_METRIC_SUCCESS",
    FETCH_STORE_METRIC_FAILURE: "FETCH_STORE_METRIC_FAILURE",
};
