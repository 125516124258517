import { BrowserRouter as Router, Routes, Route, Navigate, useRoutes } from "react-router-dom";
import { routes } from "./router";
import "./App.css";
import { Toaster } from "react-hot-toast";
import { useEffect, useState } from "react";
// import PrivateRoute from "./common/RouteAuth/PrivateRoute";
import { useSelector } from "react-redux";
// import PublicRoute from "./common/RouteAuth/PublicRoute";
// import { useSelector } from "react-redux";
// import { useState } from "react";
import ScrollToTop from "./assets/components/ScrollToTop";

const App = () => {
    const noqUser = localStorage.getItem("noqUser") || sessionStorage.getItem("noqUser");
    const noqUserObj = JSON.parse(noqUser);
    const [user] = useState(noqUserObj);
    const [isAuthenticated, setIsAuthenticated] = useState(Boolean(user));
    const { isLoggedInSuccess } = useSelector((state) => state.auth);

    const handleStorageChange = () => {
        const noqUser = localStorage.getItem("noqUser") || sessionStorage.getItem("noqUser");
        const noqUserObj = JSON.parse(noqUser);

        if (noqUserObj || isLoggedInSuccess) {
            setIsAuthenticated(true);
        } else {
            setIsAuthenticated(false);
        }
    };

    useEffect(() => {
        window.addEventListener("storage", handleStorageChange);
        return () => {
            window.removeEventListener("storage", handleStorageChange);
        };
    }, []);

    return (
        <>
            <Toaster position="top-right" />
            <Router>
                <ScrollToTop />
                <Routes>
                    {routes.map((route) => {
                        return route.isPrivate ? (
                            <Route
                                key={route.path}
                                path={route.path}
                                element={isAuthenticated ? route.element : <Navigate to="/login" />}
                            />
                        ) : (
                            <Route
                                key={route.path}
                                path={route.path}
                                element={
                                    isAuthenticated ? <Navigate to="/dashboard" /> : route.element
                                }
                            />
                        );
                    })}
                </Routes>
            </Router>
            {/* <RouterProvider router={router} /> */}
        </>
    );
};

export default App;
